import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const VideoPlayer = (props: { src: string; type: string; style?: React.CSSProperties }) => {
	const videoRef = useRef();
	const { src, type, style } = props;

	useEffect(() => {
		if (type === 'm3u8' && Hls.isSupported()) {
			const hls = new Hls();
			hls.loadSource(src);
			hls.attachMedia(videoRef.current);
		}
	}, [src, type]);

	return type === 'm3u8' ? (
		<div style={{ position: 'absolute', top: 0, bottom: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
			<video ref={videoRef} controls style={{...style, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
		</div>
	) : (
		<div style={{ position: 'absolute', top: 0, bottom: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
			<video ref={videoRef} src={src} controls style={{...style, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
		</div>
	);
};

export default VideoPlayer;
