// Chakra imports
import { Box, FormLabel, Image, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import * as CSS from 'csstype';
import Zoom from 'react-medium-image-zoom';
import { useThemeColors } from "../../providers/theme-provider";
// Custom components
export default function Default(props: {
	label?: string;
	extra?: string;
	url?: string;
	objectFit?: CSS.Property.ObjectFit;
	size?: string;
	height?: string;
	[x: string]: any;
}) {
	const {label, extra, url, objectFit, mb, size, height, ...rest} = props;
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const {textColorSecondary} = useThemeColors();
	const defaultUrl = 'https://via.placeholder.com/300?text=No+Image';

	return (
		<Box w="100%" fontWeight="500" {...rest} mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				fontSize="sm"
				color={textColorPrimary}
				fontWeight="bold"
				_hover={{cursor: 'pointer'}}
			>
				{label}
				<Text fontSize="sm" fontWeight="400" ms="2px">
					{extra}
				</Text>
			</FormLabel>
			<Zoom>
				<Image
					src={url || defaultUrl}
					objectFit={objectFit ? objectFit : 'cover'}
					width={size}
					borderRadius={'10px'}
					borderWidth={'1px'}
					borderColor={textColorSecondary}
					height={height}
					loading={'lazy'}
					onError={(e) => {
						e.currentTarget.src = 'https://via.placeholder.com/300?text=No+Image'; // 로드 실패 시 기본 이미지로 변경
					}}
				/>
			</Zoom>
		</Box>
	);
}
