// Chakra imports
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../../providers/theme-provider';
import Rewards from '../../../project/rewards';
import { RewardsTrain } from "../../../../../apis/projectAPI";

// Assets

export default function ModalSelectReward(props: { isOpen: boolean; onClose: () => void, onSelectReward: (reward: RewardsTrain) => void }) {
	const { t } = useTranslation();
	const {} = useThemeColors();
	const { isOpen, onClose, onSelectReward } = props;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={'full'} isCentered scrollBehavior={'inside'}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Rewards
						onSelectReward={onSelectReward}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
