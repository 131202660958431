// Chakra imports
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Checkbox,
	Flex,
	FormLabel,
	Grid,
	GridItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text,
	useBoolean
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import Card from '../../../../components/card/Card';
import {
	ItemCategoryType,
	ItemLessonType,
	ItemRegisterStatusType,
	ItemSalesStatusType,
	RewardType
} from '../../../../types/enums';
import { isValidDate, showToast } from '../../../../helper/functions';
import ItemDateSelector from './components/ItemDateSelector';
import { HSeparator } from 'components/separator/Separator';
import SelectField from 'components/fields/SelectField';
import NumberField from 'components/fields/NumberField';
import DropImageBox from 'components/dropzone/DropzoneImgBox';
import TextAreaField from 'components/fields/TextAreaField';
import { CrowdFundingItemDetailDto } from '../../../../apis/crowdfundingAPI';
import {
	CharacterTypeString,
	ItemLessonTypeString,
	ItemSalesStatusTypeString,
	SkillTypeString
} from '../../../../helper/types';
import ButtonField from 'components/fields/ButtonField';
import ModalSelectReward from './components/ModalSelectReward';
import DynamicRewardField from './components/DynamicRewardField';
import { RewardsTrain } from '../../../../apis/projectAPI';
import ModalBasic from '../../../../components/modal/ModalBasic';
import ModalIdolList, { IdolModalType } from './components/ModalIdolList';
import CrowdFundingItemUpdateDetail from './components/UpdateContentDetail';
import { useLoading } from '../../../../providers/loading-provider';
import { isDate } from 'moment';
import DynamicInputField from 'components/fields/DynamicInputField';
import { TableBodyText } from '../../../../components/table/TableComponents';

// 금지어 목록
export default function CrowdFundingItemDetail(props: { itemId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const { textColorPrimary, textColorSecondary, textColorBrand } = useThemeColors();
	const { crowdFundingApi } = useApi();
	const { setLoading } = useLoading();

	const { itemId, requestChangeParams } = props;

	const [itemDetail, setItemDetail] = useState<CrowdFundingItemDetailDto>(null);

	// 	const [itemOrgSalesStatus, setItemOrgSalesStatus] = useState<ItemSalesStatusType>(ItemSalesStatusType.OPEN_SOON);
	//	const [itemSalesStatus, setItemSalesStatus] = useState<ItemSalesStatusType>(ItemSalesStatusType.OPEN_SOON);

	// 상품 판매 노출 시작일
	const [displayStartDateTime, setDisplayStartDateTime] = useState<Date>(null);
	const [isShowPickerDisplayStartDateTime, setIsShowPickerDisplayStartDateTime] = useBoolean(false);

	// 상품 판매 노출 종료일
	const [displayEndDateTime, setDisplayEndDateTime] = useState<Date>(null);
	const [isShowPickerDisplayEndDateTime, setIsShowPickerDisplayEndDateTime] = useBoolean(false);

	// 상품 판매 시작일
	const [salesStartDateTime, setSalesStartDateTime] = useState<Date>(null);
	const [isShowPickerSalesStartDateTime, setIsShowPickerSalesStartDateTime] = useBoolean(false);

	// 상품 판매 종료일
	const [salesEndDateTime, setSalesEndDateTime] = useState<Date>(null);
	const [isShowPickerSalesEndDateTime, setIsShowPickerSalesEndDateTime] = useBoolean(false);

	// 상품 사용 가능 시작일
	const [usableStartDateTime, setUsableStartDateTime] = useState<Date>(null);
	const [isShowPickerUsableStartDateTime, setIsShowPickerUsableStartDateTime] = useBoolean(false);

	// 상품 사용 가능 종료일
	const [usableEndDateTime, setUsableEndDateTime] = useState<Date>(null);
	const [isShowPickerUsableEndDateTime, setIsShowPickerUsableEndDateTime] = useBoolean(false);

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgUrls, setThumbnailImgUrls] = useState<string[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgFiles, setThumbnailImgFiles] = useState<File[]>([]);

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgUrls, setDetailImgUrls] = useState<string[]>([]);

	// 아이템 주의사항
	const [cautions, setCautions] = useState<string[]>([]);
	const [cautionsOrg, setCautionsOrg] = useState<string[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgFiles, setDetailImgFiles] = useState<File[]>([]);

	const [editabled, setEditabled] = useState<boolean>(false);
	//
	const [ticketGoalCount, setTicketGoalCount] = useState<number>(0);
	const [ticketHpPoint, setTicketHpPoint] = useState<number>(0);

	const [isShowItemRegisterModal, setIsShowItemRegisterModal] = useState<boolean>(false);
	const [isItemRequestApproveOrRejected, setIsItemRequestApproveOrRejected] = useState<boolean>(false);
	const [rejectReason, setRejectReason] = useState<string>('');

	// 리워드 선택 팝업
	const [isShowRewardModal, setIsShowRewardModal] = useState<boolean>(false);
	const [itemRewards, setItemRewards] = useState<RewardsTrain[]>([]);
	const [rewardDescription, setRewardDescription] = useState<string>('');


	// 아이템 사용 아이돌 팝업
	const [isShowIdolListModal, setIsShowIdolListModal] = useState<boolean>(false);
	const [showIdolListModalType, setShowIdolListModalType] = useState<IdolModalType>(null);

	// 업데이트 콘텐츠 팝업
	const [isShowUpdateContentsModal, setIsShowUpateContentsModal] = useState<boolean>(false);
	const [isItemUpdateAppove, setIsItemUpdateApporve] = useState<boolean>(false);

	const [isShowModalHide, setIsShowModalHide] = useState<boolean>(false);
	const [isShowModalSalesStop, setIsShowModalSalesStop] = useState<boolean>(false);


	useEffect(() => {
		if (itemId) {
			if (itemId === 'register') {
			} else {
				setThumbnailImgUrls([]);
				setDetailImgUrls([]);
				getItem();
			}
		}
	}, [itemId]);

	const getItem = () => {
		crowdFundingApi
			.getCrowdFundingItem(itemId)
			.then(response => {
				if (response.itemDetail) {
					let itemDetail = response.itemDetail as CrowdFundingItemDetailDto;

					setItemDetail(itemDetail);

					if (isValidDate(new Date(itemDetail.displayStartDateTime))) {
						setDisplayStartDateTime(new Date(itemDetail.displayStartDateTime));
					}

					if (isValidDate(new Date(itemDetail.displayEndDateTime))) {
						setDisplayEndDateTime(new Date(itemDetail.displayEndDateTime));
					}

					setSalesStartDateTime(new Date(itemDetail.saleStartDateTime));
					setSalesEndDateTime(new Date(itemDetail.saleEndDateTime));

					setUsableStartDateTime(new Date(itemDetail.usableStartDateTime));
					setUsableEndDateTime(new Date(itemDetail.usableEndDateTime));

					setTicketGoalCount(itemDetail.ticketGoalCount);
					setTicketHpPoint(itemDetail.ticketPrice);

					setCautions(itemDetail.cautions && itemDetail.cautions.length > 0 ? itemDetail.cautions.map(item => item).filter(item => item !== null): []);
					setCautionsOrg(itemDetail.cautions && itemDetail.cautions.length > 0 ? itemDetail.cautions.map(item => item).filter(item => item !== null): []);

					if (itemDetail.coverImgUrl && itemDetail.coverImgUrl !== '') {
						setThumbnailImgUrls([itemDetail.coverImgUrl]);
					}

					if (itemDetail.detailFileUrls && itemDetail.detailFileUrls.length > 0) {
						itemDetail.detailFileUrls.map((mt: { url: string }) => {
							if (mt !== null) {
								setDetailImgUrls(prevState => {
									return [...prevState, mt.url];
								});
							}
						});
					}
				}
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const registerItemApprove = () => {
		if (ticketGoalCount === 0) {
			showToast(t('error.minimum_ticket_count'));
			return;
		}

		const rewardIds: number[] = [];
		if (itemRewards.length > 0) {
			itemRewards.map(reward => {
				rewardIds.push(reward.rewardId);
			});
		}

		setLoading(true);

		crowdFundingApi
			.putCrowdFundingItemReigsterApprove(
				String(itemDetail?.crowdFundingItemId),
				ticketGoalCount,
				rewardIds,
				rewardDescription
			)
			.then(response => {
				setLoading(false);
				showToast(t('item.completed_register_approve'));
				// setThumbnailImgUrls([]);
				// setDetailImgUrls([]);
				// getItem();
				requestChangeParams();
			})
			.catch(() => {
				setLoading(false);
				showToast(t('error.try_again'));
			});
	};

	const registerItemReject = () => {
		setLoading(true);
		crowdFundingApi
			.putCrowdFundingItemReigterReject(String(itemDetail?.crowdFundingItemId), rejectReason)
			.then(response => {
				setLoading(false);
				showToast(t('item.completed_register_reject'));
				// setRejectReason('');
				// setThumbnailImgUrls([]);
				// setDetailImgUrls([]);
				// getItem();
				requestChangeParams();
			})
			.catch(() => {
				setLoading(false);
				showToast(t('error.try_again'));
			});
	};

	const itemUpdateInvisible = () => {
		setLoading(true);
		crowdFundingApi
			.putCrowdFundingItemInvisible(String(itemDetail?.crowdFundingItemId))
			.then(response => {
				setLoading(false);
				showToast(t('item.hide_completed'));
				setThumbnailImgUrls([]);
				setDetailImgUrls([]);
				getItem();
			})
			.catch(() => {
				setLoading(false);
				showToast(t('error.try_again'));
			});
	};

	const itemSalesStop = () => {
		setLoading(true);
		crowdFundingApi
			.putCrowdFundingItemSalesStop(String(itemDetail?.crowdFundingItemId))
			.then(() => {
				setLoading(false);
				showToast(t('item.sales_stop_completed'));
				setThumbnailImgUrls([]);
				setDetailImgUrls([]);
				getItem();
			})
			.catch(() => {
				setLoading(false);
				showToast(t('error.try_again'));
			});
	};

	const closeAllPopup = () => {
		setIsShowPickerDisplayEndDateTime.off();
		setIsShowPickerDisplayStartDateTime.off();
		setIsShowPickerSalesStartDateTime.off();
		setIsShowPickerSalesEndDateTime.off();
		setIsShowPickerUsableStartDateTime.off();
		setIsShowPickerUsableEndDateTime.off();
	};

	const isItemRegisterPending = () => {
		return itemDetail?.registerStatus === ItemRegisterStatusType.REGISTERED;
	};

	const isItemRegisterReject = () => {
		return itemDetail?.registerStatus === ItemRegisterStatusType.REJECTED;
	};

	const isItemRegisterCompleted = () => {
		return itemDetail?.registerStatus === ItemRegisterStatusType.APPROVED;
	};

	const isItemUpdatePending = () => {
		return itemDetail?.updateStatus === ItemRegisterStatusType.REGISTERED;
	};

	const isItemOnSale = () => {
		return itemDetail?.salesStatus === ItemSalesStatusType.ON_SALE;
	};

	const isItemOnSaleOrEndSale = () => {
		return (
			itemDetail?.salesStatus === ItemSalesStatusType.ON_SALE ||
			itemDetail?.salesStatus === ItemSalesStatusType.END_SALE ||
			itemDetail?.salesStatus === ItemSalesStatusType.SOLD_OUT
		);
	};

	const isItemSalesOpenSoon = () => {
		return itemDetail?.salesStatus === ItemSalesStatusType.OPEN_SOON;
	};

	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'row', md: 'row' }} justifyContent={'center'} mb="36px">
					<Grid
						display={{ base: 'block', md: 'grid' }}
						pt={{ base: '0px', md: '0px', xl: '0px' }}
						pl={{ base: '0px', md: '0px', xl: '20px' }}
						pr={{ base: '0px', md: '0px', xl: '20px' }}
						gap={{ base: '20px', xl: '20px' }}
						width={{ base: '90%', xl: 'calc(100% - 40px)' }}
						maxWidth={'1280px'}
					>
						{/* 타이틀 */}
						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }}>
							<GridItem colSpan={2}>
								<Text
									display="flex"
									ms="10px"
									fontSize="xl"
									color={textColorPrimary}
									fontWeight="bold"
									_hover={{ cursor: 'pointer' }}
								>
									{t('item.information')}
								</Text>
							</GridItem>
						</SimpleGrid>
						<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }} mb={{base: 10, md: 0}}>
							{t('item.setting_information')}
						</Text>

						{isItemRegisterPending() ? (
							<>
								<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'}>
									<Alert
										status="info"
										variant="subtle"
										flexDirection="column"
										alignItems="center"
										justifyContent="center"
										textAlign="center"
										borderRadius={'10px'}
									>
										<AlertIcon mr={0} />
										<AlertTitle mt={4} mb={1} fontSize="lg" whiteSpace={'pre-line'}>
											{t('item.register_submitted')}
										</AlertTitle>
									</Alert>
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<ButtonField
										label={t('item.obtain_rewards_for_idols') + ' *'}
										buttonName={t('item.select_rewards')}
										backgroundColor={'blue'}
										onClick={() => {
											if (itemRewards.length < 3) {
												setIsShowRewardModal(true);
											} else {
												showToast(t('error.select_maximum_3_rewards'));
											}
										}}
									/>
									<Flex direction={'column'}>
										<FormLabel
											display="flex"
											ms="10px"
											fontSize="sm"
											color={textColorPrimary}
											fontWeight="bold"
											_hover={{ cursor: 'pointer' }}
										>
											{t('item.used_rewards') + ' *'}
										</FormLabel>
										{itemRewards.length > 0 ? (
											<DynamicRewardField values={itemRewards} setValues={setItemRewards} />
										) : (
											<Text ms="10px" fontSize="xl" color={textColorPrimary} fontWeight="bold">
												{t('common.none')}
											</Text>
										)}
									</Flex>
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<TextAreaField
										label={t('item.reward_description')}
										values={rewardDescription}
										onChange={e => {
											setRewardDescription(e.target.value);
										}}
										placeholder={t('store.detailed_description_guide')}
									/>
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<NumberField
										label={t('item.sales_ticket_goal_count') + ' *'}
										type={'number'}
										value={ticketGoalCount}
										onChange={(e, i) => {
											if (!isNaN(i)) {
												setTicketGoalCount(i);
												setTicketHpPoint(Math.ceil(itemDetail?.itemPriceYen / (i * 7)));
											} else {
												setTicketGoalCount(0);
												setTicketHpPoint(0);
											}
										}}
									/>
									<NumberField label={t('item.sales_ticket_hp_point')} value={ticketHpPoint} disabled={true} />
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<ButtonField
										buttonName={t('item.register_approve')}
										onClick={() => {
											if (ticketGoalCount === 0) {
												showToast(t('error.minimum_ticket_count'));
												return;
											}

											setIsItemRequestApproveOrRejected(true);
											setIsShowItemRegisterModal(true);
										}}
									/>
									<ButtonField
										buttonName={t('item.register_reject')}
										backgroundColor={'red'}
										onClick={() => {
											setIsItemRequestApproveOrRejected(false);
											setIsShowItemRegisterModal(true);
										}}
									/>
								</SimpleGrid>
							</>
						) : null}

						{itemDetail?.updateStatus === ItemRegisterStatusType.REGISTERED ? (
							<>
								<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'}>
									<Alert
										status="info"
										variant="subtle"
										flexDirection="column"
										alignItems="center"
										justifyContent="center"
										textAlign="center"
										borderRadius={'10px'}
									>
										<AlertIcon mr={0} />
										<AlertTitle mt={4} mb={1} fontSize="lg" whiteSpace={'pre-line'}>
											{t('item.update_submitted')}
										</AlertTitle>
									</Alert>
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<ButtonField
										backgroundColor={'blue'}
										buttonName={t('item.show_updated_contents')}
										onClick={() => {
											setIsShowUpateContentsModal(true);
										}}
									/>
								</SimpleGrid>
							</>
						) : null}

						<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
							<SelectField
								label={t('item.sales_status')}
								mt={'0px'}
								extra={t('item.sales_status_change_desc')}
								value={itemDetail?.salesStatus}
								disabled={true}
								options={Object.keys(ItemSalesStatusType)}
								displayOptionsString={ItemSalesStatusTypeString}
							/>
							{isItemRegisterCompleted() && isItemOnSale() ? (
								<ButtonField
									buttonName={t('item.sales_stop')}
									mt={'22px'}
									backgroundColor={'red'}
									onClick={() => {
										setIsShowModalSalesStop(true);
									}}
								/>
							) : null}
						</SimpleGrid>

						<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
							<InputField label={t('item.sales_price')} value={itemDetail?.itemPriceYen} disabled={true} />
						</SimpleGrid>

						{isItemRegisterReject() ? (
							<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'}>
								<Alert
									status="error"
									variant="subtle"
									flexDirection="column"
									alignItems="center"
									justifyContent="center"
									textAlign="center"
									borderRadius={'10px'}
								>
									<AlertIcon mr={0} />
									<AlertTitle mt={4} mb={1} fontSize="lg">
										{t('item.register_reject')}
									</AlertTitle>
									{itemDetail?.adminComment ? (
										<AlertDescription maxWidth="sm">{itemDetail?.adminComment}</AlertDescription>
									) : null}
								</Alert>
							</SimpleGrid>
						) : null}

						{isItemRegisterCompleted() ? (
							<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
								<NumberField
									label={t('item.sales_ticket_goal_count') + ' *'}
									type={'number'}
									value={ticketGoalCount}
									disabled={!editabled}
									onChange={(e, i) => {
										if (!isNaN(i)) {
											setTicketGoalCount(i);
											setTicketHpPoint(Math.ceil(itemDetail?.itemPriceYen / (i * 7)));
										} else {
											setTicketGoalCount(0);
											setTicketHpPoint(0);
										}
									}}
								/>
								<NumberField label={t('item.sales_ticket_hp_point')} value={ticketHpPoint} disabled={true} />
							</SimpleGrid>
						) : null}

						{isItemRegisterCompleted() ? (
							<>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<Flex direction={'row'}>
										<Text
											fontSize={'md'}
											textColor={textColorPrimary}
											mr={{
												base: '10px',
												md: '20px'
											}}
											fontWeight={'bold'}
											justifyContent={'center'}
											alignSelf={'center'}
										>
											{t('item.is_visible') + ' *'}
										</Text>
										<Checkbox isChecked={!itemDetail?.invisible} />
									</Flex>
									{!itemDetail?.invisible ? (
										<ButtonField
											buttonName={t('item.hide')}
											backgroundColor={'red'}
											onClick={() => {
												// itemUpdateInvisible();
												setIsShowModalHide(true);
											}}
										/>
									) : null}
								</SimpleGrid>
							</>
						) : null}

						{isItemRegisterCompleted() && isItemOnSaleOrEndSale() ? (
							<>
								<HSeparator mb={{base: 10, md: 0}} />
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<TextAreaField
										label={t('item.reward_description')}
										value={itemDetail?.producerRewardDescription}
										disabled={true}
									/>
									<Flex direction={'column'}>
										<FormLabel
											display="flex"
											ms="10px"
											fontSize="sm"
											color={textColorPrimary}
											fontWeight="bold"
											_hover={{ cursor: 'pointer' }}
										>
											{t('item.used_rewards')}
										</FormLabel>
										{itemDetail?.skillRewards.map(s => {
											if (s.type === RewardType.CHARACTER) {
												return (
													<InputField
														value={
															CharacterTypeString(t, s.character.type) +
															':  ' +
															t('common.score', { score: s.character.point })
														}
														disabled={true}
													/>
												);
											} else {
												return (
													<InputField
														value={
															SkillTypeString(t, s.skill.type) + ':  ' + t('common.score', { score: s.skill.point })
														}
														disabled={true}
													/>
												);
											}
										})}
									</Flex>
								</SimpleGrid>
								<HSeparator mb={{base: 10, md: 0}} />
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<InputField label={t('item.request_use_idol_count')} value={itemDetail?.applyCount} disabled={true} />
									{itemDetail?.applyCount >= 0 ? (
										<ButtonField
											label={t('item.request_use_idol_list')}
											buttonName={t('item.show_list')}
											onClick={() => {
												setShowIdolListModalType(IdolModalType.APPLY);
												setIsShowIdolListModal(true);
											}}
										/>
									) : null}
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<InputField label={t('item.used_idol_count')} value={itemDetail?.usedCount} disabled={true} />
									{itemDetail?.usedCount >= 0 ? (
										<ButtonField
											label={t('item.used_idol_list')}
											buttonName={t('item.show_list')}
											onClick={() => {
												setShowIdolListModalType(IdolModalType.USED);
												setIsShowIdolListModal(true);
											}}
										/>
									) : null}
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<InputField
										label={t('item.obtained_item_count')}
										value={itemDetail?.obtainmentCount}
										disabled={true}
									/>
									{itemDetail?.obtainmentCount >= 0 ? (
										<ButtonField
											label={t('item.obtained_idol_list')}
											buttonName={t('item.show_list')}
											onClick={() => {
												setShowIdolListModalType(IdolModalType.OBTAINED);
												setIsShowIdolListModal(true);
											}}
										/>
									) : null}
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<InputField
										label={t('item.participation_count')}
										value={itemDetail?.participationCount}
										disabled={true}
									/>
									{itemDetail?.participationCount >= 0 ? (
										<ButtonField
											label={t('item.participation_idol_list')}
											buttonName={t('item.show_list')}
											onClick={() => {
												setShowIdolListModalType(IdolModalType.PARTICIPATION);
												setIsShowIdolListModal(true);
											}}
										/>
									) : null}
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									<InputField
										label={t('item.participation_cancel_count')}
										value={itemDetail?.participationCancelCount}
										disabled={true}
									/>
									{itemDetail?.participationCancelCount >= 0 ? (
										<ButtonField
											label={t('item.participation_cancel_count')}
											buttonName={t('item.show_list')}
											onClick={() => {
												setShowIdolListModalType(IdolModalType.CANCEL);
												setIsShowIdolListModal(true);
											}}
										/>
									) : null}
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
									{itemDetail?.usedCount >= 0 ? (
										<ButtonField
											label={t('item.refund_idol_list')}
											buttonName={t('item.show_list')}
											onClick={() => {
												setShowIdolListModalType(IdolModalType.REFUND);
												setIsShowIdolListModal(true);
											}}
										/>
									) : null}
								</SimpleGrid>
							</>
						) : null}

						<HSeparator mb={{base: 10, md: 0}} />

						{/* 노출 설정 */}
						<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
							{t('item.setting_display')}
						</Text>

						<ItemDateSelector
							startDateTime={displayStartDateTime}
							setStartDateTime={setDisplayStartDateTime}
							isShowStartDateTime={isShowPickerDisplayStartDateTime}
							setIsShowStartDateTime={setIsShowPickerDisplayStartDateTime}
							startDateTitle={t('item.exposure_start_date')}
							endDateTime={displayEndDateTime}
							closeAllPopup={closeAllPopup}
							setEndDateTime={setDisplayEndDateTime}
							isShowEndDateTime={isShowPickerDisplayEndDateTime}
							setIsShowEndDateTime={setIsShowPickerDisplayEndDateTime}
							endDateTitle={t('item.exposure_end_date')}
							description={t('item.exposure_date_desc')}
							disabled={!editabled}
						/>

						<ItemDateSelector
							startDateTime={salesStartDateTime}
							setStartDateTime={setSalesStartDateTime}
							isShowStartDateTime={isShowPickerSalesStartDateTime}
							setIsShowStartDateTime={setIsShowPickerSalesStartDateTime}
							startDateTitle={t('item.sales_start_date')}
							endDateTime={salesEndDateTime}
							closeAllPopup={closeAllPopup}
							setEndDateTime={setSalesEndDateTime}
							isShowEndDateTime={isShowPickerSalesEndDateTime}
							setIsShowEndDateTime={setIsShowPickerSalesEndDateTime}
							endDateTitle={t('item.sales_end_date')}
							description={t('item.sales_date_desc')}
							disabled={!editabled}
						/>

						<ItemDateSelector
							startDateTime={usableStartDateTime}
							setStartDateTime={setUsableStartDateTime}
							isShowStartDateTime={isShowPickerUsableStartDateTime}
							setIsShowStartDateTime={setIsShowPickerUsableStartDateTime}
							startDateTitle={t('item.usability_start_date')}
							endDateTime={usableEndDateTime}
							closeAllPopup={closeAllPopup}
							setEndDateTime={setUsableEndDateTime}
							isShowEndDateTime={isShowPickerUsableEndDateTime}
							setIsShowEndDateTime={setIsShowPickerUsableEndDateTime}
							endDateTitle={t('item.usability_end_date')}
							description={t('item.usability_date_desc')}
							leastStartDate={salesStartDateTime}
							leastStartDateTitle={t('item.sales_start_date')}
							leastEndDate={salesEndDateTime}
							leastEndDateTitle={t('item.sales_end_date')}
							disabled={!editabled}
						/>

						{/* 카테고리 설정 */}
						<HSeparator mb={{base: 10, md: 0}} />
						<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
							{t('item.setting_category')}
						</Text>

						<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
							<InputField label={t('store.item')} value={t('store.item_crowdfunding')} disabled={true} />
							<SelectField
								label={t('item.category_type')}
								mt={'0px'}
								value={itemDetail?.category}
								disabled={!editabled}
								options={Object.keys(ItemCategoryType)}
								onChange={e => {
									setItemDetail(prevState => ({
										...prevState, // 기존 상태를 유지
										category: e.target.value as ItemCategoryType // category 값을 업데이트
									}));
								}}
							/>
						</SimpleGrid>

						{/* 카테고리 설정 */}
						<HSeparator mb={{base: 10, md: 0}} />
						<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
							{t('item.setting_information')}
						</Text>

						<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
							<InputField
								label={t('item.name')}
								value={itemDetail?.name}
								disabled={!editabled}
								extra={t('item.name_desc')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setItemDetail(prevState => ({
										...prevState,
										name: e.target.value
									}));
								}}
							/>
							<NumberField
								label={t('item.sales_price')}
								onChange={(s, i) => {
									setItemDetail(prevState => ({
										...prevState,
										itemPriceYen: i
									}));
								}}
								value={itemDetail?.itemPriceYen}
								min={0}
								disabled={!editabled}
								extra={t('item.sales_price_desc')}
							/>
						</SimpleGrid>

						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'} justifyContent={'center'}>
							<Text
								display="flex"
								ms="10px"
								fontSize="sm"
								color={textColorPrimary}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
							>
								{t('item.thumbnail')}
							</Text>
							<DropImageBox
								imgUrls={thumbnailImgUrls}
								setImgUrls={setThumbnailImgUrls}
								imgFiles={thumbnailImgFiles}
								setImgFiles={setThumbnailImgFiles}
								maxFiles={1}
								title={t('store.thumbnail')}
								disabled={!editabled}
								width={'xs'}
							/>
							<Text fontSize="sm" color={textColorSecondary} fontWeight="400" ps={'10px'} whiteSpace={'pre-line'}>
								{t('item.thumbnail_img_desc')}
							</Text>
						</SimpleGrid>

						<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
							<NumberField
								label={t('item.sales_quantity')}
								value={itemDetail?.quantity}
								disabled={!editabled}
								onChange={(s, i) => {
									setItemDetail(prevState => ({
										...prevState,
										quantity: i
									}));
								}}
								extra={t('item.sales_quantity_desc')}
							/>
						</SimpleGrid>

						{/* 상세 설명 */}
						<HSeparator mb={{base: 10, md: 0}} />
						<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
							{t('item.setting_detail')}
						</Text>

						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'} justifyContent={'center'}>
							<Text
								display="flex"
								ms="10px"
								fontSize="sm"
								color={textColorPrimary}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
							>
								{t('item.detailed_img')}
							</Text>
							<DropImageBox
								imgUrls={detailImgUrls}
								setImgUrls={setDetailImgUrls}
								imgFiles={detailImgFiles}
								setImgFiles={setDetailImgFiles}
								maxFiles={5}
								title={t('item.detailed_img')}
								disabled={!editabled}
								width={'100%'}
							/>
							<Text fontSize="sm" color={textColorSecondary} fontWeight="400" ps={'10px'} whiteSpace={'pre-line'}>
								{t('item.detailed_img_desc')}
							</Text>
						</SimpleGrid>

						{/* 상세 설명 */}
						<HSeparator mb={{base: 10, md: 0}} />
						<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
							{t('item.description_how_to_use')}
						</Text>

						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
							<TextAreaField
								label={t('item.how_to_use') + ' *'}
								minH={'250px'}
								value={itemDetail?.usageInstruction}
								mb={'10px'}
								onChange={e => {
									setItemDetail(prevState => ({
										...prevState,
										usageInstruction: e.target.value
									}));
								}}
								disabled={!editabled}
								placeholder={t('item.how_to_use_placeholder')}
							/>
						</SimpleGrid>
						<SimpleGrid
							columns={{base: 1, md: 1, xl: 1}}
							gap={'20px'}
							justifyContent={'center'}
						>
							<DynamicInputField
								label={'item.cautions'}
								placeholder={'item.input_cautions_placeholder'}
								originalValues={cautionsOrg}
								values={cautions}
								disabled={!editabled}
								setValues={setCautions}
							/>

						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
							<SelectField
								label={t('item.lesson_type')}
								mt={'0px'}
								disabled={!editabled}
								value={itemDetail?.online ? ItemLessonType.ONLINE : ItemLessonType.OFFLINE}
								options={Object.keys(ItemLessonType)}
								displayOptionsString={ItemLessonTypeString}
								onChange={e => {
									setItemDetail(prevState => ({
										...prevState,
										online: e.target.value === ItemLessonType.ONLINE
									}));
								}}
							/>
							{/*<InputField*/}
							{/*	label={t('item.available_lesson_time')}*/}
							{/*	value={itemDetail?,}*/}
							{/*	disabled={!isEditabled}*/}
							{/*	onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
							{/*		setUsingItemSchedule(e.target.value);*/}
							{/*	}}*/}
							{/*/>*/}
						</SimpleGrid>

						{/*<ButtonField*/}
						{/*	buttonName={'save'}*/}
						{/*	onClick={() => {*/}
						{/*		postItem();*/}
						{/*	}}*/}
						{/*/>*/}
					</Grid>
				</Flex>
			</Card>

			{/* 리워드 팝업  */}
			<ModalSelectReward
				isOpen={isShowRewardModal}
				onClose={() => {
					setIsShowRewardModal(false);
				}}
				onSelectReward={reward => {
					const index = itemRewards.findIndex(x => x.rewardId === reward.rewardId);
					if (index < 0) {
						setItemRewards([...itemRewards, reward]);
					}

					setIsShowRewardModal(false);
				}}
			/>

			{/* 아이템 신청 승인/반려 모달 */}
			<ModalBasic
				isOpen={isShowItemRegisterModal && isItemRequestApproveOrRejected}
				size={'xl'}
				onClose={() => {
					setIsShowItemRegisterModal(false);
				}}
				title={t('item.register_approved', { name: itemDetail?.name })}
				desc={
					t('item.register_approved_desc') +
					(itemRewards.length === 0 ? t('item.register_approved_desc_not_rewards') : '')
				}
				firstButton={{
					title: t('common.cancel'),
					onClick: () => {
						setIsShowItemRegisterModal(false);
					}
				}}
				secondaryButton={{
					title: t('provider.approved'),
					onClick: () => {
						setIsShowItemRegisterModal(false);
						registerItemApprove();
					}
				}}
			/>

			<Modal
				isOpen={isShowItemRegisterModal && !isItemRequestApproveOrRejected}
				onClose={() => {
					setIsShowItemRegisterModal(false);
				}}
				size={'xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('item.store_rejected', { name: itemDetail?.name })}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color={textColorPrimary} fontSize="md" fontWeight="500" ms={'10px'} mb={'10px'}>
							{t('item.register_rejected_desc')}
						</Text>
						<TextAreaField
							label={t('item.register_rejected_reason')}
							onChange={e => {
								setRejectReason(e.target.value);
							}}
							value={rejectReason}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="brand"
							mr={3}
							onClick={() => {
								if (rejectReason === null || rejectReason.length < 10) {
									showToast(t('error.input_admin_comment'));
									return;
								}

								registerItemReject();
								setIsShowItemRegisterModal(false);
							}}
						>
							{t('provider.rejected')}
						</Button>
						<Button
							onClick={() => {
								setRejectReason('');
								setIsShowItemRegisterModal(false);
							}}
						>
							{t('common.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<ModalIdolList
				isOpen={isShowIdolListModal}
				onClose={() => {
					setIsShowIdolListModal(false);
				}}
				modalType={showIdolListModalType}
				crowdfundingItemId={String(itemDetail?.crowdFundingItemId)}
				item={itemDetail}
			/>

			<Modal
				isOpen={isShowUpdateContentsModal}
				onClose={() => {
					setIsShowUpateContentsModal(false);
				}}
				size={'4xl'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<CrowdFundingItemUpdateDetail
						itemId={String(itemDetail?.crowdFundingItemId)}
						requestChangeParams={() => {
							setIsShowUpateContentsModal(false);
							setThumbnailImgUrls([]);
							setDetailImgUrls([]);
							getItem();
						}}
					/>
				</ModalContent>
			</Modal>

			{/*</Modal>*/}

			{/*/!* 보상 팝업  *!/*/}
			{/*<Modal></Modal>*/}
			<ModalBasic
				isOpen={isShowModalHide}
				onClose={() => {
					setIsShowModalHide(false);
				}}
				title={t('item.confirm_item_hide')}
				secondaryButton={{
					title: t('common.hide'),
					onClick: () => {
						// postItem();
						itemUpdateInvisible();
						setIsShowModalHide(false);
					}
				}}
			/>

			<ModalBasic
				isOpen={isShowModalSalesStop}
				onClose={() => {
					setIsShowModalSalesStop(false);
				}}
				title={t('item.confirm_item_sales_stop')}
				secondaryButton={{
					title: t('store.sales_stop'),
					onClick: () => {
						itemSalesStop();
						setIsShowModalSalesStop(false);
					}
				}}
			/>

			{/*/!* 업데이트 팝업  *!/*/}
			{/*<Modal></Modal>*/}
		</Flex>
	);
}
