import { BaseApi } from './baseAPI';
import {
	URL_CROWDFUNDING_ITEM,
	URL_CROWDFUNDING_ITEM_DETAIL,
	URL_CROWDFUNDING_ITEM_INVISIBLE,
	URL_CROWDFUNDING_ITEM_PARTICIPANTS,
	URL_CROWDFUNDING_ITEM_PARTICIPANTS_ITEM_STATUS,
	URL_CROWDFUNDING_ITEM_REGISTER_APPROVE,
	URL_CROWDFUNDING_ITEM_REGISTER_REJECT,
	URL_CROWDFUNDING_ITEM_REWARD,
	URL_CROWDFUNDING_ITEM_SALES_PERIOD,
	URL_CROWDFUNDING_ITEM_SALES_STOP,
	URL_CROWDFUNDING_ITEM_UPDATE_APPROVE,
	URL_CROWDFUNDING_ITEM_UPDATE_REJECT,
	URL_CROWDFUNDING_ITEM_USED,
	URL_PROVIDER, URL_PROVIDER_BANK_ACCOUNT,
	URL_PROVIDER_BRAND,
	URL_PROVIDER_BRAND_DETAIL,
	URL_PROVIDER_BRAND_DETAIL_APPROVE,
	URL_PROVIDER_BRAND_DETAIL_REJECT,
	URL_PROVIDER_DETAIL, URL_PROVIDER_ITEM_META_COUNT,
	URL_PROVIDER_REGISTER_APPROVE,
	URL_PROVIDER_REGISTER_REJECT,
	URL_PROVIDER_WITHDRAW_APPROVE,
	URL_PROVIDER_WITHDRAW_REJECT
} from "./URLS";
import {
	BrandShopStatusType,
	BusinessStructureType,
	CrowdFundingItemParticipantsItemStatusType,
	CrowdFundingItemParticipantsStatusType,
	ItemCategoryType,
	ItemRegisterStatusType,
	ItemSalesStatusType,
	MediaType,
	ProviderStatusType
} from '../types/enums';
import { PagingRequest } from './commonAPI';
import { RewardsTrain } from './projectAPI';
import { convertObjectToQueryParams } from '../helper/functions';

/**
 * Common Controller
 */
export class CrowdFundingApi extends BaseApi {
	getProvider = (request: ProviderRequest) => {
		return this.axios
			.get<ProviderListResponse>(URL_PROVIDER, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getProviderDetail = (storeAccoundId: string) => {
		return this.axios
			.get<ProviderDetailResponse>(URL_PROVIDER_DETAIL.replace('{storeAdminAccountId}', storeAccoundId))
			.then(response => response)
			.catch(error => error.response);
	};

	putProviderRegisterApprove = (crowdFundingProviderId: string) => {
		return this.axios
			.put(URL_PROVIDER_REGISTER_APPROVE.replace('{crowdFundingProviderId}', crowdFundingProviderId))
			.then(response => response)
			.catch(error => {
				throw error;
			});
	};

	putProviderRegisterRejected = (crowdFundingProviderId: string, reason: string) => {
		return this.axios
			.put(URL_PROVIDER_REGISTER_REJECT.replace('{crowdFundingProviderId}', crowdFundingProviderId), {
				adminComment: reason
			})
			.then(response => response)
			.catch(error => {
				throw error;
			});
	};

	putProviderWithdrawApprove = (crowdFundingProviderId: string) => {
		return this.axios
			.put(URL_PROVIDER_WITHDRAW_APPROVE.replace('{crowdFundingProviderId}', crowdFundingProviderId))
			.then(response => response)
			.catch(error => {
				throw error;
			});
	};

	putProviderWithdrawRejected = (crowdFundingProviderId: string, reason: string) => {
		return this.axios
			.put(URL_PROVIDER_WITHDRAW_REJECT.replace('{crowdFundingProviderId}', crowdFundingProviderId), {
				adminComment: reason
			})
			.then(response => response)
			.catch(error => {
				throw error;
			});
	};

	getBrandShopList = (request: BrandShopRequest) => {
		return this.axios
			.get<ProviderListResponse>(URL_PROVIDER_BRAND, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	putBrandApprove = (crowdFundingProviderId: string) => {
		return this.axios
			.put(URL_PROVIDER_BRAND_DETAIL_APPROVE.replace('{crowdFundingProviderId}', crowdFundingProviderId))
			.then(response => response)
			.catch(error => {
				throw error;
			});
	};

	putBrandRejected = (crowdFundingProviderId: string, reason: string) => {
		return this.axios
			.put(URL_PROVIDER_BRAND_DETAIL_REJECT.replace('{crowdFundingProviderId}', crowdFundingProviderId), {
				adminComment: reason
			})
			.then(response => response)
			.catch(error => {
				throw error;
			});
	};

	getBrandShopDetail = (crowdFundingProviderId: string) => {
		return this.axios
			.get<ProviderListResponse>(URL_PROVIDER_BRAND_DETAIL.replace('{crowdFundingProviderId}', crowdFundingProviderId))
			.then(response => response)
			.catch(error => error.response);
	};

	getCrowdFundingItemList = (request: CrowdFundingItemListRequest) => {
		return this.axios
			.get<CrowdFundingItemListResponse>(URL_CROWDFUNDING_ITEM, {
				params: request,
				paramsSerializer: params => convertObjectToQueryParams(params)
			})
			.then(response => response)
			.catch(error => error.response);
	};

	getCrowdFundingItem = (crowdFundingItemId: string) => {
		return this.axios
			.get<CrowdFundingItemDetailResponse>(
				URL_CROWDFUNDING_ITEM_DETAIL.replace('{crowdFundingItemId}', crowdFundingItemId)
			)
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	getCrowdFundingItemParticipants = (crowdFundingItemId: string, request: CrowdFundingItemParticipantsListRequest) => {
		return this.axios
			.get<CrowdFundingItemApplyIdolListResponse>(
				URL_CROWDFUNDING_ITEM_PARTICIPANTS.replace('{crowdFundingItemId}', crowdFundingItemId),
				{
					params: request
				}
			)
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	getCrowdFundingItemParticipantsItemStatus = (
		crowdFundingItemId: string,
		request: CrowdFundingItemParticipantsItemStatusListRequest
	) => {
		return this.axios
			.get<CrowdFundingItemApplyIdolListResponse>(
				URL_CROWDFUNDING_ITEM_PARTICIPANTS_ITEM_STATUS.replace('{crowdFundingItemId}', crowdFundingItemId),
				{
					params: request
				}
			)
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	putCrowdFundingItemReigsterApprove = (
		crowdFundingItemId: string,
		ticketGoalCount: number,
		skillRewardIds: number[],
		producerRewardDescription: string
	) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_REGISTER_APPROVE.replace('{crowdFundingItemId}', crowdFundingItemId), {
				ticketGoalCount: ticketGoalCount,
				skillRewardIds: skillRewardIds,
				producerRewardDescription: producerRewardDescription
			})
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	putCrowdFundingItemReigterReject = (crowdFundingItemId: string, reason: string) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_REGISTER_REJECT.replace('{crowdFundingItemId}', crowdFundingItemId), {
				adminComment: reason
			})
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	putCrowdFundingItemUpdateApprove = (crowdFundingItemId: string) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_UPDATE_APPROVE.replace('{crowdFundingItemId}', crowdFundingItemId))
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	putCrowdFundingItemUpdateReject = (crowdFundingItemId: string, reason: string) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_UPDATE_REJECT.replace('{crowdFundingItemId}', crowdFundingItemId), {
				adminComment: reason
			})
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	// getCrowdFundingItemApplyIdol = (crowdFundingItemId: string, request: PagingRequest) => {
	// 	return this.axios
	// 		.get<CrowdFundingItemApplyIdolListResponse>(
	// 			URL_CROWDFUNDING_ITEM_PARTICIPANTS.replace('{crowdFundingItemId}', crowdFundingItemId),
	// 			{ params: request }
	// 		)
	// 		.then(response => response)
	// 		.catch(error => {
	// 			throw error;
	// 			return error;
	// 		});
	// };

	putCrowdFundingItemInvisible = (crowdFundingItemId: string) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_INVISIBLE.replace('{crowdFundingItemId}', crowdFundingItemId))
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	getCrowdFundingProviderItemMetaCount = (crowdFundingProviderId: string, request: PagingRequest) => {
		return this.axios
			.get<CrowdFundingItemMetaCountListResponse>(
				URL_PROVIDER_ITEM_META_COUNT.replace('{crowdFundingProviderId}', crowdFundingProviderId),
				{
					params: request
				}
			)
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	putCrowdFundingItemSalesPeriod = (crowdFundingItemId: string, startDate: string, endDate: string) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_SALES_PERIOD.replace('{crowdFundingItemId}', crowdFundingItemId), {
				saleStartDateTime: startDate,
				saleEndDateTime: endDate
			})
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	putCrowdFundingItemSalesStop = (crowdFundingItemId: string) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_SALES_STOP.replace('{crowdFundingItemId}', crowdFundingItemId))
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	putCrowdFundingItemUsed = (participationId: string) => {
		return this.axios
			.put(URL_CROWDFUNDING_ITEM_USED.replace('{participationId}', participationId))
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};

	postCrowdFundingItemUsedReward = (participationId: string, request: FormData) => {
		return this.axios
			.post(URL_CROWDFUNDING_ITEM_REWARD.replace('{participationId}', participationId), request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};


	getProviderBankAccount = (providerId: string) => {
		return this.axios.get<CrowdFundingProviderBankAccountResponse>(URL_PROVIDER_BANK_ACCOUNT.replace('{crowdFundingProviderId}', providerId)).then((response) => response).catch((error) => {
			throw error;
			return error;
		});
	}

	getCrowdFundingItemUsedReward = (participationId: string) => {
		return this.axios
			.get<CrowdFundingProviderRewardFileResponse>(URL_CROWDFUNDING_ITEM_REWARD.replace('{participationId}', participationId))
			.then(response => response)
			.catch(error => {
				throw error;
				return error;
			});
	};
}

/**
 *
 *
 * 리퀘스트들
 *
 *
 */
export type ProviderRequest = PagingRequest & {
	/**
	 * (query)
	 * 검색 조건 타입
	 * EMAIL, NAME
	 */
	searchType?: string;

	/**
	 * (query)
	 *  검색어
	 */
	searchWord?: string;

	/**
	 * (query)
	 *  판매 활성 여부 (기본 true)
	 */
	providerStatus?: string;
};

export type BrandShopRequest = PagingRequest & {
	/**
	 * (query)
	 * 검색 조건 타입
	 * EMAIL, NAME
	 */
	searchType?: string;

	/**
	 * (query)
	 *  검색어
	 */
	searchWord?: string;

	/**
	 * (query)
	 *  판매 활성 여부 (기본 true)
	 */
	brandStatus?: string;
};

export type CrowdFundingItemListRequest = PagingRequest & {
	/**
	 * 검색어. 아이템 이름만 대상으로 함
	 */
	searchWord?: string;

	/**
	 * Available values : OPEN_SOON, ON_SALE, SOLD_OUT, END_SALE
	 * 아이템 판매 상태
	 */
	salesStatusSet?: ItemSalesStatusType[];

	/**
	 * 아이템 등록 상태
	 * REGISTERED, APPROVED, REJECTED
	 */
	registerStatusSet?: ItemRegisterStatusType[];

	/**
	 * 아이템 수정 상태
	 * REGISTERED, APPROVED, REJECTED
	 */
	updateStatusSet?: ItemRegisterStatusType[];

	/**
	 * 아이템 카테고리
	 */
	categorySet?: ItemCategoryType[];

	/**
	 * 아이템 제공자 아이디. 하이피 어드민 조회시에만 사용되고, 판매점에서는 본인의 아이디만 사용됨
	 */
	crowdFundingProviderId?: string;
};

export type CrowdFundingItemParticipantsListRequest = PagingRequest & {
	/**
	 * 아이템 상태 타입
	 */
	participationStatus?: CrowdFundingItemParticipantsStatusType;
};

export type CrowdFundingItemParticipantsItemStatusListRequest = PagingRequest & {
	/**
	 * 아이템 상태 타입
	 */
	itemStatus?: CrowdFundingItemParticipantsItemStatusType;
};

/**
 *
 *
 * 리스폰스들
 *
 *
 */
export type ProviderListResponse = {
	products: AdminCrowdFundingProviderDto[];
	count: number;
};

export type ProviderDetailResponse = {
	providerDetail: AdminCrowdFundingProviderDetailDto;
};

export type CrowdFundingItemListResponse = {
	/**
	 * 아이템 목록
	 */
	itemBasicTrain: CrowdFundingItemDto[];

	/**
	 * 아이템 목록 갯수
	 */
	count: number;

	/**
	 * 상세 정보 입력 여부, false의 경우 아이템 등록 불가
	 */
	detailInRegistered: boolean;
};

export type CrowdFundingItemDetailResponse = {
	itemDetail: CrowdFundingItemDetailDto;
};

export type CrowdFundingItemApplyIdolListResponse = {
	idolTrain: CrowdFundingItemApplyIdolTrain[];
	count: number;
};

export type CrowdFundingItemMetaCountListResponse = {
	itemMetaCountTrain: CrowdFundingItemMetaCountTrain[];
	count: number;
};

export type CrowdFundingProviderBankAccountResponse = {
	bankAccount: ProviderBankAccountTrain;
}

export type CrowdFundingProviderRewardFileResponse = {
	rewardTrain: CrowdFundingItemRewardTrain[];
}

export type AdminCrowdFundingProviderDto = {
	/**
	 * 판매점 아이디
	 */
	crowdFundingProviderId: number;

	/**
	 * 상호명
	 */
	name: string;

	/**
	 * 회원 ID
	 */
	accountId: string;

	/**
	 * 판매점 대표 썸네일
	 */
	thumbnailImgUrl: string;

	/**
	 * 대표 이름
	 */
	presidentFirstName: string;

	/**
	 * 대표 성
	 */
	presidentLastName: string;

	/**
	 * 사업자 유형
	 */
	businessStructureType: BusinessStructureType;

	/**
	 * 사업자 번호
	 */
	businessRegistrationNo: string;

	/**
	 * 이메일 주소: 인증한 회원의 이메일 주소
	 */
	email: string;

	/**
	 * 판매점 전화번호: 판매점 대표 번호
	 */
	businessPhoneNumber: string;

	/**
	 * CS 담당자: 회사에서 연락 시, 해당 업체의 CS 담당자 이름
	 */
	managerName: string;

	/**
	 * 담당자 전화번호
	 */
	managerPhoneNumber: string;

	/**
	 * 크라펀 아이템 판매점 상태
	 */
	providerStatus: ProviderStatusType;

	/**
	 *     가입 완료 일자
	 */
	registerApprovedDateTime: string;

	/**
	 * 브랜드 상태
	 */
	brandStatus: BrandShopStatusType;
};

export type AdminCrowdFundingProviderDetailDto = {
	/**
	 * 판매점 아이디
	 */
	crowdFundingProviderId: number;

	/**
	 * 상호명
	 */
	name: string;

	/**
	 * 회원 ID
	 */
	accountId: string;

	/**
	 * 판매점 대표 썸네일
	 */
	thumbnailImgUrl: string;

	/**
	 * 대표 이름
	 */
	presidentFirstName: string;

	/**
	 * 대표 성
	 */
	presidentLastName: string;

	/**
	 * 사업자 유형
	 */
	businessStructureType: BusinessStructureType;

	/**
	 * 사업자 번호
	 */
	businessRegistrationNo: string;

	/**
	 * 국적 판매점 나라. 2자리 문자열. 필요시 추후 전화번호 국번으로 변경
	 */
	countryCode: string;

	/**
	 * 주소 판매점 사업지 주소(시/도)
	 */
	businessAddressCity: string;

	/**
	 * 주소 판매점 사업지 주소(구/군)
	 */
	businessAddressState: string;

	/**
	 * 주소 판매점 사업지 주소(상세1)
	 */
	businessAddressStreetAddress1: string;

	/**
	 * 주소 판매점 사업지 주소(상세2)
	 */
	businessAddressStreetAddress2: string;

	/**
	 * 우편 번호: 판매점 사업지 주소의 우편 번호
	 */
	businessPostalCode: string;

	/**
	 * 이메일 주소: 인증한 회원의 이메일 주소
	 */
	email: string;

	/**
	 * 판매점 전화번호: 판매점 대표 번호
	 */
	businessPhoneNumber: string;

	/**
	 * 대표자 전화번호: 판매점의 대표자 전화번호
	 */
	presidentPhoneNumber: string;

	/**
	 * CS 담당자: 회사에서 연락 시, 해당 업체의 CS 담당자 이름
	 */
	managerName: string;

	/**
	 * 담당자 전화번호
	 */
	managerPhoneNumber: string;

	/**
	 * 인보이스 번호: 일본 내 상용되고 있는 소비세를 위해 시행하는 번호
	 */
	invoiceNumber: string;

	/**
	 * 크라펀 아이템 판매점 상태
	 */
	providerStatus: ProviderStatusType;

	/**
	 *     가입 완료 일자
	 */
	registerApprovedDateTime: string;

	/**
	 * 어드민 추가 의견
	 */
	adminComment: string;
};

export type CrowdFundingItemDto = {
	/**
	 * 아이템 아이디
	 */
	crowdFundingItemId: number;

	/**
	 * 이름
	 */
	name: string;

	/**
	 * 커버 이미지
	 */
	coverImgUrl: string;

	/**
	 * 카테고리
	 */
	category: ItemCategoryType;

	/**
	 * 아이템 등록 신청 상태
	 */
	registerStatus: ItemRegisterStatusType;

	/**
	 * 아이템 수정 신청 상태
	 */
	updateStatus: ItemRegisterStatusType;

	/**
	 * 등록 요청일자
	 */
	createDateTime: string;

	/**
	 * 판매 시작 일시
	 */
	saleStartDateTime: string;

	/**
	 * 판매 종료 일시
	 */
	saleEndDateTime: string;

	/**
	 * 사용 신청 아이돌 수
	 */
	applyCount: number;

	/**
	 * 판매상태
	 */
	salesStatus: ItemSalesStatusType;

	/**
	 * 재고
	 */
	stock: string;
};

/**
 * 아이템 상세 정보
 */
export type CrowdFundingItemDetailDto = {
	/**
	 * 아이템 아이디
	 */
	crowdFundingItemId: number;

	/**
	 * 이름
	 */
	name: string;

	/**
	 * 커버 이미지
	 */
	coverImgUrl: string;

	/**
	 * 카테고리
	 */
	category: ItemCategoryType;

	/**
	 *  아이템 등록 신청 상태
	 */
	registerStatus: ItemRegisterStatusType;

	/**
	 * 아이템 수정 신청 상태
	 */
	updateStatus: ItemRegisterStatusType;

	/**
	 * 등록 요청일자
	 */
	createDateTime: string;

	/**
	 * 판매 시작 일시
	 */
	saleStartDateTime: string;

	/**
	 * 판매 종료 일시
	 */
	saleEndDateTime: string;

	/**
	 * 아이템 전체 수량
	 */
	quantity: number;

	/**
	 * 판매 중지 여부(판매 기간과 상관 없이 판매 중지 처리 시
	 */
	stop: boolean;

	/**
	 * 사용 신청 수
	 */
	applyCount: number;

	/**
	 * 아이템 판매 상태
	 */
	salesStatus: ItemSalesStatusType;

	/**
	 * 재고
	 */
	stock: number;

	/**
	 * 사용 방법
	 */
	usageInstruction: string;

	/**
	 * 크라펀 제공자 아이디
	 */
	providerId: number;

	/**
	 * 크라펀 아이템 판매점 이름
	 */
	providerName: string;

	/**
	 * 판매점 썸네일 이미지 주소
	 */
	providerThumbnailImgUrl: string;

	/**
	 * 티켓 이름
	 */
	ticketName: string;

	/**
	 * 티켓 가격(HP)
	 */
	ticketPrice: number;

	/**
	 * 아이템 가격(YEN)
	 */
	itemPriceYen: number;

	/**
	 * 목표 티켓 개수
	 */
	ticketGoalCount: number;

	/**
	 * 노출 시작 일시
	 */
	displayStartDateTime: string;

	/**
	 * 노출 종료 일시
	 */
	displayEndDateTime: string;

	/**
	 * 사용가능 시작 일시
	 */

	usableStartDateTime: string;

	/**
	 * 사용가능 종료 일시
	 */
	usableEndDateTime: string;

	/**
	 * 보상 스킬
	 */
	skillRewards: RewardsTrain[];

	/**
	 * 보상 내용
	 */
	producerRewardDescription: string;

	/**
	 * 아이템 상세 파일 정보
	 */
	detailFileUrls: MediaDto[];

	/**
	 * 레슨 방식(온라인 or 오프라인)
	 */
	online: boolean;

	/**
	 * 노출 여부(판매 중지는 아니나 일시적으로 판매를 막을 경우
	 */
	invisible: boolean;

	/**
	 * 아이템 등록/수정 정보. 아이템 상세의 일부로 내려가는 경우, 상세 화면에는 이 내용을 표시
	 */
	updateRegistered: CrowdFundingItemSaveDto;

	/**
	 * 진행중인 아이템 개수
	 */
	participationCount: number;

	/**
	 * 획득한 아이템 개수
	 */
	obtainmentCount: number;

	/**
	 * 사용 완료 아이템 개수
	 */
	usedCount: number;

	/**
	 * 아이돌 찌릿 개수
	 */
	idolZziritCount: number;

	/**
	 * 프로듀서 찌릿 개수
	 */
	producerZziritCount: number;

	/**
	 * 크라펀 취소 횟수
	 */
	participationCancelCount: number;

	/**
	 * 반려 사유
	 */
	adminComment?: string;

	/**
	 * 주의사항
	 */
	cautions?: string[];
};

export type CrowdFundingItemSaveDto = {
	/**
	 * 아이템 아이디
	 */
	crowdFundingItemId: number;

	/**
	 * 이름
	 */
	name: string;

	/**
	 * 커버 이미지
	 */
	coverImgUrl: string;

	/**
	 * 카테고리
	 */
	category: ItemCategoryType;

	/**
	 *  아이템 등록 신청 상태
	 */
	registerStatus: ItemRegisterStatusType;

	/**
	 * 아이템 수정 신청 상태
	 */
	updateStatus: ItemRegisterStatusType;

	/**
	 * 아이템 전체 수량
	 */
	quantity: number;

	/**
	 * 판매 중지 여부(판매 기간과 상관 없이 판매 중지 처리 시
	 */
	stop: boolean;

	/**
	 * 사용 방법
	 */
	usageInstruction: string;

	/**
	 * 티켓 이름
	 */
	ticketName: string;

	/**
	 * 아이템 가격(YEN)
	 */
	itemPriceYen: number;

	/**
	 * 목표 티켓 개수
	 */
	ticketGoalCount: number;

	periods: ItemPeriodsDto;

	/**
	 * 스킬 리워드
	 */
	skillRewardIds: number[];

	/**
	 * 아이템 상세 파일 주소 리스트
	 */
	detailFileUrls: MediaDto[];

	/**
	 * 레슨 방식(온라인 or 오프라인)
	 */
	online: boolean;

	/**
	 * 노출 여부(판매 중지는 아니나 일시적으로 판매를 막을 경우
	 */
	invisible: boolean;
};

/**
 *  아이템 상세 파일 주소 리스트
 */
export type MediaDto = {
	/**
	 * 미디어 타입
	 * 비디오, 오디오, 이미지
	 */
	type: MediaType;

	/**
	 * URL
	 */
	url: string;
};

export type ItemPeriodsDto = {
	/**
	 * 판매 시작 일시
	 */
	saleStartDateTime: string;

	/**
	 * 판매 종료 일시
	 */
	saleEndDateTime: string;

	/**
	 * 노출 시작 일시
	 */
	displayStartDateTime: string;

	/**
	 * 노출 종료 일시
	 */
	displayEndDateTime: string;

	/**
	 * 사용가능 시작 일시
	 */

	usableStartDateTime: string;

	/**
	 * 사용가능 종료 일시
	 */
	usableEndDateTime: string;
};

// 아이돌의 참여 정보
export type CrowdFundingItemApplyIdolTrain = {
	/**
	 * 크라펀 참여 아이디
	 */
	crowdFundingParticipationId: number;

	/**
	 * 아이돌 아이디
	 */
	idolId: number;

	/**
	 * 프로필 이미지
	 */
	profileImgUrl: string;

	/**
	 * 활동명
	 */
	activityName: string;

	/**
	 * Enum:
	 *    Array [ 8 ]
	 */
	status: CrowdFundingItemParticipantsStatusType;

	/**
	 * 참여 아이템 상태
	 * Enum:
	 *    Array [ 5 ]
	 */
	itemStatus: CrowdFundingItemParticipantsItemStatusType;

	/**
	 * 보상 업로드 여부
	 */
	isUploadReward: boolean;

	/**
	 * 신청 사용 일시
	 */
	applyDateTime: string;

	/**
	 * 사용 완료 일시
	 */
	usedDateTime: string;
};

// 크라펀 아이템 관련 수치
export type CrowdFundingItemMetaCountTrain = {
	// 아이템 아이디
	itemId: number;

	// 아이돌 찌릿 횟수
	idolZziritCount: number;

	// 프로듀서 찌릿 횟수
	producerZziritCount: number;

	// 참여 수
	participationCount: number;

	// 획득 수
	obtainmentCount: number;

	// 교환 수
	exchangeCount: number;

	// 사용 신청 횟수
	applyCount: number;

	// 사용 횟수
	usedCount: number;

	// 참여 취소 횟수
	participationCancelCount: number;
};

export type ProviderBankAccountTrain = {
	/**
	 * 계좌 번호
	 */
	bankAccountNumber: string;

	/**
	 * 계좌 타입(일어 타입만 지원합니다)
	 */
	bankAccountType: string;

	/**
	 * 예금주 성
	 */
	bankAccountHolderSurName: string;

	/**
	 * 예금주 이름
	 */
	bankAccountHolderGivenName: string;

	/**
	 * 은행 명
	 */
	bankAccountBankName: string;

	/**
	 * 은행 코드
	 */
	bankAccountBankCode: string;
}


export type CrowdFundingItemRewardTrain = {
	/**
	 * 파일 타입
	 */
	type: string;

	/**
	 * URL
	 */
	url: string;
}
