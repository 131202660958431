// Chakra imports
import {
	Button,
	Flex,
	Grid,
	GridItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text,
	useBoolean
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import { useThemeColors } from '../../../../../providers/theme-provider';
import { useApi } from '../../../../../providers/api-provider';
import Card from '../../../../../components/card/Card';
import { ItemCategoryType, ItemLessonType, ItemRegisterStatusType } from '../../../../../types/enums';
import { DATA_FORMAT_FULL_DATE, dateFormat, showToast } from '../../../../../helper/functions';
import ItemDateSelector from './../components/ItemDateSelector';
import { HSeparator } from 'components/separator/Separator';
import SelectField from 'components/fields/SelectField';
import NumberField from 'components/fields/NumberField';
import DropImageBox from 'components/dropzone/DropzoneImgBox';
import TextAreaField from 'components/fields/TextAreaField';
import { CrowdFundingItemDetailDto, CrowdFundingItemSaveDto } from '../../../../../apis/crowdfundingAPI';
import ButtonField from 'components/fields/ButtonField';
import { RewardsTrain } from '../../../../../apis/projectAPI';
import ModalBasic from '../../../../../components/modal/ModalBasic';
import { IdolModalType } from './../components/ModalIdolList';
import { useLoading } from '../../../../../providers/loading-provider';
import DynamicInputField from "../../../../../components/fields/DynamicInputField";

// 금지어 목록
export default function CrowdFundingItemUpdateDetail(props: { itemId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const { textColorPrimary, textColorSecondary, textColorBrand } = useThemeColors();
	const { crowdFundingApi } = useApi();
	const { setLoading } = useLoading();

	const { itemId, requestChangeParams } = props;

	const [itemOrgDetail, setItemOrgDetail] = useState<CrowdFundingItemDetailDto>(null);

	const [itemDetail, setItemDetail] = useState<CrowdFundingItemSaveDto>(null);

	// 	const [itemOrgSalesStatus, setItemOrgSalesStatus] = useState<ItemSalesStatusType>(ItemSalesStatusType.OPEN_SOON);
	//	const [itemSalesStatus, setItemSalesStatus] = useState<ItemSalesStatusType>(ItemSalesStatusType.OPEN_SOON);

	// 상품 판매 노출 시작일
	const [displayStartDateTime, setDisplayStartDateTime] = useState<Date>(null);
	const [isShowPickerDisplayStartDateTime, setIsShowPickerDisplayStartDateTime] = useBoolean(false);

	// 상품 판매 노출 종료일
	const [displayEndDateTime, setDisplayEndDateTime] = useState<Date>(null);
	const [isShowPickerDisplayEndDateTime, setIsShowPickerDisplayEndDateTime] = useBoolean(false);

	// 상품 판매 시작일
	const [salesStartDateTime, setSalesStartDateTime] = useState<Date>(null);
	const [isShowPickerSalesStartDateTime, setIsShowPickerSalesStartDateTime] = useBoolean(false);

	// 상품 판매 종료일
	const [salesEndDateTime, setSalesEndDateTime] = useState<Date>(null);
	const [isShowPickerSalesEndDateTime, setIsShowPickerSalesEndDateTime] = useBoolean(false);

	// 상품 사용 가능 시작일
	const [useAvaiableStartDateTime, setUseAvaiableStartDateTime] = useState<Date>(null);
	const [isShowPickerUseAvaiableStartDateTime, setIsShowPickerUseAvaiableStartDateTime] = useBoolean(false);

	// 상품 사용 가능 종료일
	const [useAvaiableEndDateTime, setUseAvaiableEndDateTime] = useState<Date>(null);
	const [isShowPickerUseAvaiableEndDateTime, setIsShowPickerUseAvaiableEndDateTime] = useBoolean(false);

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgUrls, setThumbnailImgUrls] = useState<string[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgFiles, setThumbnailImgFiles] = useState<File[]>([]);

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgUrls, setDetailImgUrls] = useState<string[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgFiles, setDetailImgFiles] = useState<File[]>([]);

	const [editabled, setEditabled] = useState<boolean>(false);
	//
	const [ticketGoalCount, setTicketGoalCount] = useState<number>(0);
	const [ticketHpPoint, setTicketHpPoint] = useState<number>(0);

	const [isShowItemRegisterModal, setIsShowItemRegisterModal] = useState<boolean>(false);
	const [isItemRegisterApprove, setIsItemRegisterApprove] = useState<boolean>(false);
	const [rejectReason, setRejectReason] = useState<string>('');

	// 아이템 주의사항
	const [cautions, setCautions] = useState<string[]>([]);
	const [cautionsOrg, setCautionsOrg] = useState<string[]>([]);

	// 리워드 선택 팝업
	const [isShowRewardModal, setIsShowRewardModal] = useState<boolean>(false);
	const [itemRewards, setItemRewards] = useState<RewardsTrain[]>([]);

	// 아이템 사용 아이돌 팝업
	const [isShowIdolListModal, setIsShowIdolListModal] = useState<boolean>(false);
	const [showIdolListModalType, setShowIdolListModalType] = useState<IdolModalType>(null);

	// 업데이트 콘텐츠 팝업
	const [isShowUpdateContentsModal, setIsShowUpateContentsModal] = useState<boolean>(false);
	const [isItemUpdateAppove, setIsItemUpdateApporve] = useState<boolean>(false);

	useEffect(() => {
		if (itemId) {
			if (itemId === 'register') {
			} else {
				setThumbnailImgUrls([]);
				setDetailImgUrls([]);
				getItem();
			}
		}
	}, [itemId]);

	const getItem = () => {
		crowdFundingApi
			.getCrowdFundingItem(itemId)
			.then(response => {
				if (response.itemDetail as CrowdFundingItemDetailDto) {
					setItemOrgDetail(response.itemDetail);
					setItemDetail(response.itemDetail.updateRegistered);

					setDisplayStartDateTime(new Date(response.itemDetail.updateRegistered.periods.displayStartDateTime));
					setDisplayEndDateTime(new Date(response.itemDetail.updateRegistered.periods.displayEndDateTime));

					setSalesStartDateTime(new Date(response.itemDetail.updateRegistered.periods.saleStartDateTime));
					setSalesEndDateTime(new Date(response.itemDetail.updateRegistered.periods.saleEndDateTime));

					setUseAvaiableStartDateTime(new Date(response.itemDetail.updateRegistered.periods.usableStartDateTime));
					setUseAvaiableEndDateTime(new Date(response.itemDetail.updateRegistered.periods.usableEndDateTime));

					let cautions = response.itemDetail.updateRegistered.cautions as string[];
					setCautions(cautions && cautions.length > 0 ? cautions.map(item => item).filter(item => item !== null): []);
					setCautionsOrg(cautions && cautions.length > 0 ? cautions.map(item => item).filter(item => item !== null): []);

					if (
						response.itemDetail.updateRegistered.coverImgUrl &&
						response.itemDetail.updateRegistered.coverImgUrl !== ''
					) {
						setThumbnailImgUrls([response.itemDetail.updateRegistered.coverImgUrl]);
					} else {
						if (response.itemDetail.coverImgUrl && response.itemDetail.coverImgUrl !== '') {
							setThumbnailImgUrls([response.itemDetail.coverImgUrl]);
						}
					}

					if (
						response.itemDetail.updateRegistered.detailFileUrls &&
						response.itemDetail.updateRegistered.detailFileUrls.length > 0
					) {
						response.itemDetail.updateRegistered.detailFileUrls.map((mt: { url: string }) => {
							if (mt !== null) {
								setDetailImgUrls(prevState => {
									return [...prevState, mt.url];
								});
							}
						});
					} else {
						if (response.itemDetail.detailFileUrls && response.itemDetail.detailFileUrls.length > 0) {
							response.itemDetail.detailFileUrls.map((mt: { url: string }) => {
								if (mt !== null) {
									setDetailImgUrls(prevState => {
										return [...prevState, mt.url];
									});
								}
							});
						}
					}
				}
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const itemUpdateApprove = () => {
		setLoading(true);
		crowdFundingApi
			.putCrowdFundingItemUpdateApprove(String(itemDetail?.crowdFundingItemId))
			.then(response => {
				setLoading(false);
				showToast(t('item.completed_update_approve'));
				setThumbnailImgUrls([]);
				setDetailImgUrls([]);
				setTimeout(() => {
					requestChangeParams();
				}, 300);
			})
			.catch(() => {
				setLoading(false);
				showToast(t('error.try_again'));
			});
	};

	const itemUpdateReject = () => {
		setLoading(true);
		crowdFundingApi
			.putCrowdFundingItemUpdateReject(String(itemDetail?.crowdFundingItemId), rejectReason)
			.then(response => {
				setLoading(false);
				showToast(t('item.completed_update_reject'));
				setThumbnailImgUrls([]);
				setDetailImgUrls([]);

				setTimeout(() => {
					requestChangeParams();
				}, 300);

			})
			.catch(() => {
				setLoading(false);
				showToast(t('error.try_again'));
			});
	};

	const closeAllPopup = () => {
		setIsShowPickerDisplayEndDateTime.off();
		setIsShowPickerDisplayStartDateTime.off();
		setIsShowPickerSalesStartDateTime.off();
		setIsShowPickerSalesEndDateTime.off();
		setIsShowPickerUseAvaiableStartDateTime.off();
		setIsShowPickerUseAvaiableEndDateTime.off();
	};

	const isItemRegisterPending = () => {
		return itemDetail?.updateStatus === ItemRegisterStatusType.REGISTERED;
	};

	if (!itemDetail) {
		return <></>;
	}

	return (
		<Flex direction="column" pt={{ sm: '30px', lg: '30px' }}>
			<Flex w="100%" direction={{ base: 'row', md: 'row' }} justifyContent={'center'} mb="36px">
				<Grid
					display={{ base: 'block', md: 'grid' }}
					pt={{ base: '0px', md: '0px', xl: '0px' }}
					pl={{ base: '0px', md: '0px', xl: '20px' }}
					pr={{ base: '0px', md: '0px', xl: '20px' }}
					gap={{ base: '20px', xl: '20px' }}
					width={{ base: '90%', xl: 'calc(100% - 40px)' }}
					maxWidth={'1280px'}
				>
					{/* 타이틀 */}
					<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }}>
						<GridItem colSpan={2}>
							<Text
								display="flex"
								ms="10px"
								fontSize="xl"
								color={textColorPrimary}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
							>
								{t('item.update_information')}
							</Text>
						</GridItem>
					</SimpleGrid>

					{isItemRegisterPending() ? (
						<>
							<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
								<ButtonField
									buttonName={t('item.register_approve')}
									backgroundColor={'blue'}
									onClick={() => {
										setIsItemRegisterApprove(true);
										setIsShowItemRegisterModal(true);
									}}
								/>
								<ButtonField
									buttonName={t('item.register_reject')}
									backgroundColor={'red'}
									onClick={() => {
										setIsItemRegisterApprove(false);
										setIsShowItemRegisterModal(true);
									}}
								/>
							</SimpleGrid>
						</>
					) : null}

					<HSeparator />

					{/* 노출 설정 */}
					<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
						{t('item.setting_display')}
					</Text>

					<ItemDateSelector
						startDateTime={displayStartDateTime}
						setStartDateTime={setDisplayStartDateTime}
						isShowStartDateTime={isShowPickerDisplayStartDateTime}
						setIsShowStartDateTime={setIsShowPickerDisplayStartDateTime}
						startDateTitle={t('item.exposure_start_date')}
						endDateTime={displayEndDateTime}
						closeAllPopup={closeAllPopup}
						setEndDateTime={setDisplayEndDateTime}
						isShowEndDateTime={isShowPickerDisplayEndDateTime}
						setIsShowEndDateTime={setIsShowPickerDisplayEndDateTime}
						endDateTitle={t('item.exposure_end_date')}
						description={t('item.exposure_date_desc')}
						disabled={!editabled}
						isUpdatedStartDate={
							dateFormat(new Date(itemOrgDetail.displayStartDateTime), DATA_FORMAT_FULL_DATE) !==
							dateFormat(displayStartDateTime, DATA_FORMAT_FULL_DATE)
						}
						isUpdatedEndDate={
							dateFormat(new Date(itemOrgDetail.displayEndDateTime), DATA_FORMAT_FULL_DATE) !==
							dateFormat(displayEndDateTime, DATA_FORMAT_FULL_DATE)
						}
					/>

					<ItemDateSelector
						startDateTime={salesStartDateTime}
						setStartDateTime={setSalesStartDateTime}
						isShowStartDateTime={isShowPickerSalesStartDateTime}
						setIsShowStartDateTime={setIsShowPickerSalesStartDateTime}
						startDateTitle={t('item.sales_start_date')}
						endDateTime={salesEndDateTime}
						closeAllPopup={closeAllPopup}
						setEndDateTime={setSalesEndDateTime}
						isShowEndDateTime={isShowPickerSalesEndDateTime}
						setIsShowEndDateTime={setIsShowPickerSalesEndDateTime}
						endDateTitle={t('item.sales_end_date')}
						description={t('item.sales_date_desc')}
						disabled={!editabled}
						isUpdatedStartDate={
							dateFormat(new Date(itemOrgDetail.saleStartDateTime), DATA_FORMAT_FULL_DATE) !==
							dateFormat(salesStartDateTime, DATA_FORMAT_FULL_DATE)
						}
						isUpdatedEndDate={
							dateFormat(new Date(itemOrgDetail.saleEndDateTime), DATA_FORMAT_FULL_DATE) !==
							dateFormat(salesEndDateTime, DATA_FORMAT_FULL_DATE)
						}
					/>

					<ItemDateSelector
						startDateTime={useAvaiableStartDateTime}
						setStartDateTime={setUseAvaiableStartDateTime}
						isShowStartDateTime={isShowPickerUseAvaiableStartDateTime}
						setIsShowStartDateTime={setIsShowPickerUseAvaiableStartDateTime}
						startDateTitle={t('item.usability_start_date')}
						endDateTime={useAvaiableEndDateTime}
						closeAllPopup={closeAllPopup}
						setEndDateTime={setUseAvaiableEndDateTime}
						isShowEndDateTime={isShowPickerUseAvaiableEndDateTime}
						setIsShowEndDateTime={setIsShowPickerUseAvaiableEndDateTime}
						endDateTitle={t('item.usability_end_date')}
						description={t('item.usability_date_desc')}
						leastStartDate={salesStartDateTime}
						leastStartDateTitle={t('item.sales_start_date')}
						leastEndDate={salesEndDateTime}
						leastEndDateTitle={t('item.sales_end_date')}
						disabled={!editabled}
						isUpdatedStartDate={
							dateFormat(new Date(itemOrgDetail.usableStartDateTime), DATA_FORMAT_FULL_DATE) !==
							dateFormat(useAvaiableStartDateTime, DATA_FORMAT_FULL_DATE)
						}
						isUpdatedEndDate={
							dateFormat(new Date(itemOrgDetail.usableEndDateTime), DATA_FORMAT_FULL_DATE) !==
							dateFormat(useAvaiableEndDateTime, DATA_FORMAT_FULL_DATE)
						}
					/>

					{/* 카테고리 설정 */}
					<HSeparator />
					<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
						{t('item.setting_category')}
					</Text>

					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
						<InputField label={t('store.item')} value={t('store.item_crowdfunding')} disabled={true} />
						<SelectField
							label={t('item.category_type')}
							mt={'0px'}
							value={itemDetail?.category ? itemDetail?.category : itemOrgDetail?.category}
							disabled={!editabled}
							isUpdated={itemDetail?.category !== null && itemDetail?.category !== itemOrgDetail?.category}
							options={Object.keys(ItemCategoryType)}
							onChange={e => {
								setItemDetail(prevState => ({
									...prevState, // 기존 상태를 유지
									category: e.target.value as ItemCategoryType // category 값을 업데이트
								}));
							}}
						/>
					</SimpleGrid>

					{/* 카테고리 설정 */}
					<HSeparator />
					<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
						{t('item.setting_information')}
					</Text>

					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
						<InputField
							label={t('item.name')}
							value={itemDetail?.name ? itemDetail?.name : itemOrgDetail?.name}
							disabled={!editabled}
							extra={t('item.name_desc')}
							isUpdated={itemDetail?.name !== null && itemDetail?.name !== itemOrgDetail?.name}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								setItemDetail(prevState => ({
									...prevState,
									name: e.target.value
								}));
							}}
						/>
						<NumberField
							label={t('item.sales_price')}
							onChange={(s, i) => {
								setItemDetail(prevState => ({
									...prevState,
									itemPriceYen: i
								}));
							}}
							value={itemDetail?.itemPriceYen ? itemDetail?.itemPriceYen : itemOrgDetail?.itemPriceYen}
							min={0}
							disabled={!editabled}
							extra={t('item.sales_price_desc')}
						/>
					</SimpleGrid>

					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'} justifyContent={'center'}>
						<Text
							display="flex"
							ms="10px"
							fontSize="sm"
							color={
								itemDetail?.coverImgUrl !== null && itemOrgDetail?.coverImgUrl !== itemDetail?.coverImgUrl
									? 'red'
									: textColorPrimary
							}
							fontWeight="bold"
							_hover={{ cursor: 'pointer' }}
						>
							{t('item.thumbnail')}
						</Text>
						<DropImageBox
							imgUrls={thumbnailImgUrls}
							setImgUrls={setThumbnailImgUrls}
							imgFiles={thumbnailImgFiles}
							setImgFiles={setThumbnailImgFiles}
							maxFiles={1}
							title={t('store.thumbnail')}
							disabled={!editabled}
							width={'xs'}
						/>
						<Text fontSize="sm" color={textColorSecondary} fontWeight="400" ps={'10px'} whiteSpace={'pre-line'}>
							{t('item.thumbnail_img_desc')}
						</Text>
					</SimpleGrid>

					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
						<NumberField
							label={t('item.sales_quantity')}
							value={itemDetail?.quantity ? itemDetail?.quantity : itemOrgDetail?.quantity}
							disabled={!editabled}
							isUpdated={itemDetail?.quantity !== 0 && itemOrgDetail?.quantity !== itemDetail?.quantity}
							onChange={(s, i) => {
								setItemDetail(prevState => ({
									...prevState,
									quantity: i
								}));
							}}
							extra={t('item.sales_quantity_desc')}
						/>
					</SimpleGrid>

					{/* 상세 설명 */}
					<HSeparator />
					<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
						{t('item.setting_detail')}
					</Text>

					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'} justifyContent={'center'}>
						<Text
							display="flex"
							ms="10px"
							fontSize="sm"
							color={
								itemDetail?.detailFileUrls !== null &&
								itemDetail?.detailFileUrls.length > 0 &&
								itemOrgDetail?.detailFileUrls[0].url !== itemDetail?.detailFileUrls[0].url
									? 'red'
									: textColorPrimary
							}
							fontWeight="bold"
							_hover={{ cursor: 'pointer' }}
						>
							{t('item.detailed_img')}
						</Text>
						<DropImageBox
							imgUrls={detailImgUrls}
							setImgUrls={setDetailImgUrls}
							imgFiles={detailImgFiles}
							setImgFiles={setDetailImgFiles}
							maxFiles={5}
							title={t('item.detailed_img')}
							disabled={!editabled}
							width={'100%'}
						/>
						<Text fontSize="sm" color={textColorSecondary} fontWeight="400" ps={'10px'} whiteSpace={'pre-line'}>
							{t('item.detailed_img_desc')}
						</Text>
					</SimpleGrid>

					{/* 상세 설명 */}
					<HSeparator />
					<Text display="flex" fontSize="md" color={textColorBrand} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
						{t('item.description_how_to_use')}
					</Text>

					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
						<TextAreaField
							label={t('item.how_to_use') + ' *'}
							minH={'250px'}
							value={itemDetail?.usageInstruction ? itemDetail?.usageInstruction : itemOrgDetail?.usageInstruction}
							mb={'10px'}
							isUpdated={
								itemDetail?.usageInstruction !== null &&
								itemOrgDetail?.usageInstruction !== itemDetail?.usageInstruction
							}
							onChange={e => {
								setItemDetail(prevState => ({
									...prevState,
									usageInstruction: e.target.value
								}));
							}}
							disabled={!editabled}
							placeholder={t('item.how_to_use_placeholder')}
						/>
					</SimpleGrid>
					<SimpleGrid
						columns={{base: 1, md: 1, xl: 1}}
						gap={'20px'}
						justifyContent={'center'}
					>
						<DynamicInputField
							label={'item.cautions'}
							placeholder={'item.input_cautions_placeholder'}
							originalValues={cautionsOrg}
							values={cautions}
							disabled={!editabled}
							setValues={setCautions}
						/>

					</SimpleGrid>
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap={'10px'}>
						<SelectField
							label={t('item.lesson_type')}
							mt={'0px'}
							disabled={!editabled}
							isUpdated={itemDetail?.online !== null && itemDetail?.online !== itemOrgDetail?.online}
							value={
								itemDetail?.online === null
									? itemOrgDetail?.online
										? ItemLessonType.ONLINE
										: ItemLessonType.OFFLINE
									: itemDetail?.online
									  ? ItemLessonType.ONLINE
									  : ItemLessonType.OFFLINE
							}
							options={Object.keys(ItemLessonType)}
							onChange={e => {
								setItemDetail(prevState => ({
									...prevState,
									online: e.target.value === ItemLessonType.ONLINE
								}));
							}}
						/>
					</SimpleGrid>
				</Grid>
			</Flex>

			{/* 아이템 신청 승인/반려 모달 */}
			<ModalBasic
				isOpen={isShowItemRegisterModal && isItemRegisterApprove}
				size={'xl'}
				onClose={() => {
					setIsShowItemRegisterModal(false);
				}}
				title={t('item.register_approved', { name: itemDetail?.name })}
				desc={
					t('item.register_approved_desc') +
					(itemRewards.length === 0 ? t('item.register_approved_desc_not_rewards') : '')
				}
				firstButton={{
					title: t('common.cancel'),
					onClick: () => {
						setIsShowItemRegisterModal(false);
					}
				}}
				secondaryButton={{
					title: t('provider.approved'),
					onClick: () => {
						setIsShowItemRegisterModal(false);
						itemUpdateApprove();
					}
				}}
			/>

			<Modal
				isOpen={isShowItemRegisterModal && !isItemRegisterApprove}
				onClose={() => {
					setIsShowItemRegisterModal(false);
				}}
				size={'xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('item.store_rejected', { name: itemDetail?.name })}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color={textColorPrimary} fontSize="md" fontWeight="500" ms={'10px'} mb={'10px'}>
							{t('item.register_rejected_desc')}
						</Text>
						<TextAreaField
							label={t('item.register_rejected_reason')}
							onChange={e => {
								setRejectReason(e.target.value);
							}}
							value={rejectReason}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="brand"
							mr={3}
							onClick={() => {
								if (rejectReason === null || rejectReason.length < 10) {
									showToast(t('error.input_admin_comment'));
									return;
								}

								itemUpdateReject();
								setIsShowItemRegisterModal(false);
							}}
						>
							{t('provider.rejected')}
						</Button>
						<Button
							onClick={() => {
								setRejectReason('');
								setIsShowItemRegisterModal(false);
							}}
						>
							{t('common.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
