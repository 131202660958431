// Chakra imports
import { Box, Portal, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";
import PrivateRoute from "../privateRoute";
import { useLogin } from "../../providers/login-provider";
import { useAuth } from "../../providers/auth-provider";
import { TYPE_ROUTE_SCREEN } from "../../helper/types";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
	const { ...rest } = props;

	const {  availableAdminMenus } = useAuth();

	// states and functions
	const [fixed] = useState(false);
	const [toggleSidebar, setToggleSidebar] = useState(false);
	const [mini, setMini] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [rootPath, setRootPath] = useState('');
	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes: RoutesType[]): string => {
		const currentPath = window.location.pathname;

		for (let route of routes) {
			// 중첩된 라우트 처리
			if (route.collapse && route.items) {
				const activeRoute = getActiveRoute(route.items);
				if (activeRoute !== "Main Dashboard") {
					return activeRoute;
				}
			}
			// 현재 경로와 비교
			else if (currentPath === route.layout + route.path) {
				return route.name;
			}
		}

		// 기본값 반환
		return "Main Dashboard";
	};
	const getActiveNavbar = (routes: RoutesType[]): boolean => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondary;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes: RoutesType[]): any => {
		return routes.map((route: RoutesType, key: number) => {
			if (route.collapse) {
				return getRoutes(route.items);
			} else {
				// console.log('=====> ' + JSON.stringify(availableAdminMenus) + ' ' + JSON.stringify(route.authType))

				if (availableAdminMenus.includes(route.authType.toUpperCase())) {

					if (route.screenType === TYPE_ROUTE_SCREEN.MAIN || route.screenType === TYPE_ROUTE_SCREEN.SUB) {
						return <Route path={`${route.path}`} element={route.component} key={key} />;
					}
				} else {
					return null;
				}
			}
		});
	};

	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();
	const bg = useColorModeValue('background.100', 'background.900');
	return (
		<Box bg={bg} h="100vh" w="100vw">
			<SidebarContext.Provider
				value={{
					toggleSidebar,
					setToggleSidebar
				}}
			>
				<Sidebar hovered={hovered} setHovered={setHovered} mini={mini} routes={routes} display="none" {...rest} />
				<Box
					float="right"
					minHeight="100vh"
					height="100%"
					overflow="auto"
					position="relative"
					maxHeight="100%"
					w={
						mini === false
							? { base: '100%', xl: 'calc( 100% - 290px )' }
							: mini === true && hovered === true
							  ? { base: '100%', xl: 'calc( 100% - 290px )' }
							  : { base: '100%', xl: 'calc( 100% - 120px )' }
					}
					maxWidth={
						mini === false
							? { base: '100%', xl: 'calc( 100% - 290px )' }
							: mini === true && hovered === true
							  ? { base: '100%', xl: 'calc( 100% - 290px )' }
							  : { base: '100%', xl: 'calc( 100% - 120px )' }
					}
					transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
					transitionDuration=".2s, .2s, .35s"
					transitionProperty="top, bottom, width"
					transitionTimingFunction="linear, linear, ease"
				>
					<Portal>
						<Box>
							<Navbar
								hovered={hovered}
								setMini={setMini}
								mini={mini}
								onOpen={onOpen}
								logoText={'Horizon UI Dashboard PRO'}
								brandText={getActiveRoute(routes)}
								secondary={getActiveNavbar(routes)}
								theme={props.theme}
								setTheme={props.setTheme}
								fixed={fixed}
								{...rest}
							/>
						</Box>
					</Portal>
					<Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" pt="50px">
						<Routes>
							<Route element={<PrivateRoute />}>
								<Route path="/" element={<Navigate to={'/admin/contents/live'} replace />} />
								{getRoutes(routes)}
							</Route>
						</Routes>
					</Box>
					<Box>
						<Footer />
					</Box>
				</Box>
			</SidebarContext.Provider>
		</Box>
	);
}
