import { BaseApi } from './baseAPI';
import { PagingRequest } from './commonAPI';
import { EmailTemplateType, OSType, SortCondition } from '../types/enums';
import {
	URL_DELETE_FORBIDDEN_WORD,
	URL_GET_APP_META_DATA,
	URL_GET_APP_META_DATA_DETAIL,
	URL_GET_FORBIDDEN_WORD_LIST,
	URL_GET_IOS_VERSION_CONFIG,
	URL_MAIL_TEMPLATE,
	URL_MAIL_TEMPLATE_DETAIL,
	URL_POST_APP_META_DATA,
	URL_POST_FORBIDDEN_WORD,
	URL_PUT_APP_META_DATA_DETAIL,
	URL_PUT_FORBIDDEN_WORD
} from './URLS';
import { BilingualContentDto } from './noticeAPI';

/**
 * Common Controller
 */
export class MetaDataApi extends BaseApi {
	/**
	 * 금칙어 목록 불러오기
	 * @param request
	 */
	getForbiddenWord = (request: ForbiddenWordRequest) => {
		return this.axios
			.get<ForbiddenWordResponse>(URL_GET_FORBIDDEN_WORD_LIST, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	/**
	 * 금칙어 추가하기
	 * @param word
	 */
	postForbiddenWord = (word: string) => {
		return this.axios
			.post(URL_POST_FORBIDDEN_WORD, {
				word: word
			})
			.then(response => response)
			.catch(error => error.response);
	};

	/**
	 * 금칙어 수정하기
	 * @param word
	 */
	putForbiddenWord = (forbiddenWordId: string, word: string) => {
		return this.axios
			.put(URL_PUT_FORBIDDEN_WORD.replace('{forbiddenWordId}', forbiddenWordId), {
				params: {
					word: word
				}
			})
			.then(response => response)
			.catch(error => error.response);
	};

	/**
	 * 금칙어 삭제하기
	 * @param request
	 */
	deleteForbiddenWord = (forbiddenWordId: string) => {
		return this.axios
			.delete(URL_DELETE_FORBIDDEN_WORD.replace('{forbiddenWordId}', forbiddenWordId))
			.then(response => response)
			.catch(error => error.response);
	};

	getiOSAppVersion = () => {
		return this.axios
			.get<iOSAppVersionResponse>(URL_GET_IOS_VERSION_CONFIG)
			.then(response => response)
			.catch(error => error.response);
	};

	putiOSAppVersion = (newVersion: string, reviewVersion: string, minVersion: string) => {
		return this.axios
			.put(URL_GET_IOS_VERSION_CONFIG, {
				iosNewVersion: newVersion,
				iosReviewVersion: reviewVersion,
				iosMinVersion: minVersion
			})
			.then(response => response)
			.catch(error => error.response);
	};

	getAppMetaList = (request: PagingRequest) => {
		return this.axios
			.get<AppMetaListResponse>(URL_GET_APP_META_DATA, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	postAppMeta = (osType: OSType, appVersion: string, isUnderReview: boolean) => {
		return this.axios
			.post(URL_POST_APP_META_DATA, {
				osType: osType,
				appVersion: appVersion,
				isUnderReview: isUnderReview
			})
			.then(response => response)
			.catch(error => error.response);
	};

	getAppMetaDetail = (appMetaId: string) => {
		return this.axios
			.get<AppMetaDetailResponse>(URL_GET_APP_META_DATA_DETAIL.replace('{appMetaId}', appMetaId))
			.then(response => response)
			.catch(error => error.response);
	};

	putAppMeta = (appMetaId: string, osType: OSType, appVersion: string, isUnderReview: boolean) => {
		return this.axios
			.put(URL_PUT_APP_META_DATA_DETAIL.replace('{appMetaId}', appMetaId), {
				osType: osType,
				appVersion: appVersion,
				isUnderReview: isUnderReview
			})
			.then(response => response)
			.catch(error => error.response);
	};

	getEmailTemplateList = (request: PagingRequest) => {
		return this.axios
			.get<EmailTemplateListResponse>(URL_MAIL_TEMPLATE, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getEmailTemplateDetail = (emailTemplateId: string) => {
		return this.axios
			.get<EmailTemplateDetailResponse>(URL_MAIL_TEMPLATE_DETAIL.replace('{masterMailTemplateId}', emailTemplateId))
			.then(response => response)
			.catch(error => error.response);
	};

	putEmailTemplateDetail = (emailTemplateId: string, request: EmailTemplateRequest) => {
		return this.axios
			.put<EmailTemplateDetailResponse>(
				URL_MAIL_TEMPLATE_DETAIL.replace('{masterMailTemplateId}', emailTemplateId),
				request
			)
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 * ====================================================
 * ====================================================
 * Request
 */
export type ForbiddenWordRequest = PagingRequest & {
	/**
	 *  string
	 *  (query)
	 *  정렬 조건
	 */
	sortCondition?: SortCondition;

	/**
	 * string
	 *  (query)
	 *  검색어
	 */
	keyword?: string;
};

export type EmailTemplateRequest = {
	// 메일 템플릿 타입
	templateType: EmailTemplateType;

	// 제목
	title: BilingualContentDto;

	body: BilingualContentDto;
};

/**
 * ====================================================
 * ====================================================
 * Response
 */
export type ForbiddenWordResponse = {
	forbiddenWordTrain: ForbiddenWordTrain[];
	count: number;
};

export type iOSAppVersionResponse = {
	iosVersionConfig: AppVersionTrain;
};

export type AppMetaListResponse = {
	appMetaTrain: AppMetaTrain[];
	count: number;
};

export type AppMetaDetailResponse = {
	appMetaDetail: AppMetaTrain;
};

export type EmailTemplateListResponse = {
	mailTemplateTrain: EmailTempleteTrain[];
	count: number;
};

export type EmailTemplateDetailResponse = {
	mailTemplateDetail: EmailTempleteTrain;
};

export type EmailTempleteTrain = {
	// 메일 템플릿 아이디
	masterMailTemplateId: number;

	/**
	 * 메일 템플릿 타입
	 *
	 *  Enum:
	 *    [ CROWD_FUNDING_PROVIDER_EMAIL_AUTH, CROWD_FUNDING_PROVIDER_REGISTER_REJECTED, CROWD_FUNDING_PROVIDER_WITHDRAW_APPROVED, CROWD_FUNDING_ITEM_INVISIBLE, CROWD_FUNDING_ITEM_ON_SALE, CROWD_FUNDING_ITEM_STOP_SALE, CROWD_FUNDING_PROVIDER_UPDATE_BUSINESS_STRUCTURE ]
	 */
	templateType: EmailTemplateType;

	// 제목
	title: BilingualContentDto;

	// 내용
	body: BilingualContentDto;
};

export type ForbiddenWordTrain = {
	/**
	 * 금칙어 아이디
	 */
	forbiddenWordId: number;

	/**
	 * 금칙어
	 */
	word: string;

	/**
	 * 생성일
	 */
	createDateTime: string;
};

export type AppVersionTrain = {
	newVersion: string;

	reviewVersion: string;

	minVersion: string;
};

export type AppMetaTrain = {
	/**
	 *  시퀀셜 Id
	 */
	appMetaId: number;

	/**
	 * OS
	 * Enum:
	 *    [ iOS, aOS ]
	 */
	osType: OSType;

	/**
	 * 앱 버전
	 */
	appVersion: string;

	/**
	 * 리뷰중 여부
	 */
	isUnderReview: boolean;
};
