// Chakra imports
import {
	Flex,
	FormLabel,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import * as React from 'react';
import { useThemeColors } from "../../providers/theme-provider";
// Custom components

export default function Default(props: {
	id?: string;
	label?: string;
	extra?: string;
	placeholder?: string;
	disabled?: boolean;
	isUpdated?: boolean;
	[x: string]: any;
	onChange?: (valueAsString: string, valueAsNumber: number) => void;
}) {
	const { id, label, extra, placeholder, type, mb, disabled = false, isUpdated, ...rest } = props;
	const {textColorSecondary, textColorPrimary} = useThemeColors();

	return (
		<Flex direction="column" mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				htmlFor={id}
				fontSize="sm"
				color={isUpdated ? 'red' : textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
				{/*<Text fontSize="sm" fontWeight="400" ms="2px">*/}
				{/*	{extra}*/}
				{/*</Text>*/}
			</FormLabel>
			<NumberInput {...rest} onChange={props?.onChange} isDisabled={disabled}>
				<NumberInputField />
				<NumberInputStepper>
					<NumberIncrementStepper />
					<NumberDecrementStepper />
				</NumberInputStepper>
			</NumberInput>

			{extra && (
				<Text fontSize="sm" color={textColorSecondary} fontWeight="400" mt={'10px'} pr={'10px'} pl={'10px'}
							whiteSpace={'pre-line'}>
					{extra}
				</Text>
			)}
		</Flex>
	);
}
