// Chakra imports
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchTable from '../../../../components/table/SearchTable';
import { TableRowType } from '../../../../types/enums';
import { TableBodyText } from '../../../../components/table/TableComponents';
import { useNavigate } from 'react-router-dom';
import { EmailTempleteTrain } from '../../../../apis/metaDataAPI';
import { EmailTemplateTypeString } from '../../../../helper/types';
import EmailDetail from './detail';

// 금지어 목록
export default function EmailTemplateList() {
	const { metaDataApi } = useApi();

	const navigate = useNavigate();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton, textColorSecondary } = useThemeColors();

	// 목록
	const [list, setList] = useState<EmailTempleteTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const [isShowDetailModal, setIsDetailModal] = useState<boolean>(false);
	const [selectEmailTemplateId, setSelectEmailTemplateId] = useState<string>('');

	useEffect(() => {
		requestList(offset);
	}, []);

	// 목록 요청
	const requestList = async (offset: number) => {
		setOffset(offset);
		metaDataApi
			.getEmailTemplateList({
				limit: limit,
				offset: offset * limit
			})
			.then(response => {
				if (response.mailTemplateTrain) {
					setList(response.mailTemplateTrain);
				}

				if (response.count) {
					setTotalCount(response.count);
				} else {
					setTotalCount(0);
				}
			})
			.catch(() => {
				setList([]);
				setTotalCount(0);
			});
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<EmailTempleteTrain>[] = [
		{
			row: 'masterMailTemplateId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'templateType',
			type: TableRowType.ENUM,
			enumToText: EmailTemplateTypeString
		},
		{
			row: 'title',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={rowData.title.contentEn}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		setSelectEmailTemplateId(String(rowData.masterMailTemplateId));
		setIsDetailModal(true);
		// navigate('/admin/crowdfunding/provider/detail/' + );
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		requestList(0);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<SearchTable request={requestList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowDetailModal}
				onClose={() => {
					setIsDetailModal(false);
				}}
				size={'4xl'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<ModalCloseButton />
						<EmailDetail
							emailTemplateId={selectEmailTemplateId}
							requestChangeParams={() => {
								requestList(offset);
								setIsDetailModal(false);
							}}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
