/**
 * 유저 검색 타입
 */
export enum MemberSearchType {
	ALL = 'ALL',
	ACTIVITY_NAME = 'ACTIVITY_NAME',
	PID = 'PID',
	REGISTER_DATE = 'REGISTER_DATE',
	MOBILE = 'MOBILE',
	EMAIL = 'EMAIL'
}

/**
 * 아이돌 검색 타입
 */
export enum IdolApplySearchType {
	ALL = 'ALL',
	ACTIVITY_NAME = 'ACTIVITY_NAME',
	PID = 'PID',
	REGISTER_DATE = 'REGISTER_DATE',
	MOBILE = 'MOBILE',
	EMAIL = 'EMAIL'
}

export enum IdolPreContractStatusType {}

/**
 * 유저 타입
 */
export enum MemberType {
	IDOL = 'IDOL',

	PRODUCER = 'PRODUCER'
}

/**
 * 유저 계정 상태
 * 일반, 블랙리스트, 탈퇴 회원
 */
export enum MemberStatusType {
	NORMAL = 'NORMAL',
	BLACK_LISTED = 'BLACK_LISTED',
	WITHDRAW_SUBMITTED = 'WITHDRAW_SUBMITTED'
}

/**
 * 회원 로그인 타입
 */
export enum MemberSocialLoginType {
	APPLE = 'APPLE',
	LINE = 'LINE',
	GOOGLE = 'GOOGLE',
	TWITTER = 'TWITTER'
}

/**
 * 아이돌승인상태
 */
export enum IdolApplyStatus {
	UNDER_REVIEW = 'UNDER_REVIEW',
	REJECTED = 'REJECTED',
	APPROVED = 'APPROVED'
}

/**
 * 아이돌승인상태
 */
// export enum IdolApplyStatus {
// 	UNDER_REVIEW = 'UNDER_REVIEW',
// 	REJECTED = 'REJECTED',
// 	APPROVED = 'APPROVED'
// }
/**
 * 라이브 스트리밍 검색 조건
 */
export enum LiveStreamingSearchType {
	ALL = 'ALL',
	IDOL_STAGE_NAME = 'IDOL_STAGE_NAME',
	IDOL_NAME = 'IDOL_NAME',
	LIVE_STREAMING_TITLE = 'LIVE_STREAMING_TITLE',
	LIVE_STREAMING_TAG = 'LIVE_STREAMING_TAG'
}

/**
 * 라이브 스트리밍 방속 상태
 * 전체 검색시 null
 */
export enum LiveStreamingBroadcastStatusType {
	SYSTEM_PROBLEM_END = 'SYSTEM_PROBLEM_END',
	ADMIN_FORCE_END = 'ADMIN_FORCE_END',
	ADMIN_FORCE_END_WAIT = 'ADMIN_FORCE_END_WAIT',
	EXPLODE_END = 'EXPLODE_END',
	EXPLODE_END_WAIT = 'EXPLODE_END_WAIT',
	NORMAL_END = 'NORMAL_END',
	NORMAL_END_WAIT = 'NORMAL_END_WAIT',
	LIVE = 'LIVE',
	READY = 'READY',
	CREATED = 'CREATED'
}

/**
 * 영상 종류
 */
export enum VideoContentType {
	LIVE_STREAMING = 'LIVE_STREAMING',
	VOD = 'VOD'
}

/**
 * 기간 종료
 */
export enum PeroidSearchCondition {
	ALL = 'ALL',
	WITHIN_7_DAY = 'WITHIN_7_DAY',
	WITHIN_14_DAY = 'WITHIN_14_DAY',
	WITHIN_30_DAY = 'WITHIN_30_DAY',
	WITHIN_90_DAY = ' WITHIN_90_DAY'
}

/**
 * 프로젝트 리그 종류
 */
export enum ProjectLeagueType {
	OFFICIAL = 'OFFICIAL',
	OFF_SEASON = 'OFF_SEASON'
}

/**
 * 프로젝트 상태
 */
export enum ProjectStatus {
	RECRUIT_SOON = 'RECRUIT_SOON',
	RECRUITING = 'RECRUITING',
	OPEN_SOON = 'OPEN_SOON',
	OPERATING = 'OPERATING',
	CLOSED = 'CLOSED'
}

/**
 * 프로젝트 지원자 상태
 */
export enum ProjectAppliedStatus {
	/** 지원가능 */
	AVAILABLE = 'AVAILABLE',
	/** 지원 완료 @ */
	APPLIED = 'APPLIED',
	/** 결과 대기 */
	WAITING = 'WAITING',
	/** 취소 @ */
	WITHDRAW = 'WITHDRAW',
	/** 합격 @ */
	QUALIFIED = 'QUALIFIED',
	/** 불합격 @ */
	UNQUALIFIED = 'UNQUALIFIED',
	/** 진행중: 합격+클리어x */
	IN_PROGRESS = 'IN_PROGRESS',
	/** 결과: 합격+클리어o @ */
	CLEARED = 'CLEARED',
	/** 결과: 합격+클리어x */
	CLOSED = 'CLOSED'
}

export enum ProjectApplicantResultStatus {
	QUALIFIED = 'QUALIFIED',
	UNQUALIFIED = 'UNQUALIFIED'
}

/**
 * 프로젝트 목록 정렬 순서
 */
export enum ListDirection {
	ASC = 'ASC',
	DESC = 'DESC'
}

/**
 * 미션 완료 조건 타입
 */
export enum MissionActionType {
	LIVE = 'LIVE',
	LIVE_REACTION = 'LIVE_REACTION',
	LIVE_CHAT_POST = 'LIVE_CHAT_POST',
	LIVE_CHAT_GET = 'LIVE_CHAT_GET',
	LIVE_DONATION = 'LIVE_DONATION',
	LIVE_VOTE = 'LIVE_VOTE',
	OTHER_LIVE_WATCH = 'OTHER_LIVE_WATCH',
	OTHER_LIVE_DONATION = 'OTHER_LIVE_DONATION',
	OTHER_LIVE_CHAT = 'OTHER_LIVE_CHAT',
	OTHER_VOD_COMMENT = 'OTHER_VOD_COMMENT'
}

/**
 * 미션 완료 조건 갯수 타입
 */
export enum MissionActionGoalType {
	COUNT = 'COUNT',
	EVALUATION = 'EVALUATION',
	TOTAL_DONATION = 'TOTAL_DONATION',
	MIN_OVER_DONATION = 'MIN_OVER_DONATION',
	STRING_LENGTH = 'STRING_LENGTH',
	RUNNING_TIME = 'RUNNING_TIME',
	LIMIT_TIME = 'LIMIT_TIME'
}

/**
 * 보상 타입
 */
export enum RewardType {
	NONE = 'NONE',
	SKILL = 'SKILL',
	CHARACTER = 'CHARACTER'
}

export enum RewardPointType {
	P90 = '90',
	P80 = '80',
	P70 = '70',
	P60 = '60',
	P50 = '50',
	P40 = '40',
	P30 = '30',
	P20 = '20',
	P10 = '10',
	P0 = '0'
}

/**
 * 매력 타입
 */
export enum CharacterType {
	CUTE = 'CUTE',
	COOL = 'COOL',
	FRESH = 'FRESH',
	SEXY = 'SEXY',
	NICE = 'NICE'
}

/**
 * 스킬 타입
 */
export enum SkillType {
	MUSIC = 'MUSIC',
	DANCE = 'DANCE',
	PERFORMANCE = 'PERFORMANCE',
	PHYSICAL = 'PHYSICAL',
	COMMUNICATION = 'COMMUNICATION'
}

/**
 * 통화 타입
 */
export enum CurrencyType {
	NONE = 'NONE',
	HYPY_POINT = 'HYPY_POINT',
	HYPY_GOLD = 'HYPY_GOLD'
}

/**
 * 상품 타입
 */
export enum ProductType {
	NONE = 'NONE',
	CONSUMABLE = 'CONSUMABLE',
	NON_CONSUMABLE = 'NON_CONSUMABLE'
}

/**
 * 상품 이벤트 타입
 */
export enum ProductEventType {
	NONE = 'NONE',
	NEW_USER = 'NEW_USER',
	MANUAL = 'MANUAL'
}

/**
 * 정렬 타입
 */
export enum SortCondition {
	NEWEST_DEST = 'NEWEST_DEST',
	WORD_ASC = 'WORD_ASC',
	ASC = 'ASC',
	DESC = 'DESC'
}

/**
 * 테이블 Row 타입
 */
export enum TableRowType {
	TEXT = 'TEXT',
	IMAGE = 'IMAGE',
	DATETIME = 'DATETIME',
	BOOLEAN = 'BOOLEAN',
	BUTTON = 'BUTTON',
	ACTION = 'ACTION',
	ENUM = 'ENUM'
}

export enum ReportContentsType {
	ALL = 'ALL',
	PROFILE_IDOL = 'PROFILE_IDOL',
	PROFILE_PRODUCER = 'PROFILE_PRODUCER',
	LIVE_STREAMING = 'LIVE_STREAMING',
	VOD = 'VOD',
	VOD_COMMENT = 'VOD_COMMENT',
	LIVE_STREAMING_CHAT = 'LIVE_STREAMING_CHAT', // 검색용
	LIVE_CHATTING = 'LIVE_CHATTING' // 목록 응답용
}

export enum ReportStatusType {
	NONE = 'NONE',
	// ("어드민 처리 진행중")
	PENDING = 'PENDING',
	// "어드민에서 유효 처리함")
	VALID = 'VALID',
	// ("어드민에서 무효 처리함")
	INVALID = 'INVALID',
	// ("철회 처리 함")
	REVERTED = 'REVERTED'
}

export enum ReportStatusProcessingType {
	// "어드민에서 유효 처리함")
	VALID = 'VALID',
	// ("어드민에서 무효 처리함")
	INVALID = 'INVALID'
}

export enum ReportStatusResultType {
	// ("철회 처리 함")
	REVERTED = 'REVERTED'
}

/**
 * 공지 타입
 */
export enum NoticeType {
	SYSTEM = 'SYSTEM',
	ADVERTISEMENT = 'ADVERTISEMENT'
}

/**
 * 공지 노출 위치 타입
 */
export enum NoticePlacementType {
	HOME = 'HOME',
	LOOK_AROUND = 'LOOK_AROUND'
}

export enum EventRewardType {
	NONE = 'NONE',
	POINT = 'POINT',
	ITEM = 'ITEM'
}

export enum EventRewardPayType {
	NONE = 'NONE',
	SEQUENCE = 'SEQUENCE',
	TERM = 'TERM'
}

export enum OSType {
	iOS = 'iOS',
	aOS = 'aOS'
}

export enum SanctionType {
	CHAT_COMMENT_WARNING = 'CHAT_COMMENT_WARNING',
	SYNTHESIS_WARNING = 'SYNTHESIS_WARNING',
	CHAT_COMMENT_SUSPENSIONS = 'CHAT_COMMENT_SUSPENSIONS',
	ACTIVITY_SUSPENSIONS = 'ACTIVITY_SUSPENSIONS',
	USING_SUSPENSIONS = 'USING_SUSPENSIONS'
}

export enum PayoutStatusType {
	ALL = 'ALL',
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED'
}

export enum PayoutStatusConfirmType {
	VALID = 'VALID',
	INVALID = 'INVALID'
}

/**
 * 사업자 유형 타입
 */
export enum BusinessStructureType {
	// 개인
	SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',

	// 법인
	CORPORATION = 'CORPORATION'
}

/**
 * 판매점 상태
 */
export enum ProviderStatusType {
	//  가입 대기
	REGISTER_PENDING = 'REGISTER_PENDING',

	// 가입 거절=
	REGISTER_REJECTED = 'REGISTER_REJECTED',

	// 가입 승인
	REGISTER_APPROVED = 'REGISTER_APPROVED',

	// 탈퇴 승인
	WITHDRAW_SUBMITTED = 'WITHDRAW_SUBMITTED',

	// 탈퇴 거절
	WITHDRAW_REJECTED = 'WITHDRAW_REJECTED',

	// 탈퇴 신청
	WITHDRAW_APPROVED = 'WITHDRAW_APPROVED'
}

/**
 * 브랜드 샵 상태
 */
export enum BrandShopStatusType {
	/** 신청 대기 : 판매점에서 브랜드를 신청하고 '반려' 혹은 '승인' 여부를 기다리고 있는 상태 */
	REGISTER = 'REGISTER',
	/** 철회 : 판매점에서 브랜드 신청 후, 대기 상태에서 브랜드 신청을 취소한 상태를 의미함 */
	WITHDRAW = 'WITHDRAW',
	/** 반려 : 브랜드 신청이 반려된 상태 */
	REJECTED = 'REJECTED',
	/** 승인 : 브랜드 신청이 승인된 상태 */
	APPROVED = 'APPROVED'
}

/**
 * 아이템 판매 상태
 */
export enum ItemSalesStatusType {
	// 판매 전
	OPEN_SOON = 'OPEN_SOON',

	// 판매 중
	ON_SALE = 'ON_SALE',

	// 수량 없음
	SOLD_OUT = 'SOLD_OUT',

	// 판매 종료
	END_SALE = 'END_SALE'
}

/**
 * 아이템 등록/수정 상태
 */
export enum ItemRegisterStatusType {
	// 등록됨
	REGISTERED = 'REGISTERED',

	// 승인됨
	APPROVED = 'APPROVED',

	// 거절됨
	REJECTED = 'REJECTED'
}

/**
 * 아이템 카테고리
 */
export enum ItemCategoryType {
	// 보컬
	VOCAL = 'VOCAL',

	// 랩
	RAP = 'RAP',

	// 댄스
	DANCE = 'DANCE',

	// 스타일
	STYLE = 'STYLE',

	// PR
	PR = 'PR',

	// 기타
	ETC = 'ETC'
}

/**
 * 미디어 타입
 */
export enum MediaType {
	VIDEO = 'VIDEO',
	AUDIO = 'AUDIO',
	IMAGE = 'IMAGE'
}

export enum ProviderSearchType {
	NONE = 'NONE',
	EMAIL = 'EMAIL',
	NAME = 'NAME'
}

/**
 * 아이템 레슨 방식
 */
export enum ItemLessonType {
	NONE = 'NONE',
	ONLINE = 'ONLINE',
	OFFLINE = 'OFFLINE'
}

/**
 * 크라펀 아이템 아이돌 판매 상태
 */
export enum CrowdFundingItemParticipantsStatusType {
	// 참여
	PARTICIPATION = 'PARTICIPATION',
	// 취소
	CANCEL = 'CANCEL',
	// 획득
	OBTAINMENT = 'OBTAINMENT',
	// 교환
	EXCHANGED = 'EXCHANGED',
	// 지원
	APPLY = 'APPLY',
	// 사용
	USED = 'USED',
	// 종료
	FINISHED = 'FINISHED',
	// 강제 취소
	INTERRUPTION = 'INTERRUPTION'
}

/**
 * 크라펀 아이템 아이돌 사용 상태
 */
export enum CrowdFundingItemParticipantsItemStatusType {
	// 사용 가능함
	USABLE = 'USABLE',
	// 사용 완료
	USE_DONE = 'USE_DONE',
	// 사용 불가
	UNUSABLE = 'UNUSABLE',
	// 환불 대기
	REFUND_REVIEW = 'REFUND_REVIEW',
	// 환불 처리
	REFUND_EXECUTED = 'REFUND_EXECUTED'
}

//  	메일 템플릿 타입
export enum EmailTemplateType {
	CROWD_FUNDING_PROVIDER_EMAIL_AUTH = 'CROWD_FUNDING_PROVIDER_EMAIL_AUTH',
	CROWD_FUNDING_PROVIDER_REGISTER_REJECTED = 'CROWD_FUNDING_PROVIDER_REGISTER_REJECTED',
	CROWD_FUNDING_PROVIDER_WITHDRAW_APPROVED = 'CROWD_FUNDING_PROVIDER_WITHDRAW_APPROVED',
	CROWD_FUNDING_ITEM_INVISIBLE = 'CROWD_FUNDING_ITEM_INVISIBLE',
	CROWD_FUNDING_ITEM_ON_SALE = 'CROWD_FUNDING_ITEM_ON_SALE',
	CROWD_FUNDING_ITEM_STOP_SALE = 'CROWD_FUNDING_ITEM_STOP_SALE',
	CROWD_FUNDING_PROVIDER_UPDATE_BUSINESS_STRUCTURE = 'CROWD_FUNDING_PROVIDER_UPDATE_BUSINESS_STRUCTURE'
}
