// Chakra imports
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import JoditEditor from 'jodit-react';
import { EmailTempleteTrain } from '../../../../apis/metaDataAPI';
import ButtonField from 'components/fields/ButtonField';
import InputField from 'components/fields/InputField';
import Card from 'components/card/Card';

// 금지어 목록
interface EmailData {
	titleJa: string;
	contentJa: string;
	titleKo: string;
	contentKo: string;
	titleEn: string;
	contentEn: string;
}

interface Language {
	ja: 'Ja',
	ko: 'Ko',
	en: 'En'
}


export default function EmailDetail({ emailTemplateId, requestChangeParams }: { emailTemplateId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const { textColorPrimary, brandColor } = useThemeColors();
	const { metaDataApi } = useApi();

	const editor = useRef(null);
	const [detail, setDetail] = useState<EmailTempleteTrain | null>(null);

	const [isEdit, setIsEdit] = useState<{ [key in 'Ja' | 'Ko' | 'En']: boolean }>({
		Ja: false,
		Ko: false,
		En: false,
	});

	const [emailData, setEmailData] = useState<EmailData>({
		titleJa: '',
		contentJa: '',
		titleKo: '',
		contentKo: '',
		titleEn: '',
		contentEn: '',
	});

	useEffect(() => {
		if (emailTemplateId) {
			metaDataApi.getEmailTemplateDetail(emailTemplateId)
				.then(response => {
					setDetail(response.mailTemplateDetail);
					setEmailData({
						titleJa: response.mailTemplateDetail.title.contentJa,
						titleKo: response.mailTemplateDetail.title.contentKo,
						titleEn: response.mailTemplateDetail.title.contentEn,
						contentJa: processBodyContent(response.mailTemplateDetail.title.contentJa, response.mailTemplateDetail.body.contentJa),
						contentKo: processBodyContent(response.mailTemplateDetail.title.contentKo, response.mailTemplateDetail.body.contentKo),
						contentEn: processBodyContent(response.mailTemplateDetail.title.contentEn, response.mailTemplateDetail.body.contentEn),
					});
				})
				.catch(() => {});
		}
	}, [emailTemplateId]);

	const processBodyContent = (header: string, body: string): string => {
		const currentYear = new Date().getFullYear();
		if (body.trim().includes('<meta')) {
			return body;
		}
		return `
<html lang="en">
<head>
	<meta charset="UTF-8">
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<title>${header}</title>
</head>
<body style="max-width: 100%; margin: 0; padding: 0; font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
	<div style="max-width: 600px; margin: 20px auto; padding: 20px; border: 1px solid #ddd; border-radius: 8px; box-sizing: border-box;">
		<div style="text-align: center; font-size: 1.5em; font-weight: bold; border-bottom: 1px solid #ddd; padding-bottom: 10px; margin-bottom: 20px;">
			${header}
		</div>
		<div style="font-size: 1em; color: #555;">
			${body}
		</div>
		<div style="text-align: center; font-size: 0.9em; color: #777; border-top: 1px solid #ddd; padding-top: 10px; margin-top: 20px;">
			&copy; ${currentYear} Hypy. All rights reserved.
		</div>
	</div>
</body>
</html>`;
	};

	const updateEmailData = (key: keyof EmailData, value: string) => {
		setEmailData((prev) => ({ ...prev, [key]: value }));
	};

	const updateTemplate = () => {
		if (!detail) return;
		const updatedDetail = {
			...detail,
			title: {
				contentJa: emailData.titleJa,
				contentKo: emailData.titleKo,
				contentEn: emailData.titleEn,
			},
			body: {
				contentJa: emailData.contentJa,
				contentKo: emailData.contentKo,
				contentEn: emailData.contentEn,
			},
		};

		metaDataApi.putEmailTemplateDetail(String(detail.masterMailTemplateId), updatedDetail)
			.catch(() => {});
	};

	const renderEditor = (lang: 'Ja' | 'Ko' | 'En') => (
		<Card>
			<InputField
				label={t('item.email_title')}
				value={emailData[`title${lang}` as keyof EmailData]}
				onChange={(e) => updateEmailData(`title${lang}` as keyof EmailData, e.target.value)}
			/>
			<JoditEditor
				ref={editor}
				value={emailData[`content${lang}` as keyof EmailData]}
				onBlur={(content) => updateEmailData(`content${lang}` as keyof EmailData, content)}
				onChange={newContent => {
					updateEmailData(`content${lang}` as keyof EmailData, newContent)
				}}
			/>
			<ButtonField
				buttonName={t('common.save')}
				onClick={updateTemplate}
			/>
		</Card>
	);

	const getLangText = (lang: string) => {
		if (lang === 'Ja') {
			return t('common.japanese');
		} else if (lang === 'Ko') {
			return t('common.korean');
		} else if (lang === 'En') {
			return t('common.english');
		}
	}


	return (
		<Flex direction="column" pt="75px">
			<Accordion allowToggle mb="30px">
				{['Ja', 'Ko', 'En'].map((lang) => (
					<AccordionItem key={lang}>
						<h2>
							<AccordionButton>
								<Box flex="1" textAlign="left">{getLangText(lang) + ' ' + emailData[`title${lang}` as keyof EmailData] + ''}</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb="4">
							<Box
								dangerouslySetInnerHTML={{ __html: emailData[`content${lang}` as keyof EmailData] }}
								p="4"
								border="1px solid #ddd"
								borderRadius="md"
								bg="white"
							/>
							<ButtonField
								buttonName={!isEdit[lang as 'Ja' | 'Ko' | 'En'] ? t('common.edit') : t('common.close')}
								onClick={() => setIsEdit({ ...isEdit, [lang as 'Ja' | 'Ko' | 'En']: !isEdit[lang as 'Ja' | 'Ko' | 'En'] })}
							/>
							{isEdit[lang as 'Ja' | 'Ko' | 'En'] && renderEditor(lang as 'Ja' | 'Ko' | 'En')}
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>
		</Flex>
	);
}

