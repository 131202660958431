import { createColumnHelper } from '@tanstack/react-table'; // Assuming you are using @tanstack/react-table
import React from 'react';
import { TableRowType } from '../../types/enums';
import {
	TableBodyButton,
	TableBodyDateTime,
	TableBodyImage,
	TableBodySwitch,
	TableBodyText,
	TableHeader
} from './TableComponents';
import { IconType } from 'react-icons';

// 보여줄 목록 타입
export type VisibleColumns<T> = {
	row: keyof T; // T 타입의 키값
	type: TableRowType; // 셀 타입
	title?: string;
	action?: (row: T) => void; // 셀 클릭 이벤트
	extraComponent?: (value: any, rowData: any) => void; // 셀에 포함될 추가 영역
	icon?: IconType;
	enumToText?: (t: any, key: string) => string;
};

// 자동으로 컬럼을 생성해주는 함수
export const createDynamicColumns = <T extends object>(
	data: T[], // 테이블 데이터
	t: any, // 번역 함수
	visibleColumns?: VisibleColumns<T>[], // 보여줄 컬럼들
	onRowClick?: (row: T) => void // 클릭 이벤트 핸들러
) => {
	const columnHelper = createColumnHelper<T>();

	// 데이터의 첫 번째 항목의 키를 사용해 동적으로 컬럼 생성
	const firstRow = data[0];
	if (!firstRow) {
		return []; // 데이터가 없으면 빈 배열 반환
	}

	// visibleColumns가 없을 경우 첫 번째 행의 필드를 사용해 기본 컬럼 생성
	const columns = visibleColumns
		? visibleColumns
		: (Object.keys(firstRow) as Array<keyof T>).map(key => ({
				row: key,
				type: TableRowType.TEXT, // 기본적으로 TEXT 타입으로 처리
				title: '',
				action: null,
				extraComponent: null,
				enumToText: null,
				icon: null
		  }));

	return columns.map(({ row: key, type, title, action, extraComponent, enumToText, icon }) => {
		const columnKey = key as string;

		// @ts-ignore
		return columnHelper.accessor(key, {
			id: columnKey, // id는 문자열로 캐스팅
			header: () => (
				<TableHeader
					title={title ? t(`component.table_header.${title}`) : t(`component.table_header.${columnKey}`) || columnKey}
				/>
			),
			cell: (info: any) => {
				const rowData = info.row.original; // Get the entire row's data here

				switch (type) {
					case TableRowType.TEXT:
						return (
							<TableBodyText
								title={info.getValue()}
								onClick={
									action
										? () => {
												action(rowData);
										  }
										: onRowClick
										  ? () => {
													onRowClick(rowData);
										    }
										  : null
								}
							/>
						);
					case TableRowType.DATETIME:
						return (
							<TableBodyDateTime
								title={info.getValue()}
								onClick={
									action
										? () => {
												action(rowData);
										  }
										: onRowClick
										  ? () => {
													onRowClick(rowData);
										    }
										  : null
								}
							/>
						);

					case TableRowType.BOOLEAN:
						return (
							<TableBodySwitch
								isChecked={info.getValue()}
								onChange={
									action
										? () => {
												action(rowData);
										  }
										: onRowClick
										  ? () => {
													onRowClick(rowData);
										    }
										  : null
								}
							/>
						);

					case TableRowType.ENUM:
						return (
							<TableBodyText
								title={enumToText ? enumToText(t, info.getValue()) : info.getValue()}
								onClick={
									action
										? () => {
												action(rowData);
										  }
										: onRowClick
										  ? () => {
													onRowClick(rowData);
										    }
										  : null
								}
							/>
						);
					case TableRowType.IMAGE:
						return (
							<TableBodyImage
								url={info.getValue()}
								onClick={
									action
										? () => {
												action(rowData);
										  }
										: onRowClick
										  ? () => {
													onRowClick(rowData);
										    }
										  : null
								}
							/>
						);
					case TableRowType.BUTTON:
						return (
							<TableBodyButton
								title={info.getValue()}
								icon={icon}
								onClick={
									action
										? () => {
												action(rowData);
										  }
										: onRowClick
										  ? () => {
													onRowClick(rowData);
										    }
										  : null
								}
							/>
						);
					case TableRowType.ACTION:
						return extraComponent ? extraComponent(info.getValue(), rowData) : null;
					default:
						return (
							<TableBodyText
								title={info.getValue()}
								onClick={
									action
										? () => {
												action(rowData);
										  }
										: onRowClick
										  ? () => {
													onRowClick(rowData);
										    }
										  : null
								}
							/>
						);
				}
			}
		});
	});
};
