// Chakra imports
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

// Assets

function Dropzone(props: {
  content: JSX.Element | string;
  onDropAccepted: (files: File[]) => void;
  maxFiles?: number;
  accept?: string | string[];
  [x: string]: any;
}) {
  const { content, onDropAccepted, maxFiles, accept, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onDropAccepted,
    onDropRejected: (files) => console.log('Rejected files:', files),
    maxFiles: maxFiles,
    accept: accept
  });
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      borderTop="1px dashed"
      borderColor={borderColor}
      h={{ base: '300px', xl: '300px' }}
      cursor="pointer"
      {...getRootProps({ className: 'dropzone' })}
      {...rest}
    >
      <input {...getInputProps()} width="240px" height={'240px'} />
      <Button variant="no-effects">{content}</Button>
    </Flex>
  );
}

export default Dropzone;
