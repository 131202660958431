import { BaseApi } from "./baseAPI";
import { PagingRequest } from "./commonAPI";
import {
	CharacterType,
	CurrencyType,
	ListDirection,
	MemberType,
	MissionActionGoalType,
	MissionActionType,
	ProductType,
	ProjectAppliedStatus,
	ProjectLeagueType,
	ProjectStatus,
	RewardType,
	SkillType,
	SortCondition
} from "../types/enums";
import {
	URL_MISSION,
	URL_PROJECT_APPLICANT_DETAIL,
	URL_PROJECT_APPLIED,
	URL_REWARDS,
	URL_STAGE,
	URL_PROJECT,
	URL_PROJECT_ADD_APPLICANT,
	URL_PROJECT_DETAIL,
	URL_PROJECT_APPLICANT_QUALIFIED,
	URL_PROJECT_APPLICANT_UNQUALIFIED,
	URL_REWARDS_DETAIL, URL_STAGE_DETAIL
} from "./URLS";

/**
 * Common Controller
 */
export class ProjectApi extends BaseApi {
	getProjectList = (request: ProjectRequest) => {
		return this.axios
			.get<ProjectsResponse>(URL_PROJECT, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getProjectDetail = (projectId: string) => {
		return this.axios
			.get<ProjectDetailResponse>(URL_PROJECT_DETAIL.replace('{projectId}', projectId), {})
			.then(response => response)
			.catch(error => error.response);
	};

	putProjectDetail = (request: FormData) => {
		return this.axios
			.put<ProjectDetailResponse>(URL_PROJECT, request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	deleteProjectDetail = (projectId: string) => {
		return this.axios
			.delete(URL_PROJECT_DETAIL.replace('{projectId}', projectId), {})
			.then(response => response)
			.catch(error => error.response);
	};

	getStageList = (request: StageRequest) => {
		return this.axios
			.get<StageResponse>(URL_STAGE, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getStageDetail = (stageId: string) => {
		return this.axios
			.get<StageDetailResponse>(URL_STAGE_DETAIL.replace('{stageId}', stageId))
			.then(response => response)
			.catch(error => error.response);
	};


	putStageDetail = (request: FormData) => {
		return this.axios
			.put(URL_STAGE, request, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	getMissionList = (request: MissionRequest) => {
		return this.axios
			.get<MissionResponse>(URL_MISSION, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};


	putMissionDetail = (request: MissionTrain) => {
		return this.axios
			.put<MissionResponse>(URL_MISSION, request)
			.then(response => response)
			.catch(error => error.response);
	};


	getRewardsList = (request: RewardsRequest) => {
		return this.axios
			.get<RewardsResponse>(URL_REWARDS, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getRewardsDetail = (rewardId: string) => {
		return this.axios
			.get<RewardDetailResponse>(URL_REWARDS_DETAIL.replace('{rewardId}', rewardId))
			.then(response => response)
			.catch(error => error.response);
	};

	postRewardsDetail = (form: FormData) => {
		return this.axios
			.post<RewardDetailResponse>(URL_REWARDS, form, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	putRewardsDetail = (rewardId: string, form: FormData) => {
		return this.axios
			.put<RewardDetailResponse>(URL_REWARDS, form, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => response)
			.catch(error => error.response);
	};

	getProjectAppliedList = (request: ProjectAppliedListRequest) => {
		return this.axios
			.get<ProjectAppliedResponse>(URL_PROJECT_APPLIED, { params: request })
			.then(response => response)
			.catch(error => error.response);
	};

	getProjectApplicantDetail = (projectApplicationId: string) => {
		return this.axios
			.get<ProjectAppliedResponse>(
				URL_PROJECT_APPLICANT_DETAIL.replace('{projectApplicationId}', projectApplicationId)
			)
			.then(response => response)
			.catch(error => error.response);
	};

	putProjectApplicantQualified = (qualifiedIds: string[]) => {
		return this.axios
			.put<ProjectDetailResponse>(URL_PROJECT_APPLICANT_QUALIFIED, {
				projectApplicationIds: qualifiedIds
			})
			.then(response => response)
			.catch(error => error.response);
	};

	putProjectApplicantUnQualified = (qualifiedIds: string[]) => {
		return this.axios
			.put<ProjectDetailResponse>(URL_PROJECT_APPLICANT_UNQUALIFIED, {
				projectApplicationIds: qualifiedIds
			})
			.then(response => response)
			.catch(error => error.response);
	};

	postProjectAddApplicant = (leagueType: ProjectLeagueType, projectId: string, idolId: string) => {
		let url = URL_PROJECT_ADD_APPLICANT + '?leagueType=' + leagueType + '&projectId=' + projectId + '&idolId=' + idolId;

		return this.axios
			.post(url, {
				leagueType: leagueType,
				projectId: projectId,
				idolId: idolId
			})
			.then(response => response)
			.catch(error => error.response);
	};
}

/**
 * ====================================================
 * ====================================================
 * Request
 */
export type ProjectRequest = PagingRequest & {
	/**
	 *  리그 종류
	 *  Available values : OFFICIAL, OFF_SEASON
	 */
	leagueType?: ProjectLeagueType;

	/**
	 *  string
	 *  프로젝트 이름
	 */
	projectName?: string;

	/**
	 * 프로젝트 상태
	 *  Available values : RECRUIT_SOON, RECRUITING, OPEN_SOON, OPERATING, CLOSED
	 */
	status?: ProjectStatus;

	/**
	 * 프로젝트 활성화 여부
	 * boolean
	 */
	isActive?: boolean;

	/**
	 * 정렬 방식
	 *  Available values : ASC, DESC
	 */
	direction?: ListDirection;

	/**
	 * 정렬 필드명
	 */
	sortField?: string;
};

export type StageRequest = PagingRequest & {
	keyword: string;
};

export type MissionRequest = PagingRequest & {
	/**
	 *  검색어
	 *  keyword
	 */
	keyword?: string;

	/**
	 * 행동 종류 선택
	 *  Available values : LIVE, LIVE_REACTION, LIVE_CHAT_POST, LIVE_CHAT_GET, LIVE_DONATION, LIVE_VOTE, OTHER_LIVE_WATCH, OTHER_LIVE_DONATION, OTHER_LIVE_CHAT, OTHER_VOD_COMMENT
	 */
	actionType?: MissionActionType;

	/**
	 * 조건 종류 선택
	 *  Available values : COUNT, EVALUATION, TOTAL_DONATION, MIN_OVER_DONATION, STRING_LENGTH, RUNNING_TIME, LIMIT_TIME
	 */
	conditionType?: MissionActionGoalType;

	/**
	 * 정렬 방식
	 *  Available values : ASC, DESC
	 */
	direction?: SortCondition;

	/**
	 * 정렬 필드명
	 *  sortField
	 */
	sortField?: string;
};

export type RewardsRequest = PagingRequest & {
	/**
	 * keyword
	 * 검색어
	 */
	keyword?: string;

	/**
	 * 보상 종류
	 *  Available values : SKILL, CHARACTER
	 */
	type?: RewardType;

	/**
	 * 보상 정도 (이상)
	 *  index
	 */
	index?: string;

	/**
	 *  정렬 방식
	 *  Available values : ASC, DESC
	 */

	direction?: SortCondition;

	/**
	 * 정렬 필드명
	 */
	sortField?: string;
};

export type ProjectAppliedListRequest = PagingRequest & {
	/**
	 *  리그 종류
	 *  Available values : OFFICIAL, OFF_SEASON
	 */
	leagueType?: ProjectLeagueType;

	/**
	 *  string
	 *  프로젝트 이름
	 */
	projectName?: string;

	/**
	 * 프로젝트 상태
	 *  Available values : RECRUIT_SOON, RECRUITING, OPEN_SOON, OPERATING, CLOSED
	 */
	status?: ProjectAppliedStatus;

	/**
	 * 정렬 방식
	 *  Available values : ASC, DESC
	 */
	direction?: ListDirection;

	/**
	 * 아이돌 활동자명
	 */
	idolActivityName?: string;

	/**
	 * 정렬 필드명
	 */
	sortField?: string;
};

export type ProjectPutResponse = {};

export type ProjectsResponse = {
	projects: ProjectTrain[];
	totalElements: number;
	totalPages: number;
};

export type ProjectDetailResponse = {
	detail: ProjectTrain;
};

export type ProjectTrain = {
	leagueType: ProjectLeagueType;
	projectId: number;
	name: string;
	description: string;
	visualImageUrl: string;
	visualVideoUrl: string;
	recruitCapacity: number;
	operationCapacity: number;
	agentId: number;
	stageIds: number[];
	rewardIds: number[];
	recruitStartDateTime: string;
	recruitEndDateTime: string;
	applyResultNoticeDateTime: string;
	operationStartDateTime: string;
	operationEndDateTime: string;
	isActive: boolean;
	createDateTime: string;
	updateDateTime: string;
	stages?: StageTrain[];
	rewards?: RewardsTrain[];
};

export type StageResponse = {
	stages: StageSimpleTrain[];
	totalElements: number;
	totalPages: number;
};

export type StageDetailResponse = {
	detail: StageTrain;
};

export type MissionResponse = {
	missions: MissionTrain[];
	totalElements: number;
	totalPages: number;
};

export type RewardsResponse = {
	rewards: RewardsTrain[];
	totalElements: number;
	totalPages: number;
};

export type RewardDetailResponse = {
	detail: RewardsTrain;
};

export type ProjectAppliedResponse = {
	applications: ProjectAppliedTrain[];
	totalElements: number;
	totalPages: number;
};

export type ProjectApplicantDetailResponse = {
	application: ProjectApplicantDetailTrain;
};

export type StageSimpleTrain = {
	/**
	 *
	 */
	stageId: number;

	/**
	 *
	 */
	name: string;

	/**
	 *
	 */
	description: string;

	/**
	 *
	 */
	visualImageUrl: string;
};

export type StageTrain = {
	/**
	 * 스테이지 아이디
	 */
	stageId: number;

	/**
	 * 이름
	 */
	name: string;

	/**
	 * 설명
	 */
	description: string;

	/**
	 * 비주얼 이미지 주소
	 */
	visualImageUrl: string;

	/**
	 * 비주얼 비디오 주소
	 */
	visualVideoUrl: string;

	/**
	 * 스테이지 완료 보상
	 */
	rewardIds: number[];

	/**
	 * 종속된 미션 아이디들
	 */
	missionIds: number[];

	/**
	 * 종속된 미션들
	 */
	missions: MissionTrain[];

	/**
	 * 스테이지 완료 보상
	 */
	rewards: RewardsTrain[];

	/**
	 *
	 */
	createDateTime: string;

	/**
	 *
	 */
	updateDateTime: string;
};

export type MissionTrain = {
	/**
	 *  미션 아이디
	 */
	missionId: number;

	/**
	 * 이름
	 */
	name: string;

	/**
	 * 설명
	 */
	description: string;

	/**
	 * 대상
	 *    [ IDOL, PRODUCER ]
	 */
	targetType: MemberType;

	/**
	 * 액션 1
	 */
	action1: ActionTrain;

	action2: ActionTrain;

	action3: ActionTrain;

	/**
	 * 보상 아이디
	 */
	rewardIds: number[];

	/**
	 * 보상
	 */

	rewords: RewardsTrain[];

	/**
	 * 생성일
	 */
	createDateTime: string;

	/**
	 * 업데이트 일
	 */
	updateDateTime: string;
};

export type ActionTrain = {
	/**
	 * 미션 액션 타입
	 */
	type: MissionActionType;

	/**
	 * 조건
	 */
	conditions: ConditionTrain[];
};

export type ConditionTrain = {
	/**
	 * 미션 완료 조건
	 */
	type: MissionActionGoalType;

	/**
	 * 완료 수
	 */
	goal: number;

	/**
	 * 평가
	 */
	evaluation: {
		question: string;
		buttonName1: string;
		buttonName2: string;
		passButtonNumber: number;
	};
};

export type RewardsTrain = {
	rewardId?: number;
	name: string;
	iconImageUrl: string;
	type: RewardType;
	character?: {
		type: CharacterType;
		point: number;
	};
	skill?: {
		type: SkillType;
		point: number;
	};
	currency?: {
		currencyType?: CurrencyType;
		price?: number;
	};
	product?: {
		productType?: ProductType;
		productId?: number;
	};
	createDateTime?: string;
	updateDateTime?: string;
};

export type ProjectAppliedTrain = {
	projectApplicationId: number;

	/**
	 * 지원 상태
	 *  Enum:
	 *    [ AVAILABLE, APPLIED, WAITING, WITHDRAW, QUALIFIED, UNQUALIFIED, IN_PROGRESS, CLEARED, CLOSED ]
	 */
	status: ProjectAppliedStatus;

	/**
	 * 리그 종류
	 */
	leagueType: ProjectLeagueType;

	/**
	 * 프로젝트 아이디
	 */
	projectId: number;

	/**
	 * 프로젝트 이름
	 */
	projectName: string;

	/**
	 * 아이돌 아이디
	 */
	idolId: number;

	/**
	 * 활동명
	 */
	activityName: string;

	/**
	 * 프로필 사진 주소
	 */
	profileImgUrl: string;
};

export type ProjectApplicantDetailTrain = {
	/**
	 * 프로젝트 지원서 아이디
	 */
	projectApplicationId: number;

	/**
	 * 리그 종류
	 */
	leagueType: ProjectLeagueType;

	/**
	 * 프로젝트 아이디
	 */
	projectId: number;

	/**
	 * 지원 상태
	 */
	status: ProjectAppliedStatus;

	/**
	 * 아이돌 아이디
	 */
	idolId: number;

	/**
	 * 자기 소개
	 */
	introduction: string;

	/**
	 * 자기 사진들
	 *
	 *  string
	 *  자기 사진들
	 */
	photoUrls: string[];

	/**
	 * 활동명
	 */
	activityName: string;

	/**
	 * 식별아이디
	 */
	pid: string;

	/**
	 * 프로필 사진 주소
	 */
	profileImgUrl: string;

	/**
	 * 생년월일
	 */
	birthDate: string;

	/**
	 * 출생지
	 *  string
	 *
	 *
	 *  Enum:
	 *    [ HOKKAIDO, AOMORI, IWATE, MIYAGI, AKITA, YAMAGATA, FUKUSHIMA, IBARAKI, TOCHIGI, GUNMA, SAITAMA, CHIBA, TOKYO, KANAGAWA, NIIGATA, TOYAMA, ISHIKAWA, FUKUI, YAMANASHI, NAGANO, GIFU, SHIZUOKA, AICHI, MIE, SHIGA, KYOTO, OSAKA, HYOGO, NARA, WAKAYAMA, TOTTORI, SHIMANE, OKAYAMA, HIROSHIMA, YAMAGUCHI, TOKUSHIMA, KAGAWA, EHIME, KOCHI, FUKUOKA, SAGA, NAGASAKI, KUMAMOTO, OITA, MIYAZAKI, KAGOSHIMA, OKINAWA, OTHER ]
	 */
	birthPrefecture: string;

	/**
	 *  키
	 */
	height: number;

	/**
	 * 혈액형
	 */
	bloodType: string;

	/**
	 * 이메일
	 */
	email: string;

	/**
	 * 전화번호
	 */
	mobile: string;

	/**
	 *
	 */
	sta: boolean;

	/**
	 *
	 */
	ppa: boolean;

	/**
	 *
	 */
	mpa: boolean;

	privacy: UserPrivacyTrain;
};

export type UserPrivacyTrain = {
	/**
	 *
	 */
	idolId: number;
	activityName: string;
	pid: string;
	profileImgUrl: string;
	birthDate: string;
	birthPrefecture: string;
	height: number;
	bloodType: string;
	email: string;
	mobile: string;
};
