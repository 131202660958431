// Chakra imports
import { Flex, Grid, SimpleGrid, Text } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from "react";

import { useTranslation } from 'react-i18next';
import { useApi } from '../../../providers/api-provider';
import { useThemeColors } from '../../../providers/theme-provider';
import { DATA_FORMAT_SERVER_FULL_DATE, dateFormat, showToast } from "../../../helper/functions";
import {
	DashboardIdolHypyPoint, DashboardIdolSet,
	DashboardResponse,
	DashboardStoreTrain,
	DashboardValueTrain
} from "../../../apis/commonAPI";
import Card from 'components/card/Card';
import DashboardMemberCard from './components/DashboardMemberCard';
import { HSeparator } from '../../../components/separator/Separator';
import DashboardPaymentCard from './components/DashboardPaymentCard';
import SearchTable from "../../../components/table/SearchTable";
import { createDynamicColumns, VisibleColumns } from "components/table/createDynamicColumns";
import { TableRowType } from "../../../types/enums";

// 금지어 목록
export default function Dashboard() {
	const { t } = useTranslation();
	const { textColor } = useThemeColors();
	const { commonApi } = useApi();

	const [data, setData] = useState<DashboardResponse>();
	const [idolRecordSet, setIdolRecordSet] = useState<DashboardIdolSet[]>([]);

	useEffect(() => {
		// 오늘
		requestDashboard();
		requestDashboardIdolHypyPoint();
	}, []);

	const requestDashboard = () => {
		commonApi
			.getDashboard()
			.then(responseDashboard)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDashboard = (response: DashboardResponse) => {
		if (response) {
			const data = response;
			data.paymentAmount = formatStorePaymentData(data.paymentAmount);
			setData(data);
		}
	};


	const requestDashboardIdolHypyPoint = () => {
		let today = new Date();
		let startDate = new Date().setFullYear(2024, 10, 11);
		let endDate = new Date();

		commonApi
			.getDashboardIdolHypyPoint(dateFormat(startDate, DATA_FORMAT_SERVER_FULL_DATE), dateFormat(endDate, DATA_FORMAT_SERVER_FULL_DATE))
			.then(responseDashboardIdolHypyPoint)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};


	const responseDashboardIdolHypyPoint = (response: DashboardIdolHypyPoint) => {
		if (response.idolRecordSet) {
			setIdolRecordSet(response.idolRecordSet);
		}
	};

	const formatStorePaymentData = (storeData: DashboardStoreTrain) => {
		const formattedData: DashboardStoreTrain = {
			totalRecord: [],
			dailyRecord: [],
			lastWeekRecord: [],
			monthlyRecord: []
		};

		if (storeData) {
			const dailyPayments: DashboardValueTrain[] = [];
			const weeklyPayments: DashboardValueTrain[] = [];
			const monthlyPayments: DashboardValueTrain[] = [];

			storeData.totalRecord.sort((a, b) => a.store.localeCompare(b.store));

			storeData.totalRecord.forEach(item => {
				addOrUpdatePaymentRecord(item, storeData.dailyRecord, dailyPayments);
				addOrUpdatePaymentRecord(item, storeData.lastWeekRecord, weeklyPayments);
				addOrUpdatePaymentRecord(item, storeData.monthlyRecord, monthlyPayments);
			});

			formattedData.totalRecord = storeData.totalRecord;
			formattedData.dailyRecord = dailyPayments;
			formattedData.lastWeekRecord = weeklyPayments;
			formattedData.monthlyRecord = monthlyPayments;
		}

		return formattedData;
	};

	const addOrUpdatePaymentRecord = (
		sourceItem: DashboardValueTrain,
		sourceArray: DashboardValueTrain[],
		targetArray: DashboardValueTrain[]
	) => {
		const existingIndex = sourceArray.findIndex(
			record => record.store === sourceItem.store && record.payType === sourceItem.payType
		);

		if (existingIndex < 0) {
			targetArray.push({
				value: '',
				store: sourceItem.store,
				payType: sourceItem.payType,
				searchStartDateTime: '',
				searchEndDateTime: ''
			});
		} else {
			targetArray.push(sourceArray[existingIndex]);
		}
	};


	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<DashboardIdolSet>[] = [
		{
			row: 'idolId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'activityName',
			title: 'activityName',
			type: TableRowType.TEXT
		},
		{
			row: 'hypyPoint',
			title: 'hypyPoint',
			type: TableRowType.TEXT
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)\
	};

	const columns = useMemo(() => createDynamicColumns(idolRecordSet, t, visibleColumns, handleRowClick), [idolRecordSet, t]);

	if (!data) {
		return <Flex />;
	}

	return (
		<Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
			<Grid
				display={{ base: 'block', md: 'grid' }}
				pt={{ base: '0px', md: '0px', xl: '0px' }}
				gap={{ base: '20px', xl: '20px' }}
			>
				<Flex direction={'column'} me={{ lg: '40px' }}>
					<Grid
						display={{ base: 'block', md: 'grid' }}
						gridTemplateColumns={{ base: '1fr 1fr', lg: '1fr 1fr' }}
						gap={{ base: '20px', xl: '20px' }}
					>
						<Card px="0px">
							<Text color={textColor} fontSize="xl" fontWeight="700" pl={'20px'} mb={'20px'}>
								<h2>{t('dashboard.member')}</h2>
							</Text>
							<SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap={'20px'}>
								<DashboardMemberCard title={t('dashboard.register_idol_count')} orgData={data?.registerIdolCount} />
								<DashboardMemberCard
									title={t('dashboard.register_producer_count')}
									orgData={data?.registerProducerCount}
								/>
								<HSeparator />
								<DashboardMemberCard
									title={t('dashboard.withdrawal_idol_count')}
									orgData={data?.withdrawProducerCount}
								/>
								<DashboardMemberCard
									title={t('dashboard.withdrawal_producer_count')}
									orgData={data?.withdrawProducerCount}
								/>
							</SimpleGrid>
						</Card>
						<Card px="0px">
							<Text color={textColor} fontSize="xl" fontWeight="700" pl={'20px'} mb={'20px'}>
								<h2>{t('dashboard.payment')}</h2>
							</Text>
							<SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap={'20px'}>
								{data?.paymentAmount.totalRecord.map((v, i) => (
									<DashboardPaymentCard
										title={v.store === 'KOR' ? t('common.korea') : v.store === 'JPN' ? t('common.japan') : v.store}
										orgData={data?.paymentAmount}
										index={i}
									/>
								))}
							</SimpleGrid>
						</Card>
						<Card>
							<SimpleGrid mt={'20px'}>
								<Text color={textColor} fontSize="xl" fontWeight="700" pl={'20px'} mb={'20px'}>
									<h2>{t('dashboard.idol_status')}</h2>
								</Text>
								<SearchTable request={() => {

								}} data={idolRecordSet} columns={columns} totalCount={idolRecordSet.length} limit={idolRecordSet.length} />
							</SimpleGrid>
						</Card>
					</Grid>
				</Flex>
			</Grid>
		</Flex>
	);
}
