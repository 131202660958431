// Chakra imports
import { Box, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useThemeColors } from '../../providers/theme-provider';
import { debugLog, showToast } from '../../helper/functions';
import { MdOutlineCloudUpload } from 'react-icons/md';
import DropImageFile from './DropImageFile';
import DropImageFileFromUrl from './DropImageFileFromUrl';
import Dropzone from './Dropzone';
import Card from '../card/Card';

/**
 * 이미지 드랍 박스
 * @param props
 * @constructor
 */
export default function DropImageBox(props: {
	imgUrls?: string[];
	setImgUrls?: React.Dispatch<React.SetStateAction<string[]>>;
	imgFiles?: File[];
	setImgFiles?: React.Dispatch<React.SetStateAction<File[]>>;
	maxFiles?: number;
	title?: string;
	disabled?: boolean;
	addVideoFiles?: boolean;
	[x: string]: any;
}) {
	// 번역
	const {t} = useTranslation();

	const [acceptFiles, setAcceptFiles] = useState<string[]>([]);

	// 파라미터
	/**
	 * imgUrls: 이미지 URLs
	 * setImgUrls: 이미지 URLs 설정
	 * imgFiles: 이미지 드랍 파일 경로
	 * setImgFiles: 설정 이미지 드랍 파일 경로
	 * maxFiles: 최대 이미지 갯수
	 * title: 이미지 설명
	 */
	const {
		imgUrls,
		setImgUrls,
		imgFiles,
		setImgFiles,
		maxFiles,
		title,
		disabled,
		addVideoFiles,
		...rest
	} = props;


	const {textColor, textColorSecondary} = useThemeColors();

	const onDropImages = useCallback(
		(acceptedFiles: File[]) => {
			if (imgUrls && imgUrls.length > 0) {
				showToast(t('error.exist_thumbnail_img'));
				return;
			}

			const mappedFiles = acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			);

			setImgFiles(
				maxFiles === 1 ? [mappedFiles[0]] : [...imgFiles, ...mappedFiles]
			);
		},
		[imgUrls, imgFiles]
	);

	const onDeleteImages = useCallback(
		(index: number) => {
			if (maxFiles === 1) {
				setImgFiles([]);
			} else {
				const updatedImagesPreview = imgFiles.filter((_, i) => i !== index);
				setImgFiles(updatedImagesPreview);
			}
		},
		[imgFiles, setImgFiles]
	);

	const onDeleteImagesUrl = useCallback(
		(index: number) => {
			if (maxFiles === 1) {
				setImgUrls([]);
			} else {
				const updatedImagesPreview = imgUrls.filter((_, i) => i !== index);
				setImgUrls(updatedImagesPreview);
			}
		},
		[imgUrls, setImgUrls]
	);

	useEffect(() => {
		if (addVideoFiles) {
			setAcceptFiles(['.mp4', '.jpg', '.jpeg', '.png']);
		} else {
			setAcceptFiles(['.jpg', '.jpeg', '.png']);
		}
	}, []);

	return (
		<Box
			maxH="100%"
			borderWidth="1px"
			borderRadius="lg"
			position={'relative'}
			overflow={'hidden'}
			{...rest}
		>
			<SimpleGrid columns={{sm: 1, md: 3, xl: maxFiles > 5 ? 5: maxFiles}} gap={'10px'}>
				{imgFiles &&
					imgFiles.length > 0 &&
					imgFiles.map((item, index) => {
						return (
							<DropImageFile
								index={index}
								file={item}
								onDeleteFile={onDeleteImages}
								disabled={disabled}
							/>
						);
					})}
				{imgUrls &&
					imgUrls.length > 0 &&
					imgUrls.map((item, index) => {
						return (
							<DropImageFileFromUrl
								index={index}
								url={item}
								onDeleteFile={onDeleteImagesUrl}
								disabled={disabled}
							/>
						);
					})}
			</SimpleGrid>
			{!disabled && maxFiles > imgFiles.length && maxFiles > imgUrls.length ? (
				<Dropzone
					content={
						<Box maxW="100%">
							<Icon
								as={MdOutlineCloudUpload}
								w="20px"
								h="20px"
								color={textColor}
							/>
							<Text
								mb="8px"
								fontSize="md"
								w="100%"
								maxW="100%"
								fontWeight="700"
								color={textColor}
								whiteSpace="pre-wrap"
							>
								{title ? title : t('common.image')}
							</Text>
							<Text
								fontSize="sm"
								fontWeight="500"
								color="secondaryGray.500"
								white-space="pre-wrap !important"
								whiteSpace="pre-wrap"
							>
								{addVideoFiles ? t('components.only_accept_image_video_files') : t('components.only_accept_image_files')}
							</Text>
						</Box>
					}
					maxFiles={maxFiles}
					onDropAccepted={onDropImages}
					accept={acceptFiles}
				/>
			) : null}

			{disabled && imgFiles.length === 0 && imgUrls.length === 0 ? (
				<Text fontSize="sm" color={textColorSecondary} fontWeight="400" mt={'30px'} mb={'30px'} ms={'10px'} justifySelf={'center'}>
					{t('common.no_image')}
				</Text>
			) : null}
		</Box>
	);
}
