// Chakra imports
import {
	Button,
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text
} from "@chakra-ui/react";

// Custom components
// Assets
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import InputField from "components/fields/InputField";
import { useThemeColors } from "../../../../providers/theme-provider";
import { useApi } from "../../../../providers/api-provider";
import Card from "../../../../components/card/Card";
import { BrandShopStatusType } from "../../../../types/enums";
import { DATA_FORMAT_FULL_DATE, dateFormat, showToast } from "../../../../helper/functions";
import { HSeparator } from "components/separator/Separator";
import DropImageBox from "components/dropzone/DropzoneImgBox";
import TextAreaField from "components/fields/TextAreaField";
import ModalBasic from "components/modal/ModalBasic";
import { AdminCrowdFundingProviderDto } from "../../../../apis/crowdfundingAPI";
import ButtonField from "../../../../components/fields/ButtonField";

// 금지어 목록
export default function BrandShopDetail(props: {
	provider: AdminCrowdFundingProviderDto;
	requestChangeParams: () => void;
}) {
	const { t } = useTranslation();
	const { textColorPrimary, textColor } = useThemeColors();
	const { crowdFundingApi } = useApi();

	const { provider, requestChangeParams } = props;

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgUrls, setThumbnailImgUrls] = useState<string[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgFiles, setThumbnailImgFiles] = useState<File[]>([]);

	/**
	 * 상세 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgUrls, setDetailImgUrls] = useState<string[]>([]);

	/**
	 * 상세 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgFiles, setDetailImgFiles] = useState<File[]>([]);

	// 상호명
	const [name, setName] = useState<string>('');

	// 설명
	const [description, setDescription] = useState<string>('');

	// 브랜드 샵 신청 상태
	const [brandShopStatus, setBrandShopStatus] = useState<BrandShopStatusType>(BrandShopStatusType.APPROVED);

	//  팝업 노출 여부
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	const [isApplicationApprove, setIsApplicationApprove] = useState<boolean>(false);
	const [adminComment, setAdminComment] = useState<string>('');

	// 신청 및 처리 날짜
	const [registerDateTime, setRegisterDateTime] = useState<string>('');
	const [reactionDateTime, setReactionDateTime] = useState<string>('');

	useEffect(() => {
		if (provider) {
			getBrandInformaiton();
		}
	}, [provider]);

	const getBrandInformaiton = () => {
		crowdFundingApi
			.getBrandShopDetail(String(provider.crowdFundingProviderId))
			.then(response => {
				if (response.brandDetail) {
					setName(provider.name);
					if (provider.thumbnailImgUrl !== null && provider.thumbnailImgUrl !== '') {
						setThumbnailImgUrls([provider.thumbnailImgUrl]);
					}

					setBrandShopStatus(response.brandDetail.brandStatus);
					setDescription(response.brandDetail.brandDescription);

					setRegisterDateTime(response.brandDetail.registerDateTime);
					setReactionDateTime(response.brandDetail.reactionDateTime);

					if (response.brandDetail.brandDetailImgUrl && response.brandDetail.brandDetailImgUrl.length > 0) {
						setDetailImgUrls([response.brandDetail.brandDetailImgUrl]);
					}
				}
			})
			.catch(error => {
				showToast(t('error.try_again'));
			});
	};

	const isEditableInformation = () => {
		return false;
	};

	const isShowApproveButton = () => {
		return brandShopStatus === BrandShopStatusType.REGISTER;
	}

	const isShowApproveModal = (isApporve: boolean) => {
		setIsShowModal(true);
		setIsApplicationApprove(isApporve);
	}

	const putProviderRegister = (isApprove: boolean) => {
		if (isApprove) {
			crowdFundingApi
				.putBrandApprove(String(provider.crowdFundingProviderId))
				.then((response) => {
					setBrandShopStatus(BrandShopStatusType.APPROVED);
					requestChangeParams();
				})
				.catch((error) => {
					showToast(t('error.try_again'));
				});
		} else {
			crowdFundingApi
				.putBrandRejected(String(provider.crowdFundingProviderId), adminComment)
				.then(() => {
					setBrandShopStatus(BrandShopStatusType.REJECTED);
					requestChangeParams();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		}
	};

	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'row', md: 'row' }} justifyContent={'center'} mb="36px">
					<Grid
						display={{ base: 'block', md: 'grid' }}
						pt={{ base: '0px', md: '0px', xl: '0px' }}
						pl={{ base: '0px', md: '0px', xl: '20px' }}
						pr={{ base: '0px', md: '0px', xl: '20px' }}
						gap={{ base: '20px', xl: '20px' }}
						width={{ base: '90%', xl: 'calc(100% - 40px)' }}
						maxWidth={'1280px'}
					>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} justifyContent={'center'}>
							<Text
								display="flex"
								fontSize="xl"
								color={textColorPrimary}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
							>
								{t('store.brand_information')}
							</Text>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={'10px'}>
							{isShowApproveButton() ? (
								<ButtonField
									buttonName={t('provider.approved')}
									alignContent={'flex-end'}
									onClick={() => {
										isShowApproveModal(true);
									}}
								/>
							) : null}

							{isShowApproveButton() ? (
								<ButtonField
									buttonName={t('provider.rejected')}
									backgroundColor={'red'}
									alignContent={'flex-end'}
									onClick={() => {
										isShowApproveModal(false);
									}}
								/>
							) : null}
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.business_name')}
								value={name}
								disabled={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setName(e.target.value);
								}}
							/>
							<InputField
								label={t('store.representative_first_name')}
								value={provider.presidentLastName + ' ' + provider.presidentFirstName}
								disabled={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setName(e.target.value);
								}}
							/>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.brand_register_date')}
								value={dateFormat(registerDateTime, DATA_FORMAT_FULL_DATE)}
								disabled={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setName(e.target.value);
								}}
							/>
							<InputField
								label={t('store.brand_process_date')}
								value={dateFormat(reactionDateTime, DATA_FORMAT_FULL_DATE)}
								disabled={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setName(e.target.value);
								}}
							/>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('common.email')}
								value={provider.email}
								disabled={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setName(e.target.value);
								}}
							/>
							<InputField
								label={t('store.id')}
								value={provider.accountId}
								disabled={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setName(e.target.value);
								}}
							/>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
							<Text
								display="flex"
								ms="10px"
								fontSize="sm"
								color={textColorPrimary}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
							>
								{t('store.thumbnail')}
							</Text>
							<DropImageBox
								imgUrls={thumbnailImgUrls}
								setImgUrls={setThumbnailImgUrls}
								imgFiles={thumbnailImgFiles}
								setImgFiles={setThumbnailImgFiles}
								maxFiles={1}
								title={t('store.thumbnail')}
								disabled={!isEditableInformation()}
								width={'xs'}
							/>
						</SimpleGrid>
						<HSeparator />
						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
							<TextAreaField
								label={t('store.brand_shop_description') + ' *'}
								minH={'250px'}
								value={description}
								onChange={e => {
									setDescription(e.target.value);
								}}
								disabled={!isEditableInformation()}
								placeholder={t('store.detailed_description_guide')}
							/>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
							<Text
								display="flex"
								ms="10px"
								fontSize="sm"
								color={textColorPrimary}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
							>
								{t('store.brand_shop_detailed_image') + ' *'}
							</Text>
							<DropImageBox
								imgUrls={detailImgUrls}
								setImgUrls={setDetailImgUrls}
								imgFiles={detailImgFiles}
								disabled={!isEditableInformation()}
								title={t('store.brand_shop_detailed_image')}
								setImgFiles={setDetailImgFiles}
								maxFiles={1}
								width={'xs'}
							/>
						</SimpleGrid>
					</Grid>
				</Flex>
			</Card>
			<ModalBasic
				isOpen={isShowModal && isApplicationApprove}
				size={'xl'}
				onClose={() => {
					setIsShowModal(false);
				}}
				title={t('provider.brand_approved', {name: provider.name})}
				desc={t('provider.brand_approved_desc')}
				firstButton={{
					title: t('common.cancel'),
					onClick: () => {
						setIsShowModal(false);
					}
				}}
				secondaryButton={{
					title: isApplicationApprove ? t('provider.approved') : t('provider.rejected'),
					onClick: () => {
						setIsShowModal(false);
						putProviderRegister(true);
					}
				}}
			/>

			<Modal
				isOpen={isShowModal && !isApplicationApprove}
				onClose={() => {
					setIsShowModal(false);
				}}
				size={'xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('provider.brand_rejected', {name: provider.name})}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color={textColorPrimary} fontSize="md" fontWeight="500" ms={'10px'} mb={'10px'}>
							{t('provider.brand_rejected_desc')}
						</Text>
						<TextAreaField
							label={t('provider.store_rejected_reason')}
							onChange={e => {
								setAdminComment(e.target.value);
							}}
							value={adminComment}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="brand"
							mr={3}
							onClick={() => {
								if (adminComment === null || adminComment.length < 10) {
									showToast(t('error.input_admin_comment'));
									return;
								}

								putProviderRegister(false);
							}}
						>
							{t('provider.rejected')}
						</Button>
						<Button
							onClick={() => {
								setAdminComment('');
								setIsShowModal(false);
							}}
						>
							{t('common.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
