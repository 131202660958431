// Chakra imports
import {
	Button,
	Flex,
	GridItem,
	Popover,
	PopoverContent,
	PopoverTrigger,
	SimpleGrid,
	Stack,
	Text
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent } from 'react';
import { DATA_FORMAT_FULL_DATE, dateFormat, showToast } from '../../../../../helper/functions';
import MiniCalendar from '../../../../../components/calendar/MiniCalendar';
import { useThemeColors } from '../../../../../providers/theme-provider';

// Assets

export default function ItemDateSelector(props: {
	startDateTime: Date | null; // 날짜 또는 null
	setStartDateTime: React.Dispatch<React.SetStateAction<Date | null>>; // 상태 변경 함수
	isShowStartDateTime: boolean; // 상태값: true/false
	setIsShowStartDateTime: {
		on: () => void; // Function to set state to true
		off: () => void; // Function to set state to false
		toggle: () => void; // Function to toggle state
	}; // 상태 변경 함수
	startDateTitle?: string;

	closeAllPopup: () => void;
	endDateTime: Date | null; // 날짜 또는 null
	setEndDateTime: React.Dispatch<React.SetStateAction<Date | null>>; // 상태 변경 함수
	isShowEndDateTime: boolean; // 상태값: true/false
	setIsShowEndDateTime: {
		on: () => void; // Function to set state to true
		off: () => void; // Function to set state to false
		toggle: () => void; // Function to toggle state
	}; // 상태 변경 함수
	endDateTitle?: string;
	description?: string;
	leastStartDate?: Date | null;
	leastStartDateTitle?: string;
	leastEndDate?: Date | null;
	leastEndDateTitle?: string;
	disabled?: boolean;
	isUpdatedStartDate?: boolean;
	isUpdatedEndDate?: boolean;
}) {
	const { t } = useTranslation();
	const { textColorPrimary, textColorSecondary } = useThemeColors();

	const {
		startDateTime,
		setStartDateTime,
		isShowStartDateTime,
		setIsShowStartDateTime,
		startDateTitle,
		closeAllPopup,
		endDateTime,
		setEndDateTime,
		isShowEndDateTime,
		setIsShowEndDateTime,
		endDateTitle,
		description,
		leastStartDate,
		leastStartDateTitle,
		leastEndDate,
		leastEndDateTitle,
		disabled,
		isUpdatedStartDate,
		isUpdatedEndDate
	} = props;

	return (
		<SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} gap={'10px'}>
			<Flex gap={'10px'} direction="column">
				<Text fontSize="md" color={isUpdatedStartDate ? 'red' : textColorPrimary} fontWeight="bold">
					{startDateTitle}
				</Text>
				<Stack spacing={5} direction="row">
					<Popover
						isOpen={isShowStartDateTime}
						onOpen={() => {
							closeAllPopup();
							setIsShowStartDateTime.on();
						}}
						onClose={setIsShowStartDateTime.off}
						closeOnBlur={false}
						isLazy
						lazyBehavior="keepMounted"
					>
						<PopoverTrigger>
							<Button w="100%" colorScheme="purple">
								{dateFormat(startDateTime, DATA_FORMAT_FULL_DATE)}
							</Button>
						</PopoverTrigger>
						<PopoverContent bg={'transparent'} border={'0px'}>
							<MiniCalendar
								selectRange={false}
								isShowTimeSelect={true}
								disabled={disabled}
								mb="20px"
								onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
									if (disabled) {
										return;
									}

									if (leastStartDate && leastStartDate?.getTime() > value.getTime()) {
										showToast(t('error.earlier_start_date', { startdate: leastStartDateTitle }));
										setStartDateTime(leastStartDate);
									} else {
										setStartDateTime(value);
									}
								}}
								onClose={() => {
									setIsShowStartDateTime.off();
								}}
								value={startDateTime}
							/>
						</PopoverContent>
					</Popover>
				</Stack>
			</Flex>
			<Flex gap={'10px'} direction="column">
				<Text fontSize="md" color={isUpdatedEndDate ? 'red' : textColorPrimary} fontWeight="bold">
					{endDateTitle}
				</Text>
				<Stack spacing={5} direction="row">
					<Popover
						isOpen={isShowEndDateTime}
						onOpen={() => {
							closeAllPopup();
							setIsShowEndDateTime.on();
						}}
						onClose={setIsShowEndDateTime.off}
						closeOnBlur={false}
						isLazy
						lazyBehavior="keepMounted"
					>
						<PopoverTrigger>
							<Button w="100%" colorScheme="purple">
								{dateFormat(endDateTime, DATA_FORMAT_FULL_DATE)}
							</Button>
						</PopoverTrigger>
						<PopoverContent bg={'transparent'} border={'0px'}>
							<MiniCalendar
								selectRange={false}
								isShowTimeSelect={true}
								mb="20px"
								disabled={disabled}
								onChange={(value: Date, event: ChangeEvent<HTMLInputElement>) => {
									if (disabled) {
										return;
									}

									if (startDateTime.getTime() > value.getTime()) {
										setEndDateTime(leastEndDate ? leastEndDate : startDateTime);
										showToast(t('error.later_end_date_than_start_date'));
									} else if (leastEndDate && leastEndDate?.getTime() > value.getTime()) {
										setEndDateTime(leastEndDate);
										showToast(t('error.earlier_end_date', { endDate: leastEndDateTitle }));
									} else {
										setEndDateTime(value);
									}
								}}
								onClose={() => {
									setIsShowEndDateTime.off();
								}}
								value={endDateTime}
							/>
						</PopoverContent>
					</Popover>
				</Stack>
			</Flex>
			{description ? (
				<GridItem colSpan={{sm: 1, md: 2, xl: 2}}>
					<Text
						fontSize="sm"
						color={textColorSecondary}
						fontWeight="400"
						pr={'10px'}
						pl={'10px'}
						whiteSpace={'pre-line'}
					>
						{description}
					</Text>
				</GridItem>
			) : null}
		</SimpleGrid>
	);
}
