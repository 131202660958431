// Chakra imports
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../../providers/api-provider';
import { useThemeColors } from '../../../../../providers/theme-provider';
import {
	CrowdFundingItemApplyIdolListResponse,
	CrowdFundingItemApplyIdolTrain, CrowdFundingItemDetailDto
} from "../../../../../apis/crowdfundingAPI";
import { showToast } from '../../../../../helper/functions';
import ModalBasic from '../../../../../components/modal/ModalBasic';
import SearchTable from '../../../../../components/table/SearchTable';
import { createDynamicColumns, VisibleColumns } from '../../../../../components/table/createDynamicColumns';
import {
	CrowdFundingItemParticipantsItemStatusType,
	CrowdFundingItemParticipantsStatusType,
	TableRowType
} from "../../../../../types/enums";
import ButtonField from 'components/fields/ButtonField';
import ModalRegisterRewards from "./ModalRegisterRewards";


// Assets

export enum IdolModalType {
	APPLY = 'APPLY',
	OBTAINED = 'OBTAINED',
	USED = 'USED',
	REFUND = 'REFUND',
	PARTICIPATION = 'PARTICIPATION',
	CANCEL='CANCEL'
}

export default function ModalIdolList(props: {
	isOpen: boolean;
	onClose: () => void;
	modalType: IdolModalType,
	crowdfundingItemId: string,
	item: CrowdFundingItemDetailDto,
}) {
	const {t} = useTranslation();
	const {crowdFundingApi} = useApi();
	const {textColorBrand} = useThemeColors();

	const {isOpen, onClose, modalType, crowdfundingItemId, item} = props;
	// 목록
	const [list, setList] = useState<CrowdFundingItemApplyIdolTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const [isShowModalUsed, setIsShowModalUsed] = useState<boolean>(false);
	const [selectParticipantId, setSelectParticipantId] = useState<string>('');

	const [isShowModalRefund, setIsShowModalRefund] = useState<boolean>(false);

	const [isShowModalReigsterReward, setIsShowModalReigsterReward] = useState<boolean>(false);
	const [selectParticipant, setSelectParticipant] = useState<CrowdFundingItemApplyIdolTrain>(null);

	useEffect(() => {
		if (crowdfundingItemId) {
			setList([]);
			setTotalCount(0);
			requestIdolList();
		}
	}, [modalType, crowdfundingItemId]);

	const requestIdolList = () => {
		if (modalType === IdolModalType.APPLY) {
			crowdFundingApi.getCrowdFundingItemParticipants(crowdfundingItemId, {
				limit: limit,
				offset: offset * limit,
				participationStatus: CrowdFundingItemParticipantsStatusType.APPLY
			}).then(responseIdolList).catch(() => {
				showToast(t('error.try_again'));
			})
		} else if (modalType === IdolModalType.USED) {
			crowdFundingApi.getCrowdFundingItemParticipants(crowdfundingItemId, {
				limit: limit,
				offset: offset * limit,
				participationStatus: CrowdFundingItemParticipantsStatusType.USED
			}).then(responseIdolList).catch(() => {
				showToast(t('error.try_again'));
			})
		} else if (modalType === IdolModalType.REFUND) {
			crowdFundingApi.getCrowdFundingItemParticipantsItemStatus(crowdfundingItemId, {
				limit: limit,
				offset: offset * limit,
				itemStatus: CrowdFundingItemParticipantsItemStatusType.REFUND_REVIEW
			}).then(responseIdolList).catch(() => {
				showToast(t('error.try_again'));
			})
		} else if (modalType === IdolModalType.OBTAINED) {
			crowdFundingApi.getCrowdFundingItemParticipants(crowdfundingItemId, {
				limit: limit,
				offset: offset * limit,
				participationStatus: CrowdFundingItemParticipantsStatusType.OBTAINMENT
			}).then(responseIdolList).catch(() => {
				showToast(t('error.try_again'));
			})
		} else if (modalType === IdolModalType.PARTICIPATION) {
			crowdFundingApi.getCrowdFundingItemParticipants(crowdfundingItemId, {
				limit: limit,
				offset: offset * limit,
				participationStatus: CrowdFundingItemParticipantsStatusType.PARTICIPATION
			}).then(responseIdolList).catch(() => {
				showToast(t('error.try_again'));
			})
		} else if (modalType === IdolModalType.CANCEL) {
			crowdFundingApi.getCrowdFundingItemParticipants(crowdfundingItemId, {
				limit: limit,
				offset: offset * limit,
				participationStatus: CrowdFundingItemParticipantsStatusType.CANCEL
			}).then(responseIdolList).catch(() => {
				showToast(t('error.try_again'));
			})
		}
	}

	const responseIdolList = (response: CrowdFundingItemApplyIdolListResponse) => {
		if (response.idolTrain) {
			setList(response.idolTrain);
		}

		// setList([{
		// 	crowdFundingParticipationId: 1,
		// 	idolId: 23,
		// 	profileImgUrl: 'asdfa',
		// 	activityName: 'asdfasf',
		// 	status: CrowdFundingItemParticipantsStatusType.PARTICIPATION,
		// 	itemStatus: CrowdFundingItemApplyIdolItemStatusType.REFUND_EXECUTED,
		// 	isUploadReward: false,
		// 	applyDateTime: '',
		// 	usedDateTime: ''
		// }])

		if (response.count) {
			setTotalCount(response.count);
		} else {
			setTotalCount(0);
		}
		// setTotalCount(1)
	}

	const putApplyUsedIdol = () => {
		crowdFundingApi.putCrowdFundingItemUsed(selectParticipantId).then(() => {
			showToast(t('item.completed_used'));
			requestIdolList();
		}).catch(() => {
			showToast(t('error.try_again'));
		});
	}

	const postApproveRefund = () => {
		// crowdFundingApi.putRefundApproved(selectParticipantId).then(() => {
		// 	showToast(t('item.completed_refund'));
		// 	requestIdolList();
		// }).catch(() => {
		// 	showToast(t('error.try_again'));
		// });
	}

	const getListTitle = () => {
		switch (modalType) {
			case IdolModalType.APPLY:
				return t('item.apply_idol_list');
			case IdolModalType.USED:
				return t('item.used_idol_list');
			case IdolModalType.REFUND:
				return t('item.refund_idol_list');
			case IdolModalType.OBTAINED:
				return t('item.obtained_idol_list');
			case IdolModalType.PARTICIPATION:
				return t('item.participation_idol_list');
			case IdolModalType.CANCEL:
				return t('item.participation_cancel_list');
			default:
				return t('item.idol_list');
		}
	}

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<CrowdFundingItemApplyIdolTrain>[] = useMemo(() => {
		switch (modalType) {
			case IdolModalType.APPLY:
				return [
					{
						row: 'crowdFundingParticipationId',
						title: 'id',
						type: TableRowType.TEXT
					},
					{
						row: 'profileImgUrl',
						title: 'profileImgUrl',
						type: TableRowType.IMAGE
					},
					{
						row: 'activityName',
						title: 'activityName',
						type: TableRowType.TEXT
					},
					{
						row: 'applyDateTime',
						title: 'applyDateTime',
						type: TableRowType.DATETIME,
					},
					// {
					// 	row: 'status',
					// 	title: 'applyApproved',
					// 	type: TableRowType.ACTION,
					// 	extraComponent: (value, rowData) => {
					// 		return <Flex>
					// 			<ButtonField
					// 				buttonName={t('item.approve_used')}
					// 				onClick={() => {
					// 					setIsShowModalUsed(true);
					// 					setSelectParticipantId(String(rowData.crowdFundingParticipationId));
					// 				}}
					// 			/>
					// 		</Flex>
					// 	}
					// },
				];
			case IdolModalType.USED:
				return [
					{
						row: 'crowdFundingParticipationId',
						title: 'id',
						type: TableRowType.TEXT
					},
					{
						row: 'activityName',
						title: 'activityName',
						type: TableRowType.TEXT
					},
					{
						row: 'usedDateTime',
						title: 'usedDateTime',
						type: TableRowType.DATETIME
					},
					{
						row: 'isUploadReward',
						title: 'isUploadReward',
						type: TableRowType.BOOLEAN
					}, {
						row: 'status',
						title: 'uploadReward',
						type: TableRowType.ACTION,
						extraComponent: (value, rowData) => {
							return (<Flex>
								<ButtonField
									buttonName={rowData.isUploadReward ? t('item.show_upload_reward') : t('item.upload_reward')}
									backgroundColor={'brand'}
									onClick={() => {
										setIsShowModalReigsterReward(true);
										setSelectParticipantId(String(rowData.crowdFundingParticipationId));
										setSelectParticipant(rowData)
									}}/>
							</Flex>)
						}
					},
				];
			case IdolModalType.REFUND:
				return [
					{
						row: 'crowdFundingParticipationId',
						title: 'id',
						type: TableRowType.TEXT
					},
					{
						row: 'profileImgUrl',
						title: 'profileImgUrl',
						type: TableRowType.IMAGE
					},
					{
						row: 'activityName',
						title: 'activityName',
						type: TableRowType.TEXT
					},
					// {
					// 	row: 'status',
					// 	title: 'refundApproved',
					// 	type: TableRowType.ACTION,
					// 	extraComponent: (value, rowData) => {
					// 		return <Flex>
					// 			<ButtonField
					// 				buttonName={t('item.approve_refund')}
					// 				onClick={() => {
					// 					setIsShowModalRefund(true);
					// 					setSelectParticipantId(String(rowData.crowdFundingParticipationId));
					// 				}}/>
					// 		</Flex>
					// 	}
					// },
				];
			case IdolModalType.CANCEL:
			case IdolModalType.OBTAINED:
			case IdolModalType.PARTICIPATION:
				return [
					{
						row: 'crowdFundingParticipationId',
						title: 'id',
						type: TableRowType.TEXT
					},
					{
						row: 'profileImgUrl',
						title: 'profileImgUrl',
						type: TableRowType.IMAGE
					},
					{
						row: 'activityName',
						title: 'activityName',
						type: TableRowType.TEXT
					}
				];
			default:
				return [];
		}
	}, [modalType]);

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// window.location.href = '/admin/item/detail/' + rowData.crowdFundingItemId;
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick),
		[list, t]
	);


	return (
		<Modal isOpen={isOpen} onClose={onClose} size={'4xl'}
					 scrollBehavior={'inside'} isCentered>
			<ModalOverlay/>
			<ModalContent>
				<ModalCloseButton/>
				<ModalBody pb={6}>
					<Text
						display="flex"
						fontSize="md"
						color={textColorBrand}
						fontWeight="bold"
						_hover={{cursor: 'pointer'}}
						mt={'20px'}
						mb={'20px'}
					>
						{getListTitle()}
					</Text>
					<SearchTable
						request={requestIdolList}
						data={list}
						columns={columns}
						totalCount={totalCount}
					/>
				</ModalBody>
			</ModalContent>

			{/*<ModalBasic*/}
			{/*	isOpen={isShowModalUsed}*/}
			{/*	onClose={() => {*/}
			{/*		setIsShowModalUsed(false);*/}
			{/*	}}*/}
			{/*	title={t('item.confirm_used')}*/}
			{/*	desc={t('item.confirm_used_desc')}*/}
			{/*	secondaryButton={{*/}
			{/*		title: t('common.ok'),*/}
			{/*		onClick: () => {*/}
			{/*		*/}
			{/*		}*/}
			{/*	}}/>*/}

			<ModalBasic
				isOpen={isShowModalUsed}
				onClose={() => {
					setIsShowModalUsed(false);
				}}
				title={t('item.confirm_used')}
				desc={t('item.confirm_used_desc')}
				secondaryButton={{
					title: t('common.ok'),
					onClick: () => {
						putApplyUsedIdol();
						setIsShowModalUsed(false);
					}
				}}/>

			<ModalBasic
				isOpen={isShowModalRefund}
				onClose={() => {
					setIsShowModalRefund(false);
				}}
				title={t('item.confirm_refund')}
				desc={t('item.confirm_refund_desc')}
				secondaryButton={{
					title: t('common.ok'),
					onClick: () => {
						postApproveRefund();
						setIsShowModalRefund(false);
					}
				}}/>


			<ModalRegisterRewards
				isOpen={isShowModalReigsterReward}
				crowfundItem={item}
				onClose={() => {
					setIsShowModalReigsterReward(false);
				}}
				requestChangeParams={() => {
					requestIdolList();
					setIsShowModalReigsterReward(false);
				}}
				selectIdol={selectParticipant}/>
		</Modal>
	);
}
