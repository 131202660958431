// Chakra imports
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { DashboardStoreTrain, DashboardValueTrain } from '../../../../apis/commonAPI';
import { useThemeColors } from '../../../../providers/theme-provider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { numberFormat } from '../../../../helper/functions';

export default function DashboardPaymentCard(props: { title: string; orgData: DashboardStoreTrain; index: number }) {
	const { title, orgData, index } = props;

	const { t } = useTranslation();
	const { borderColor, textColor, shadow, cardBg } = useThemeColors();

	// Helper function to render value rows
	const renderValueRecord = (rowTitle: string, record: DashboardValueTrain) => (
		<Tr>
			<Td>{rowTitle}</Td>
			<Td textAlign={'end'} pr={'30%'}>
				{numberFormat(record?.value) || '-'}
			</Td>
		</Tr>
	);

	return (
		<Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex
				align="center"
				justify="space-between"
				w="100%"
				px="22px"
				pb="8px"
				boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.12)"
			>
				<Text color={textColor} fontSize="xl" fontWeight="700">
					<h2>{title}</h2>
				</Text>
			</Flex>
			<Box>
				<Table variant="simple" color="gray.500" mt="12px">
					<Thead>
						<Tr>
							<Th>{t('common.type')}</Th>
							<Th textAlign={'end'} pr={'30%'}>
								{t('dashboard.payment_value', {
									payType:
										orgData?.totalRecord[index].payType === 'BUY'
											? t('dashboard.pay_type_buy')
											: orgData?.totalRecord[index].payType === 'REFUND'
											  ? t('dashboard.pay_type_refund')
											  : '',
									currencyType: orgData?.totalRecord[index].store === 'KOR'
										? t('common.currency_won')
										: orgData?.totalRecord[index].store === 'JPN'
											? t('common.currency_yen')
											: '',
								})}
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{renderValueRecord(t('dashboard.total'), orgData?.totalRecord[index])}
						{renderValueRecord(t('dashboard.today'), orgData?.dailyRecord[index])}
						{renderValueRecord(t('dashboard.this_week'), orgData?.lastWeekRecord[index])}
						{renderValueRecord(t('dashboard.this_month'), orgData?.monthlyRecord[index])}
					</Tbody>
				</Table>
			</Box>
		</Flex>
	);
}
