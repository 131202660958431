// Chakra imports
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Tooltip,
	useColorModeValue,
	Text
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineAdd, MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchInput from '../../../../components/actions/Search';
import SearchTable from '../../../../components/table/SearchTable';
import {
	CrowdFundingItemDto,
	CrowdFundingItemListRequest,
	CrowdFundingItemListResponse
} from '../../../../apis/crowdfundingAPI';
import { ItemCategoryType, ItemRegisterStatusType, ItemSalesStatusType, TableRowType } from '../../../../types/enums';
import { TableBodyText } from '../../../../components/table/TableComponents';
import { numberFormat } from '../../../../helper/functions';
import { CrowdFundingItemRegisterStatusTypeString, ItemSalesStatusTypeString } from "../../../../helper/types";
import ProviderDetail from "../provider/detail";
import CrowdFundingItemDetail from "./detail";

// 금지어 목록
export default function CrowdFunItemList() {
	const { crowdFundingApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton, textColorPrimary } = useThemeColors();

	// 목록
	const [list, setList] = useState<CrowdFundingItemDto[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	// 상세 팝업
	const [selectDetailId, setDetailId] = useState<string>('');
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	// 상품 등록 불가 팝업
	const [isShowBlockModal, setIsShowBlockModal] = useState<boolean>(false);

	// 검색어
	const [searchString, setSearchString] = useState('');
	// 검색 조건
	const [searchParams, setSearchParams] = useState<Partial<CrowdFundingItemListRequest>>({});

	// 필터 카테고리
	const [filterItemCategory, setFilterItemCategory] = useState<ItemCategoryType[]>([]);

	// 필터 판매 상태
	const [filterSalesStatus, setFilterSalesStatus] = useState<ItemSalesStatusType[]>([]);

	// 필터 판매 상태
	const [filterRegisterStatus, setFilterRegisterStatus] = useState<ItemRegisterStatusType[]>([]);

	// 필터 판매 상태
	const [filterUpdateStatus, setFilterUpdateStatus] = useState<ItemRegisterStatusType[]>([]);

	const [selectItemId, setSelectItemId] = useState<string>('');

	useEffect(() => {
		if (searchParams) {
			requestList(offset);
		}
	}, [searchParams]);

	// 목록 요청
	const requestList = async (offset: number) => {
		setOffset(offset);
		crowdFundingApi
			.getCrowdFundingItemList({
				limit: limit,
				offset: offset * limit,
				...searchParams
			})
			.then(responseList)
			.catch(() => {});
	};

	// 목록 처리
	const responseList = (response: CrowdFundingItemListResponse) => {
		// setIsShowBlockModal(!response.detailInRegistered);

		if (response.itemBasicTrain) {
			setList(response.itemBasicTrain);
		}

		if (response.count) {
			setTotalCount(response.count);
		}
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<CrowdFundingItemDto>[] = [
		{
			row: 'crowdFundingItemId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'coverImgUrl',
			title: 'crowdFundingItemThumbnail',
			type: TableRowType.IMAGE
		},
		{
			row: 'name',
			title: 'name',
			type: TableRowType.TEXT
		},
		{
			row: 'category',
			title: 'category',
			type: TableRowType.TEXT
		},
		{
			row: 'salesStatus',
			title: 'salesStatus',
			type: TableRowType.ENUM,
			enumToText: ItemSalesStatusTypeString
		},
		{
			row: 'stock',
			title: 'stock',
			type: TableRowType.TEXT
		},
		{
			row: 'createDateTime',
			title: 'createDateTime',
			type: TableRowType.DATETIME
		},
		{
			row: 'registerStatus',
			title: 'registerStatus',
			type: TableRowType.ENUM,
			enumToText: CrowdFundingItemRegisterStatusTypeString
		},
		{
			row: 'updateStatus',
			title: 'updateStatus',
			type: TableRowType.ENUM,
			enumToText: CrowdFundingItemRegisterStatusTypeString
		},
		{
			row: 'applyCount',
			title: 'useRegisterIdolCount',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={value ? numberFormat(value) : '-'}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// window.location.href = '/admin/item/detail/' + rowData.crowdFundingItemId;
		setIsShowModal(true);
		setSelectItemId(rowData.crowdFundingItemId);
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		setSearchParams({
			...searchParams,
			searchWord: searchString
		});
		setOffset(0);
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
	};


	/**
	 * 필터 판매 상태 감지
	 */
	useEffect(() => {
		setSearchParams({
			...searchParams,
			salesStatusSet: filterSalesStatus
		});
		setOffset(0);
	}, [filterSalesStatus]);

	/**
	 * 필터 카테고리
	 */
	useEffect(() => {
		setSearchParams({
			...searchParams,
			categorySet: filterItemCategory
		});
		setOffset(0);
	}, [filterItemCategory]);

	/**
	 * 필터 아이템 등록 상태
	 */
	useEffect(() => {
		setSearchParams({
			...searchParams,
			registerStatusSet: filterRegisterStatus
		});
		setOffset(0);
	}, [filterRegisterStatus]);

	/**
	 * 필터 아이템 수정 상태
	 */
	useEffect(() => {
		setSearchParams({
			...searchParams,
			updateStatusSet: filterUpdateStatus
		});
		setOffset(0);
	}, [filterUpdateStatus]);

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb="10px">
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					{/*<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>*/}
					{/*	<Tooltip label={t('item.register')}>*/}
					{/*		<Button*/}
					{/*			me={{ base: '10px', md: '20px' }}*/}
					{/*			ml={{ base: '10px', sm: '10px', md: '10px' }}*/}
					{/*			bg={buttonBg}*/}
					{/*			border="1px solid"*/}
					{/*			color={textColorPrimary}*/}
					{/*			borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}*/}
					{/*			borderRadius="16px"*/}
					{/*			_placeholder={{ color: 'secondaryGray.600' }}*/}
					{/*			_hover={hoverButton}*/}
					{/*			_active={activeButton}*/}
					{/*			_focus={activeButton}*/}
					{/*			onClick={handleClickRegister}*/}
					{/*		>*/}
					{/*			<Icon color={textColor} as={MdOutlineAdd} mr={'10px'} />*/}
					{/*			{t('item.register')}*/}
					{/*		</Button>*/}
					{/*	</Tooltip>*/}
					{/*</Flex>*/}
				</Flex>

				<Accordion allowToggle>
					<AccordionItem>
						<h2>
							<AccordionButton>
								<Box as="span" flex="1" textAlign="left">
									{t('item.list_search_filter')}
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>
							<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px" height={'max-content'} px={'20px'}>
								<Card flexDir={'row'} alignItems={'center'}>
									<Text fontSize={'sm'} textColor={textColor} mr={{ base: '10px', md: '20px' }}>
										{t('item.category')}
									</Text>
									<CheckboxGroup
										colorScheme="purple"
										value={filterItemCategory}
										onChange={e => {
											setFilterItemCategory(e as ItemCategoryType[]);
										}}
									>
										<SimpleGrid columns={{ base: 2, md: 3, lg: 3, xl: 3, '2xl': 6 }} gap={'10px'}>
											<Checkbox value={ItemCategoryType.VOCAL}>{t('item.category_vocal')}</Checkbox>
											<Checkbox value={ItemCategoryType.RAP}>{t('item.category_rap')}</Checkbox>
											<Checkbox value={ItemCategoryType.DANCE}>{t('item.category_dance')}</Checkbox>
											<Checkbox value={ItemCategoryType.STYLE}>{t('item.category_style')}</Checkbox>
											<Checkbox value={ItemCategoryType.PR}>{t('item.category_pr')}</Checkbox>
											<Checkbox value={ItemCategoryType.ETC}>{t('item.category_etc')}</Checkbox>
										</SimpleGrid>
									</CheckboxGroup>
								</Card>
								<Card flexDir={'row'} alignItems={'center'}>
									<Text fontSize="sm" textColor={textColor} mr={'20px'}>
										{t('item.sales_status')}
									</Text>
									<CheckboxGroup
										colorScheme="purple"
										value={filterSalesStatus}
										onChange={e => {
											setFilterSalesStatus(e as ItemSalesStatusType[]);
										}}
									>
										<SimpleGrid columns={{ base: 2, md: 2, lg: 2, xl: 4, '2xl': 4 }} gap={'10px'}>
											<Checkbox value={ItemSalesStatusType.OPEN_SOON}>{t('item.sales_status_pending')}</Checkbox>
											<Checkbox value={ItemSalesStatusType.ON_SALE}>{t('item.sales_status_on_sale')}</Checkbox>
											<Checkbox value={ItemSalesStatusType.SOLD_OUT}>{t('item.sales_status_sold_out')}</Checkbox>
											<Checkbox value={ItemSalesStatusType.END_SALE}>{t('item.sales_status_suspend')}</Checkbox>
										</SimpleGrid>
									</CheckboxGroup>
								</Card>
								<Card flexDir={'row'} alignItems={'center'}>
									<Text fontSize="sm" textColor={textColor} mr={'20px'}>
										{t('item.register_status')}
									</Text>
									<CheckboxGroup
										colorScheme="purple"
										value={filterRegisterStatus}
										onChange={e => {
											setFilterRegisterStatus(e as ItemRegisterStatusType[]);
										}}
									>
										<SimpleGrid columns={{ base: 1, md: 3 }} gap={'10px'}>
											<Checkbox value={ItemRegisterStatusType.REGISTERED}>{t('item.register_status_pending')}</Checkbox>
											<Checkbox value={ItemRegisterStatusType.APPROVED}>{t('item.register_status_approval')}</Checkbox>
											<Checkbox value={ItemRegisterStatusType.REJECTED}>{t('item.sales_status_rejected')}</Checkbox>
										</SimpleGrid>
									</CheckboxGroup>
								</Card>
								<Card flexDir={'row'} alignItems={'center'}>
									<Text fontSize="sm" textColor={textColor} mr={'20px'}>
										{t('item.update_status')}
									</Text>
									<CheckboxGroup
										colorScheme="purple"
										value={filterUpdateStatus}
										onChange={e => {
											setFilterUpdateStatus(e as ItemRegisterStatusType[]);
										}}
									>
										<SimpleGrid columns={{ base: 1, md: 3 }} gap={'10px'}>
											<Checkbox value={ItemRegisterStatusType.REGISTERED}>{t('item.register_status_pending')}</Checkbox>
											<Checkbox value={ItemRegisterStatusType.APPROVED}>{t('item.register_status_approval')}</Checkbox>
											<Checkbox value={ItemRegisterStatusType.REJECTED}>{t('item.sales_status_rejected')}</Checkbox>
										</SimpleGrid>
									</CheckboxGroup>
								</Card>
							</SimpleGrid>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>

				<SearchTable request={requestList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowBlockModal}
				onClose={() => {
					setIsShowBlockModal(false);
					// navigate(-1);
				}}
				isCentered
				size={'xl'}
			>
				<ModalOverlay />

				<ModalContent>
					<ModalHeader>{t('common.notice')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody overflow="auto">
						<Flex>
							<Text fontSize={'md'} color={textColor} fontWeight={'bold'}>
								{t('item.notice_cannot_register_item')}
							</Text>
						</Flex>
					</ModalBody>
					<ModalFooter />
				</ModalContent>
			</Modal>

			<Modal
				isOpen={isShowModal}
				onClose={() => {
					setIsShowModal(false);
				}}
				size={'full'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<ModalCloseButton />
						<CrowdFundingItemDetail
							itemId={selectItemId}
							requestChangeParams={() => {
								requestList(offset);
								setIsShowModal(false);
							}}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
