import React, { createContext, useContext, useState } from 'react';

// Context 타입 정의
interface LoadingContextType {
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

// Context 생성
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

// Provider 컴포넌트
export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [loading, setLoading] = useState(false);

	return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

// 커스텀 훅
export const useLoading = (): LoadingContextType => {
	const context = useContext(LoadingContext);
	if (!context) {
		throw new Error('useLoading must be used within a LoadingProvider');
	}
	return context;
};
