// Chakra imports
import { Flex, FormLabel, Input, Text } from '@chakra-ui/react';
import { useThemeColors } from '../../providers/theme-provider';
// Custom components

export default function Default(props: {
	id?: string;
	label?: string;
	extra?: string;
	placeholder?: string;
	type?: string;
	disabled?: boolean;
	isUpdated?: boolean;
	[x: string]: any;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
	const { id, label, extra, placeholder, type, mb, disabled = false, isUpdated, ...rest } = props;

	const { textColorSecondary, textColorPrimary } = useThemeColors();
	// Chakra Color Mode

	return (
		<Flex direction="column" mb={mb ? mb : '10px'}>
			<FormLabel
				display="flex"
				ms="10px"
				htmlFor={id}
				fontSize="sm"
				color={isUpdated ? 'red' : textColorPrimary}
				fontWeight="bold"
				_hover={{ cursor: 'pointer' }}
			>
				{label}
				{/*<Text fontSize="sm" fontWeight="400" ms="2px">*/}
				{/*	{extra}*/}
				{/*</Text>*/}
			</FormLabel>
			<Input
				{...rest}
				type={type}
				id={id}
				fontWeight="500"
				variant="main"
				disabled={disabled}
				placeholder={placeholder}
				_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
				borderRadius="6px !important"
				h="44px"
				maxH="44px"
				onChange={props?.onChange}
			/>
			{extra && (
				<Text
					fontSize="sm"
					color={textColorSecondary}
					fontWeight="400"
					mt={'10px'}
					pr={'10px'}
					pl={'10px'}
					whiteSpace={'pre-line'}
				>
					{extra}
				</Text>
			)}
		</Flex>
	);
}
