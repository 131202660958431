// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Select,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchInput from '../../../../components/actions/Search';
import { ProviderStatusTypeString } from '../../../../helper/types';
import SearchTable from '../../../../components/table/SearchTable';
import { AdminCrowdFundingProviderDto } from '../../../../apis/crowdfundingAPI';
import { ProviderSearchType, ProviderStatusType, TableRowType } from '../../../../types/enums';
import { TableBodyText } from '../../../../components/table/TableComponents';
import { formatBusinessNumber } from '../../../../helper/functions';
import ProviderDetail from '../provider/detail';
import { IoCloseCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

// 금지어 목록
export default function ProviderApplicationList() {
	const { crowdFundingApi } = useApi();
	const navigate = useNavigate();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton, textColorPrimary } = useThemeColors();

	// 목록
	const [list, setList] = useState<AdminCrowdFundingProviderDto[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const [searchType, setSearchType] = useState<ProviderSearchType>(ProviderSearchType.NAME);
	const [searchWord, setSearchWord] = useState<string>('');

	// 상세 팝업
	const [isShowDetailModal, setIsDetailModal] = useState<boolean>(false);
	const [selectProviderId, setSelectProviderId] = useState<string>('');

	useEffect(() => {
		requestList(offset);
	}, []);

	// 목록 요청
	const requestList = async (offset: number) => {
		setOffset(offset);
		crowdFundingApi
			.getProvider({
				limit: limit,
				offset: offset * limit,
				providerStatus: ProviderStatusType.REGISTER_PENDING,
				searchType: searchType === ProviderSearchType.NONE ? '' : searchType,
				searchWord: searchType === ProviderSearchType.NONE ? '' : searchWord
			})
			.then(response => {
				if (response.providerBasicTrain) {
					setList(response.providerBasicTrain);
				}

				if (response.count) {
					setTotalCount(response.count);
				} else {
					setTotalCount(0);
				}
			})
			.catch(() => {
				setList([]);
				setTotalCount(0);
			});
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<AdminCrowdFundingProviderDto>[] = [
		{
			row: 'crowdFundingProviderId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'name',
			type: TableRowType.TEXT
		},
		{
			row: 'presidentLastName',
			type: TableRowType.TEXT
		},
		{
			row: 'presidentFirstName',
			type: TableRowType.TEXT
		},
		{
			row: 'businessStructureType',
			type: TableRowType.TEXT
		},
		{
			row: 'businessRegistrationNo',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={value ? formatBusinessNumber(value) : '-'}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		},
		{
			row: 'registerApprovedDateTime',
			type: TableRowType.DATETIME
		},
		{
			row: 'providerStatus',
			type: TableRowType.ENUM,
			enumToText: ProviderStatusTypeString
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		setSelectProviderId(String(rowData.crowdFundingProviderId));
		setIsDetailModal(true);
		// navigate('/admin/crowdfunding/provider/detail/' + );
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		requestList(0);
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchWord(e.target.value);
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb={'20px'}>
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						leftElement={
							<Select
								fontSize="sm"
								id="edit_product"
								variant="unstyled"
								h={'44px'}
								w={{ md: '80px', xl: '120px' }}
								value={searchType}
								onChange={e => setSearchType(e.target.value as ProviderSearchType)}
							>
								<option value={ProviderSearchType.NAME}>{t('components.list_search_type_provider_name')}</option>
								<option value={ProviderSearchType.EMAIL}>{t('components.list_search_type_provider_email')}</option>
							</Select>
						}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
					<Flex justifyContent={'flex-end'} mr={{ base: '0px', sm: '10px' }}>
						<Tooltip label={t('provider.application_rejected_list')}>
							<Button
								me={{ base: '10px', md: '20px' }}
								ml={{ base: '10px', sm: '10px', md: '10px' }}
								bg={buttonBg}
								border="1px solid"
								color={textColorPrimary}
								borderColor={useColorModeValue('secondaryGray.100', 'whiteAlpha.100')}
								borderRadius="16px"
								_placeholder={{ color: 'secondaryGray.600' }}
								_hover={hoverButton}
								_active={activeButton}
								_focus={activeButton}
								onClick={() => {
									navigate('/admin/crowdfunding/provider/application/rejected');
								}}
							>
								<Icon color={textColor} as={IoCloseCircle} mr={'10px'} />
								{t('provider.application_rejected_list')}
							</Button>
						</Tooltip>
					</Flex>
				</Flex>
				<SearchTable request={requestList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowDetailModal}
				onClose={() => {
					setIsDetailModal(false);
				}}
				size={'full'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<ModalCloseButton />
						<ProviderDetail
							providerId={selectProviderId}
							requestChangeParams={() => {
								requestList(offset);
								setIsDetailModal(false);
							}}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
