// Chakra imports
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { DashboardTrain, DashboardValueTrain } from '../../../../apis/commonAPI';
import { useThemeColors } from '../../../../providers/theme-provider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { numberFormat } from "../../../../helper/functions";

export default function DashboardMemberCard(props: { title: string; orgData: DashboardTrain }) {
	const { title, orgData } = props;

	const { t } = useTranslation();
	const { borderColor, textColor, shadow, cardBg } = useThemeColors();

	// Helper function to render value rows
	const renderValueRecord = (rowTitle: string, record: DashboardValueTrain) => (
		<Tr>
			<Td>{rowTitle}</Td>
			<Td  textAlign={'end'} pr={'30%'}>{numberFormat(record?.value) || '-'}</Td>
		</Tr>
	);

	return (
		<Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex
				align="center"
				justify="space-between"
				w="100%"
				px="22px"
				pb="8px"
				boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.12)"
			>
				<Text color={textColor} fontSize="xl" fontWeight="700">
					<h2>{title}</h2>
				</Text>
			</Flex>
			<Box>
				<Table variant="simple" color="gray.500" mt="12px">
					<Thead>
						<Tr>
							<Th>{t('common.type')}</Th>
							<Th textAlign={'end'} pr={'30%'}>{t('dashboard.register_count')}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{renderValueRecord(t('dashboard.total'), orgData.totalRecord)}
						{renderValueRecord(t('dashboard.today'), orgData.dailyRecord)}
						{renderValueRecord(t('dashboard.this_week'), orgData.lastWeekRecord)}
						{renderValueRecord(t('dashboard.this_month'), orgData.monthlyRecord)}
					</Tbody>
				</Table>
			</Box>
		</Flex>
	);
}
