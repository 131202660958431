// Chakra imports
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Image, Text } from '@chakra-ui/react';
import { getFileName } from '../../helper/functions';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import VideoPlayer from 'components/video/VideoPlayer';

export default function DropImageFileFromUrl(props: {
  index: number;
  url: string;
  onDeleteFile: (index: number) => void;
  isDeletable?: boolean;
  disabled?: boolean;
}) {
  const {
    index,
    url,
    onDeleteFile,
    isDeletable = true,
    disabled = false,
  } = props;

  if (!url) {
    return <Box/>;
  }

  return (
    <Box
      maxW={'100%'}
      maxH="sm"
      borderWidth="1px"
      overflow="hidden"
      position={'relative'}
      minH={'150px'}
    >
      {getFileName(url).toLowerCase().includes("jpg") ||
        getFileName(url).toLowerCase().includes("jpeg") ||
        getFileName(url).toLowerCase().includes("png") ? (
        <Zoom>
          <Image src={url} objectFit={'cover'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
        </Zoom>
      ) : getFileName(url).toLowerCase().includes("mp4") ? (
        <VideoPlayer src={url} type={'mp4'} style={{width: '100%', height: '100%'}} />
      ) : (
        <Text fontSize="xs" h={'100px'}>
          {getFileName(url)}
        </Text>
      )
      }
      {!disabled && isDeletable ? (
          <DeleteIcon
            mb={'20px'}
            position={'absolute'}
            top={'10px'}
            right={'10px'}
            backgroundColor={'gray.50'}
            borderRadius={'20px'}
            borderWidth={'1px'}
            borderColor={'gray.700'}
            width={'30px'}
            height={'30px'}
            pl={'8px'}
            pr={'8px'}
            onClick={() => {
              onDeleteFile(index);
            }}
          />
        )
        :
        null
      }
    </Box>
  )
    ;
}
