// Chakra imports
import { Box, Button, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RewardsTrain } from '../../../../../apis/projectAPI';
import { useThemeColors } from '../../../../../providers/theme-provider';
import { CharacterTypeString, SkillTypeString } from '../../../../../helper/types';
import { RewardType } from '../../../../../types/enums';
// Custom components

export default function DynamicRewardField(props: {
	label?: string;
	values: RewardsTrain[];
	setValues: (values: RewardsTrain[]) => void;
}) {
	const { t } = useTranslation();
	const { textColorPrimary } = useThemeColors();
	const { label, values, setValues } = props;

	const handleRemove = (rewardId?: number) => {
		const updatedValues = values.filter(reward => reward.rewardId !== rewardId);
		setValues(updatedValues);
	};

	const displayRewards = (reward: RewardsTrain): string => {
		if (reward.type === RewardType.SKILL) {
			return `${t('project.reward_skill')}: ${SkillTypeString(t, reward.skill.type)}, ${t('project.reward_skill_point')}: ${reward.skill.point}`;
		} else if (reward.type === RewardType.CHARACTER) {
			return `${t('project.reward_character')}: ${CharacterTypeString(t, reward.character.type)}, ${t('project.reward_character_point')}: ${reward.character.point}`;
		} else {
			return t('No data');
		}
	};

	return (
		<Stack direction="column" gap="10px" mb="20px">
			{/* Label */}
			{label && (
				<Text ms="10px" fontSize="sm" color={textColorPrimary} fontWeight="bold" _hover={{ cursor: 'pointer' }}>
					{t(label)}
				</Text>
			)}

			{/* Rewards List */}
			{values.map(reward => (
				<Box
					key={reward.rewardId || reward.name}
					p="10px"
					borderWidth="1px"
					borderRadius="md"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Text fontSize="md" fontWeight="medium" color={textColorPrimary}>
						{displayRewards(reward)}
					</Text>
					<Button colorScheme="red" size="sm" onClick={() => handleRemove(reward.rewardId)}>
						{t('common.delete')}
					</Button>
				</Box>
			))}
		</Stack>
	);
}
