// Chakra imports
import { Flex, Grid, SimpleGrid } from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import Card from 'components/card/Card';
import { showToast } from '../../../../helper/functions';
import { RewardsTrain } from '../../../../apis/projectAPI';
import SelectField from 'components/fields/SelectField';
import { CharacterType, CurrencyType, ProductType, RewardType, SkillType } from '../../../../types/enums';
import {
	CharacterTypeString,
	CurrencyTypeString,
	ProductTypeString,
	RewardTypeString,
	SkillTypeString
} from '../../../../helper/types';
import ButtonField from 'components/fields/ButtonField';
import DropImageBox from '../../../../components/dropzone/DropzoneImgBox';

// 금지어 목록
export default function RewardsDetail(props: {
	detailId: string;
	requestChangeParams: () => void;
	isSelectMode?: boolean;
	onSelectReward?: (reward: RewardsTrain) => void;
}) {
	const { t } = useTranslation();
	const {} = useThemeColors();
	const { projectApi } = useApi();

	const { detailId, requestChangeParams, isSelectMode = false, onSelectReward } = props;

	const [detail, setDetail] = useState<RewardsTrain>();

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgUrls, setThumbnailImgUrls] = useState<string[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgFiles, setThumbnailImgFiles] = useState<File[]>([]);

	useEffect(() => {
		if (detailId !== '-1') {
			requestDetail();
		} else {
			const emptyObject: RewardsTrain = {
				name: '',
				iconImageUrl: null,
				type: RewardType.NONE
			};
			setDetail(emptyObject);
		}
	}, [detailId]);

	/**
	 * {
	 *   "rewardId": 0,
	 *   "name": "string",
	 *   "iconImageUrl": "string",
	 *   "type": "SKILL",
	 *   "character": {
	 *     "type": "CUTE",
	 *     "point": 0
	 *   },
	 *   "skill": {
	 *     "type": "MUSIC",
	 *     "point": 0
	 *   },
	 *   "currency": {
	 *     "currencyType": "HYPY_POINT",
	 *     "price": 0
	 *   },
	 *   "product": {
	 *     "productType": "CONSUMABLE",
	 *     "productId": 0
	 *   }
	 * }
	 * @param id
	 */

	const requestDetail = () => {
		projectApi
			.getRewardsDetail(detailId)
			.then(responseDetail)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const responseDetail = (response: any) => {
		if (response.detail) {
			setDetail(response.detail);
			if (response.detail.iconImageUrl !== null && response.detail.iconImageUrl !== '') {
				setThumbnailImgUrls([response.detail.iconImageUrl]);
			}
		}
	};

	const postReward = () => {
		if (detail?.name === '') {
			showToast(t('error.input_name'));
			return;
		}

		if (detail?.type === null || detail?.type === RewardType.NONE) {
			showToast(t('error.input_reward_type'));
			return;
		}

		if (
			detail?.type === RewardType.CHARACTER &&
			(detail?.character?.point === null || detail?.character?.point === 0)
		) {
			showToast(t('error.input_reward_character_point'));
			return;
		}

		if (detail?.type === RewardType.SKILL && (detail?.skill?.point === null || detail?.skill?.point === 0)) {
			showToast(t('error.input_reward_skill_point'));
			return;
		}

		if (
			detail?.currency !== null &&
			detail?.currency?.currencyType !== CurrencyType.NONE &&
			(detail?.currency?.price === null || detail?.currency?.price === 0)
		) {
			showToast(t('error.input_reward_currency_price'));
			return;
		}

		if (
			detail?.product !== null &&
			detail?.product?.productType !== ProductType.NONE &&
			(detail?.product?.productId === null || detail?.product?.productId === 0)
		) {
			showToast(t('error.input_reward_product_id'));
			return;
		}

		const form = new FormData();
		const requstData = detail;

		if (requstData?.type === RewardType.SKILL) {
			requstData.character = null;
		} else if (requstData?.type === RewardType.CHARACTER) {
			requstData.skill = null;
		}

		if (requstData?.product?.productType === ProductType.NONE) {
			requstData.product.productType = null;
		}

		if (requstData?.currency?.currencyType === CurrencyType.NONE) {
			requstData.currency = null;
		}

		console.log('=====> aaaa ' + JSON.stringify(requstData));
		form.append('request', JSON.stringify(requstData));

		if (thumbnailImgFiles.length > 0) {
			form.append('iconImgFile', thumbnailImgFiles[0]);
		}

		if (detailId !== '-1') {
			projectApi
				.putRewardsDetail(detailId, form)
				.then(() => {
					showToast(t('common.success_save'));
					requestChangeParams();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		} else {
			projectApi
				.postRewardsDetail(form)
				.then(() => {
					showToast(t('common.success_save'));
					requestChangeParams();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		}
	};

	return (
		<Grid
			display={{ base: 'block', md: 'grid' }}
			pt={{ base: '0px', md: '0px', xl: '0px' }}
			gap={{ base: '20px', xl: '20px' }}
		>
			<Flex direction={'column'}>
				<Grid
					display={{ base: 'block', md: 'grid' }}
					gridTemplateColumns={{ base: '1.5fr 1fr 1fr', lg: '1fr 1fr 1fr' }}
					gap={{ base: '20px', xl: '20px' }}
				>
					<Card>
						<SimpleGrid>
							<DropImageBox
								imgUrls={thumbnailImgUrls}
								setImgUrls={setThumbnailImgUrls}
								imgFiles={thumbnailImgFiles}
								setImgFiles={setThumbnailImgFiles}
								maxFiles={1}
								title={t('project_reward_icon_image')}
								disabled={false}
								width={'xs'}
							/>
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>
							<TextField label={t('common.id')} value={detail?.rewardId ? detail?.rewardId : '-'} />
							<InputField
								label={t('common.name')}
								value={detail?.name}
								onChange={e => {
									setDetail(prevState => ({
										...prevState,
										name: e.target.value
									}));
								}}
							/>
							<SelectField
								label={t('project.reward_type')}
								options={Object.values(RewardType)}
								value={detail?.type}
								displayOptionsString={RewardTypeString}
								onChange={e => {
									setDetail(prevState => ({
										...prevState,
										type: e.target.value as RewardType,
										skill:
											e.target.value === RewardType.NONE || e.target.value === RewardType.CHARACTER
												? null
												: prevState?.skill,
										character:
											e.target.value === RewardType.NONE || e.target.value === RewardType.SKILL
												? null
												: prevState?.character
									}));
								}}
							/>
							{detail?.type === RewardType.SKILL ? (
								<>
									<SelectField
										label={t('project.reward_skill_type')}
										options={Object.values(SkillType)}
										value={detail?.skill?.type}
										displayOptionsString={SkillTypeString}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												skill: {
													type: e.target.value as SkillType,
													point: prevState?.skill?.point
												}
											}));
										}}
									/>
									<InputField
										label={t('project.reward_skill_point')}
										value={detail?.skill?.point}
										type={'number'}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												skill: {
													type: prevState?.skill?.type,
													point: Number(e.target.value)
												}
											}));
										}}
									/>
								</>
							) : null}

							{detail?.type === RewardType.CHARACTER ? (
								<>
									<SelectField
										label={t('project.reward_character_type')}
										options={Object.values(CharacterType)}
										value={detail?.character?.type}
										displayOptionsString={CharacterTypeString}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												character: {
													type: e.target.value as CharacterType,
													point: prevState?.character?.point
												}
											}));
										}}
									/>
									<InputField
										label={t('project.reward_character_point')}
										value={detail?.character?.point}
										type={'number'}
										onChange={e => {
											setDetail(prevState => ({
												...prevState,
												character: {
													type: prevState?.character?.type,
													point: Number(e.target.value)
												}
											}));
										}}
									/>
								</>
							) : null}
						</SimpleGrid>
					</Card>
					<Card>
						<SimpleGrid>
							<>
								<SelectField
									label={t('project.reward_paid_type')}
									options={Object.values(CurrencyType)}
									value={detail?.currency?.currencyType ? detail?.currency?.currencyType : CurrencyType.NONE}
									displayOptionsString={CurrencyTypeString}
									onChange={e => {
										setDetail(prevState => ({
											...prevState,
											currency: {
												currencyType: e.target.value === CurrencyType.NONE ? null : (e.target.value as CurrencyType),
												price: prevState?.currency?.price
											}
										}));
									}}
								/>
								<InputField
									label={t('project.reward_paid_point')}
									value={detail?.currency?.price}
									type={'number'}
									onChange={e => {
										setDetail(prevState => ({
											...prevState,
											currency: {
												currencyType: prevState.currency.currencyType,
												price: Number(e.target.value)
											}
										}));
									}}
								/>
							</>

							<>
								<SelectField
									label={t('project.reward_paid_item_type')}
									options={Object.values(ProductType)}
									value={detail?.product?.productType ? detail?.product?.productType : ProductType.NONE}
									onChange={e => {
										setDetail(prevState => ({
											...prevState,
											product: {
												productType: e.target.value === ProductType.NONE ? null : (e.target.value as ProductType),
												productId: prevState?.product?.productId
											}
										}));
									}}
									displayOptionsString={ProductTypeString}
								/>
								<InputField
									label={t('project.reward_paid_item_id')}
									value={detail?.product?.productId}
									type={'number'}
									onChange={e => {
										setDetail(prevState => ({
											...prevState,
											product: {
												productType: prevState.product.productType,
												productId: Number(e.target.value)
											}
										}));
									}}
								/>
							</>
						</SimpleGrid>

						<ButtonField
							buttonName={isSelectMode && detailId !== '-1' ? t('common.select') : t('common.save')}
							onClick={() => {
								if (detailId !== '-1' && isSelectMode && onSelectReward) {
									onSelectReward(detail);
								} else {
									postReward();
								}
							}}
						/>
					</Card>
				</Grid>
			</Flex>
		</Grid>
	);
}
