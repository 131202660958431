// Chakra imports
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Select
} from "@chakra-ui/react";

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';

import { useTranslation } from 'react-i18next';
import { MdOutlineSearch } from 'react-icons/md';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';

import { useApi } from '../../../../providers/api-provider';
import { useThemeColors } from '../../../../providers/theme-provider';
import SearchInput from '../../../../components/actions/Search';
import SearchTable from '../../../../components/table/SearchTable';
import { AdminCrowdFundingProviderDto } from '../../../../apis/crowdfundingAPI';
import { BrandShopStatusType, ProviderSearchType, ProviderStatusType, TableRowType } from '../../../../types/enums';
import { TableBodyText } from '../../../../components/table/TableComponents';
import { phoneFomatter } from '../../../../helper/functions';
import ProviderDetail from "../provider/detail";

// 금지어 목록
export default function ProviderWithdrawList() {
	const { crowdFundingApi } = useApi();

	const { t } = useTranslation();
	const { textColor, buttonBg, hoverButton, activeButton } = useThemeColors();

	// 목록
	const [list, setList] = useState<AdminCrowdFundingProviderDto[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const [searchType, setSearchType] = useState<ProviderSearchType>(ProviderSearchType.NAME);
	const [searchWord, setSearchWord] = useState<string>('');

	// 상세 팝업
	const [selectDetailId, setDetailId] = useState<string>('');
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	const [isShowDetailModal, setIsDetailModal] = useState<boolean>(false);
	const [selectProviderId, setSelectProviderId] = useState<string>('');

	useEffect(() => {
		requestList(offset);
	}, []);

	// 목록 요청
	const requestList = async (offset: number) => {
		setOffset(offset);
		crowdFundingApi
			.getProvider({
				limit: limit,
				offset: offset * limit,
				searchType: searchType === ProviderSearchType.NONE ? '' : searchType,
				searchWord: searchType === ProviderSearchType.NONE ? '' : searchWord,
				providerStatus: ProviderStatusType.WITHDRAW_SUBMITTED
			})
			.then(response => {
				if (response.providerBasicTrain) {
					setList(response.providerBasicTrain);
				}

				if (response.count) {
					setTotalCount(response.count);
				}
			})
			.catch(() => {});
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<AdminCrowdFundingProviderDto>[] = [
		{
			row: 'crowdFundingProviderId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'name',
			type: TableRowType.TEXT
		},
		{
			row: 'presidentLastName',
			type: TableRowType.TEXT
		},
		{
			row: 'presidentFirstName',
			type: TableRowType.TEXT
		},
		{
			row: 'email',
			type: TableRowType.TEXT
		},
		{
			row: 'businessPhoneNumber',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={value ? phoneFomatter(value) : '-'}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		},
		{
			row: 'managerName',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={value ? value : '-'}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		},
		{
			row: 'managerPhoneNumber',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={value ? phoneFomatter(value) : '-'}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		},
		{
			row: 'brandStatus',
			type: TableRowType.ACTION,
			extraComponent: (value: any, rowData: any) => {
				return (
					<TableBodyText
						title={value && value === BrandShopStatusType.APPROVED ? '브랜드 판매점' : '기본 판매점'}
						onClick={() => {
							handleRowClick(rowData);
						}}
					/>
				);
			}
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		// 원하는 동작 추가 (예: 모달 열기, 상세 보기 페이지로 이동 등)
		setSelectProviderId(String(rowData.crowdFundingProviderId));
		setIsDetailModal(true);
		// navigate('/admin/crowdfunding/provider/detail/' + );
	};

	const columns = useMemo(() => createDynamicColumns(list, t, visibleColumns, handleRowClick), [list, t]);

	// 정렬 필터 변경
	const filterChange = (type: string) => {};

	// 검색 엔터키 처리
	const handleSearchKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleClickSearch();
		}
	};

	const handleClickSearch = () => {
		requestList(0);
	};

	// 검색어 저장
	const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

	const handleClickAdd = () => {
		setIsShowModal(true);
		setDetailId('-1');

		//window.location.href = '';
	};

	// Chakra Color Mode
	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'column', md: 'row' }} justifyContent={'space-between'} mb={'20px'}>
					<SearchInput
						onChangeSearch={onSearchInputChange}
						onEnterKeyPress={handleSearchKeyPress}
						leftElement={
							<Select
								fontSize="sm"
								id="edit_product"
								variant="unstyled"
								h={'44px'}
								w={{ md: '80px', xl: '120px' }}
								value={searchType}
								onChange={e => setSearchType(e.target.value as ProviderSearchType)}
							>
								<option value={ProviderSearchType.NAME}>{t('components.list_search_type_provider_name')}</option>
								<option value={ProviderSearchType.EMAIL}>{t('components.list_search_type_provider_email')}</option>
							</Select>
						}
						rightElement={
							<Button h="1.75rem" size="sm" onClick={handleClickSearch} borderRadius="20px">
								<Icon color={textColor} as={MdOutlineSearch} />
							</Button>
						}
					/>
				</Flex>
				<SearchTable request={requestList} data={list} columns={columns} totalCount={totalCount} />
			</Card>

			<Modal
				isOpen={isShowDetailModal}
				onClose={() => {
					setIsDetailModal(false);
				}}
				size={'full'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<ModalCloseButton />
						<ProviderDetail
							providerId={selectProviderId}
							requestChangeParams={() => {
								requestList(offset);
								setIsDetailModal(false);
							}}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
