/**
 * COMMON
 */
// 로그인
export const URL_ADMIN_ACCOUNT_SIGNIN = '/admin/account/signin';

// 계정 추가 / 조회
export const URL_ADMIN_ACCOUNT = '/admin/account';

/**
 * 회원
 */
export const URL_MEMBER_SEARCH = '/admin/member/search';

export const URL_MEMBER_DETAIL = '/admin/member/{memberId}';
export const URL_MEMBER_BLACKLISTED = '/admin/member/black-list';
export const URL_MEMBER_BLACKLISTED_DETAIL = '/admin/member/{memberId}/black-list';

export const URL_MEMBER_CURRENCY_DEPOSIT = '/admin/currency/deposit';

export const URL_IDOL_CURRENCY_REDEEM_RATE = '/admin/currency/redeem-rate/idol/{idolId}';

export const URL_IDOL_CURRENCY_REDEEM = '/admin/currency/redeem';
export const URL_IDOL_MEMBER_SEARCH = '/admin/idol/search';

export const URL_MEMBER_CURRENCY_REDEEM = '/admin/currency/redeem/{redeemHistoryId}';

/**
 * 아이돌
 */
// export const IDOL_SEARCH_URL = '/admin/member/search'
export const URL_IDOL_PRECONTRACT = '/admin/idol/pre-contract'; // 사전 계약 아이돌 목록 조회 (_GET)
export const URL_POST_IDOL_PRECONTRACT = '/admin/idol/pre-contract'; // 사전 계약 아이돌 수정 (_POST)
export const URL_GET_IDOL_PRECONTRACT_DETAIL = '/admin/idol/pre-contract/{idolPreContractId}'; // 사전 계약 아이돌 상세 조회
export const URL_PUT_IDOL_APPLY_REVIEW_REJECT = '/admin/idol/apply/review/{idolApplyId}/reject'; // 승인 대상 거부처리
export const URL_PUT_IDOL_APPLY_REVIEW_APPROVE = '/admin/idol/apply/review/{idolApplyId}/approve'; // 승인 대상 허용처리

export const URL_PUT_IDOL_APPLY_REVIEW_TEST_APPROVE = '/admin/idol/apply/review/{idolApplyId}/approve-test'; // 승인 대상 테스트 승인 처리
export const URL_GET_IDOL_APPLY = '/admin/idol/apply'; // 승인 대상 아이돌 목록 조회
export const URL_POST_IDOL_APPLY = '/admin/idol/apply'; // 승인 대상 아이돌 등록
export const URL_GET_IDOL_APPLY_DETAIL = '/admin/idol/apply/{idolApplyId}'; // 승인 대상 아이돌 상세 조회

/**
 * 라이브 스트리밍
 */
export const URL_ADMIN_LIVE_STREAMING = '/admin/live-streaming';
export const URL_ADMIN_LIVE_STREAMING_DETAIL = '/admin/live-streaming/{liveStreamingId}';
export const URL_ADMIN_LIVE_FORCE_QUIT = '/admin/live-streaming/{liveStreamingId}/force-quit';

/**
 * VOD
 */
export const URL_ADMIN_VOD = '/admin/vod';
export const URL_ADMIN_VOD_DETAIL = '/admin/vod/{vodId}';
export const URL_PUT_ADMIN_VOD_EXPOSE = '/admin/vod/{vodId}/expose';
export const URL_PUT_ADMIN_VOD_HIDE = '/admin/vod/{vodId}/hide';

/**
 * Project
 */
export const URL_PROJECT = '/admin/project';
export const URL_PROJECT_DETAIL = '/admin/project/{projectId}';

export const URL_STAGE = '/admin/stage';

export const URL_STAGE_DETAIL = '/admin/stage/{stageId}';

export const URL_MISSION = '/admin/mission';

export const URL_REWARDS = '/admin/reward';

export const URL_REWARDS_DETAIL = '/admin/reward/{rewardId}';

export const URL_PROJECT_APPLIED = '/admin/project/applications';
export const URL_PROJECT_APPLICANT_DETAIL = '/admin/project/application/{projectApplicationId}';

export const URL_PROJECT_APPLICANT_QUALIFIED = '/admin/project/applicants/qualified';
export const URL_PROJECT_APPLICANT_UNQUALIFIED = '/admin/project/applicants/un-qualified';

export const URL_PROJECT_ADD_APPLICANT = '/admin/project/add-applicant';

/**
 * MetaData
 */
// 금칙어 불러오기
export const URL_GET_FORBIDDEN_WORD_LIST = '/admin/metadata/forbidden-word';
// 금칙어 추가하기
export const URL_POST_FORBIDDEN_WORD = '/admin/metadata/forbidden-word';
// 금칙어 수정하기
export const URL_PUT_FORBIDDEN_WORD = '/admin/metadata/forbidden-word/{forbiddenWordId}';
// 금칙어 삭제하기
export const URL_DELETE_FORBIDDEN_WORD = '/admin/metadata/forbidden-word/{forbiddenWordId}';

export const URL_GET_IOS_VERSION_CONFIG = '/admin/metadata/ios-version-config';
export const URL_PUT_IOS_VERSION_CONFIG = '/admin/metadata/ios-version-config';

export const URL_GET_APP_META_DATA = '/admin/metadata/app-meta';
export const URL_GET_APP_META_DATA_DETAIL = '/admin/metadata/app-meta/{appMetaId}';

export const URL_PUT_APP_META_DATA_DETAIL = '/admin/metadata/app-meta/{appMetaId}';
export const URL_POST_APP_META_DATA = '/admin/metadata/app-meta';


// 메일 템플릿 목록
export const URL_MAIL_TEMPLATE =  '/admin/metadata/mail-template';
// 메일 템플릿 상세
export const URL_MAIL_TEMPLATE_DETAIL = '/admin/metadata/mail-template/{masterMailTemplateId}'




/**
 * Report
 */
export const URL_GET_REPORT_NEED_CONFIRM = '/admin/report/need-confirm';
export const URL_GET_REPORT_ALL = '/admin/report/all';

export const URL_GET_REPORT_CONFIRM = '/admin/report/confirm';
export const URL_GET_REPORT_REVERT = '/admin/report/revert';

/**
 * Notice
 */
export const URL_GET_NOTICE_LIST = '/admin/notice';

export const URL_POST_NOTICE = '/admin/notice';
export const URL_PUT_NOTICE_DETAIL = '/admin/notice/{noticeId}';

/**
 * Event
 */
export const URL_GET_EVENT_LIST = '/admin/event';
export const URL_POST_EVENT = '/admin/event';

export const URL_GET_EVENT_DETAIL = '/admin/event/{eventId}';

export const URL_GET_ACCOUNT_LIST = '/admin/account';
export const URL_GET_ACCOUNT_DETAIL = '/admin/account/{accountId}';

export const URL_POST_ACCOUNT = '/admin/account';

export const URL_PUT_ACTIVATION = '/admin/account/{accountId}/activation';

/**
 * 기타
 */
export const URL_TILE_STORAGES_FORCE_STORE = '/admin/tile-storage/force-stores';

export const URL_DASHBOARD = '/admin/dash-board/home';

export const URL_DASHBOARD_IDOL_HP = '/admin/dash-board/idol/hypy-point';

/**
 * Product
 */

export const URL_PRODUCT = '/admin/product';

export const URL_PRODUCT_DETAIL = '/admin/product/{productId}';

/**
 *  크라우드 펀딩
 */
export const URL_PROVIDER = '/admin/provider';

export const URL_PROVIDER_DETAIL = '/admin/provider/{storeAdminAccountId}';

export const URL_PROVIDER_REGISTER_APPROVE = '/admin/provider/{crowdFundingProviderId}/register/approve';

export const URL_PROVIDER_REGISTER_REJECT = '/admin/provider/{crowdFundingProviderId}/register/reject';


export const URL_PROVIDER_WITHDRAW_APPROVE = '/admin/provider/{crowdFundingProviderId}/withdraw/approve';

export const URL_PROVIDER_WITHDRAW_REJECT = '/admin/provider/{crowdFundingProviderId}/withdraw/reject';

export const URL_PROVIDER_BRAND = '/admin/provider/brand';

export const URL_PROVIDER_BRAND_DETAIL = '/admin/provider/{crowdFundingProviderId}/brand';

export const URL_PROVIDER_ITEM_META_COUNT = '/admin/provider/{crowdFundingProviderId}/item-meta-count';

export const URL_PROVIDER_BRAND_DETAIL_APPROVE = '/admin/provider/{crowdFundingProviderId}/brand/approve';

export const URL_PROVIDER_BRAND_DETAIL_REJECT = '/admin/provider/{crowdFundingProviderId}/brand/reject';

export const URL_PROVIDER_BRAND_DETAIL_IS_TOP_VISIBLE = '/admin/provider/{crowdFundingProviderId}/brand/is-top-visible';

export const URL_PROVIDER_BRAND_DETAIL_BUSINESS_STRUCTURE = '/admin/provider/{crowdFundingProviderId}/business-structure';

export const URL_CROWDFUNDING_ITEM = '/admin/crowd-funding/item';

export const URL_CROWDFUNDING_ITEM_DETAIL = '/admin/crowd-funding/item/{crowdFundingItemId}';

// 아이템 사용 신청한 아이돌 목록
// export const URL_CROWDFUNDING_ITEM_APPLY_IDOL = '/admin/crowd-funding/item/{crowdFundingItemId}/apply-idol';

// PUT  아이템 미노출 설정(어드민에 의한 강제 미노출)
export const URL_CROWDFUNDING_ITEM_INVISIBLE = '/admin/crowd-funding/item/{crowdFundingItemId}/invisible';

// 아이템 상태에 따른 아이돌 목록
export const URL_CROWDFUNDING_ITEM_PARTICIPANTS = '/admin/crowd-funding/item/{crowdFundingItemId}/participants';
// 아이돌 상품 상테에 따른 아이돌 목록
export const URL_CROWDFUNDING_ITEM_PARTICIPANTS_ITEM_STATUS = '/admin/crowd-funding/item/{crowdFundingItemId}/participants-by-item-status';


// PUT 아이템 등록 승인
export const URL_CROWDFUNDING_ITEM_REGISTER_APPROVE = '/admin/crowd-funding/item/{crowdFundingItemId}/register/approve';

// PUT 아이템 등록 반려
export const URL_CROWDFUNDING_ITEM_REGISTER_REJECT = '/admin/crowd-funding/item/{crowdFundingItemId}/register/reject';

// PUT 아이템 판매 기간 수정
export const URL_CROWDFUNDING_ITEM_SALES_PERIOD = '/admin/crowd-funding/item/{crowdFundingItemId}/sales-period';


// PUT 아이템 판매 정지/재개
export const URL_CROWDFUNDING_ITEM_SALES_STOP = '/admin/crowd-funding/item/{crowdFundingItemId}/sales-stop';

// PUT 아이템 수정 승인
export const URL_CROWDFUNDING_ITEM_UPDATE_APPROVE = '/admin/crowd-funding/item/{crowdFundingItemId}/update/approve';

// PUT 아이템 수정 반려
export const URL_CROWDFUNDING_ITEM_UPDATE_REJECT = '/admin/crowd-funding/item/{crowdFundingItemId}/update/reject';

// // GET 아이템 사용 완료한 아이돌 목록
// export const URL_CROWDFUNDING_ITEM_USED_IDOL = '/admin/crowd-funding/item/{crowdFundingItemId}/used-idol';

// PUT 사용 완료 처리
export const URL_CROWDFUNDING_ITEM_USED = '/admin/crowd-funding/item/{participationId}/used';

// 보상 등록 및 조회
export const URL_CROWDFUNDING_ITEM_REWARD = '/admin/crowd-funding/item/{participationId}/reward';

// 상점 계좌 번호 조회
export const URL_PROVIDER_BANK_ACCOUNT = '/admin/provider/{crowdFundingProviderId}/bank-account';










