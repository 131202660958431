// Chakra imports
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	CrowdFundingItemApplyIdolTrain,
	CrowdFundingItemDetailDto,
	CrowdFundingItemRewardTrain
} from '../../../../../apis/crowdfundingAPI';
import { useApi } from '../../../../../providers/api-provider';
import { useThemeColors } from '../../../../../providers/theme-provider';
import { showToast } from '../../../../../helper/functions';
import InputField from 'components/fields/InputField';
import DropImageBox from 'components/dropzone/DropzoneImgBox';
import ButtonField from 'components/fields/ButtonField';
import ModalBasic from 'components/modal/ModalBasic';
import ImageField from 'components/fields/ImageField';

// Assets

export default function ModalRegisterRewards(props: {
	isOpen: boolean;
	onClose: () => void;
	selectIdol: CrowdFundingItemApplyIdolTrain;
	crowfundItem: CrowdFundingItemDetailDto;
	requestChangeParams: () => void;
}) {
	const { t } = useTranslation();
	const { crowdFundingApi } = useApi();
	const { textColorBrand, textColorPrimary, textColorSecondary } = useThemeColors();

	const { isOpen, onClose, selectIdol, crowfundItem, requestChangeParams } = props;

	const [isShowModalRefund, setIsShowModalRefund] = useState<boolean>(false);

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [rewardsFiles, setRewardsFiles] = useState<File[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [rewardsUrls, setRewardsUrls] = useState<string[]>([]);

	useEffect(() => {
		if (selectIdol && isOpen) {
			if (selectIdol.isUploadReward) setRewardsUrls([]);
			setRewardsFiles([]);
			getRegisterRewards();
		}
	}, [selectIdol, isOpen]);

	const getRegisterRewards = () => {
		crowdFundingApi
			.getCrowdFundingItemUsedReward(String(selectIdol?.crowdFundingParticipationId))
			.then(resposne => {
				if (resposne.rewardTrain) {
					setTimeout(() => {
						const rewardFileList = resposne.rewardTrain as CrowdFundingItemRewardTrain[];
						rewardFileList.forEach(s => {
							setRewardsUrls(prevState => [...prevState, s.url]);
						});
					}, 50);
				}
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const postRegisterRewards = () => {
		if (rewardsUrls.length === 0) {
			showToast(t('error.input_reward_image'));
			return;
		}

		const form = new FormData();
		rewardsUrls.map(file => {
			form.append('fileTrain', file);
		});

		crowdFundingApi
			.postCrowdFundingItemUsedReward(String(selectIdol?.crowdFundingParticipationId), form)
			.then(() => {
				showToast(t('item.completed_register_reward'));
				requestChangeParams();
			})
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={'4xl'} scrollBehavior={'inside'} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody pb={6}>
					{/* 타이틀 */}
					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }}>
						<Text
							display="flex"
							ms="10px"
							fontSize="xl"
							color={textColorPrimary}
							fontWeight="bold"
							_hover={{ cursor: 'pointer' }}
						>
							{t('item.register_idol_rewards')}
						</Text>
						<Text fontSize="sm" color={textColorSecondary} fontWeight="400" ps={'10px'} whiteSpace={'pre-line'}>
							{t('item.register_idol_rewards_desc')}
						</Text>
					</SimpleGrid>
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} mt={'10px'} gap={'10px'}>
						<InputField label={t('item.idol_name')} disabled={true} value={selectIdol?.activityName} />
						<ImageField
							label={t('item.idol_profile_img')}
							value={t('store.item_crowdfunding')}
							disabled={true}
							height={'100px'}
							url={selectIdol?.profileImgUrl}
						/>
					</SimpleGrid>
					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} mt={'10px'} gap={'10px'}>
						<InputField label={t('item.name')} disabled={true} value={crowfundItem?.name} />
					</SimpleGrid>
					<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'10px'} mt={'10px'}>
						<Text
							display="flex"
							ms="10px"
							fontSize="sm"
							color={textColorPrimary}
							fontWeight="bold"
							_hover={{ cursor: 'pointer' }}
						>
							{t('item.register_reward_image')}
						</Text>
						<DropImageBox
							imgUrls={rewardsUrls}
							setImgUrls={setRewardsUrls}
							imgFiles={rewardsFiles}
							setImgFiles={setRewardsFiles}
							maxFiles={10}
							disabled={selectIdol?.isUploadReward}
							addVideoFiles={true}
							title={t('item.register_reward_image')}
						/>
						<Text fontSize="sm" color={textColorSecondary} fontWeight="400" ps={'10px'} whiteSpace={'pre-line'}>
							{t('item.register_reward_image_desc')}
						</Text>
					</SimpleGrid>
					{!selectIdol?.isUploadReward ? (
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} mt={'10px'}>
							<ButtonField
								witdh={'100px'}
								buttonName={t('common.register')}
								onClick={() => {
									setIsShowModalRefund(true);
								}}
							/>
						</SimpleGrid>
					) : null}
				</ModalBody>
			</ModalContent>

			<ModalBasic
				isOpen={isShowModalRefund}
				onClose={() => {
					setIsShowModalRefund(false);
				}}
				title={t('item.confirm_reward_image_register')}
				secondaryButton={{
					title: t('common.register'),
					onClick: () => {
						postRegisterRewards();
						setIsShowModalRefund(false);
					}
				}}
			/>
		</Modal>
	);
}
