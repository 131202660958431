// Chakra imports
import Card from 'components/card/Card';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import VideoPlayer from "../video/VideoPlayer";
import { getFileName } from "../../helper/functions";

type PreviewFileObj = {
  file: File;
  preview: string;
};
export default function DropImageFile(props: {
  index: number;
  file: File;
  onDeleteFile: (index: number) => void;
  isDeletable?: boolean;
  disabled?: boolean;
}) {
  const {
    index,
    file,
    onDeleteFile,
    isDeletable = true,
    disabled = false,
  } = props;

  const [previewFile, setPreviewFile] = useState<PreviewFileObj>(null);

  useEffect(() => {
    if (
      getFileName(file.name).toLowerCase().includes("png") ||
      getFileName(file.name).toLowerCase().includes("jpg")  ||
      getFileName(file.name).toLowerCase().includes("jpeg") ||
      getFileName(file.name).toLowerCase().includes("mp4")
    ) {
      setPreviewFile({file: file, preview: URL.createObjectURL(file)});
    } else {
      setPreviewFile({file: file, preview: ''});
    }
  }, [file]);

  if (!previewFile) {
    return <Card/>;
  }

  return (
    <Box
      maxW={'100%'}
      maxH="sm"
      borderWidth="1px"
      overflow="hidden"
      position={'relative'}
      minH={'150px'}
    >
      {getFileName(file.name).toLowerCase().includes("jpg") ||
      getFileName(file.name).toLowerCase().includes("jpeg") ||
      getFileName(file.name).toLowerCase().includes("png") ? (
        <Zoom>
          <Image src={previewFile.preview} objectFit={'cover'} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
        </Zoom>
      ) : getFileName(file.name).toLowerCase().includes("mp4")? (
        <VideoPlayer src={previewFile.preview} type={'mp4'} style={{width: '100%', height: '100%'}}/>
      ) : (
        <Text fontSize="xs" maxH={'100%'}>
          {file.name}
        </Text>
      )}
      {!disabled && isDeletable ? (
        <DeleteIcon
          mb={'20px'}
          position={'absolute'}
          top={'10px'}
          right={'10px'}
          backgroundColor={'gray.50'}
          borderRadius={'20px'}
          borderWidth={'1px'}
          borderColor={'gray.700'}
          width={'30px'}
          height={'30px'}
          pl={'8px'}
          pr={'8px'}
          onClick={() => {
            onDeleteFile(index);
          }}
        />
      ) : null}
    </Box>
  );
}
