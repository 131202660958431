// Chakra imports
import {
	Button,
	Flex,
	Grid,
	GridItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text
} from '@chakra-ui/react';

// Custom components
// Assets
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import { useThemeColors } from '../../../../providers/theme-provider';
import { useApi } from '../../../../providers/api-provider';
import Card from '../../../../components/card/Card';
import {
	AdminCrowdFundingProviderDetailDto,
	CrowdFundingItemMetaCountTrain, ProviderBankAccountTrain,
	ProviderDetailResponse
} from "../../../../apis/crowdfundingAPI";
import {
	DATA_FORMAT_FULL_DATE,
	dateFormat,
	formatBusinessNumber,
	formatInvoiceNumber,
	formatPostalCode,
	phoneFomatter,
	showToast
} from '../../../../helper/functions';
import ButtonField from 'components/fields/ButtonField';
import DropImageBox from 'components/dropzone/DropzoneImgBox';
import SelectField from 'components/fields/SelectField';
import { BrandShopStatusType, BusinessStructureType, ProviderStatusType, TableRowType } from 'types/enums';
import { HSeparator } from '../../../../components/separator/Separator';
import { BusinessTypeString, ProviderStatusTypeString } from 'helper/types';
import ModalBasic from '../../../../components/modal/ModalBasic';
import TextAreaField from 'components/fields/TextAreaField';
import { createDynamicColumns, VisibleColumns } from 'components/table/createDynamicColumns';
import CrowdFundingItemDetail from '../item/detail';
import SearchTable from '../../../../components/table/SearchTable';

// 금지어 목록
export default function ProviderDetail(props: { providerId: string; requestChangeParams: () => void }) {
	const { t } = useTranslation();
	const { textColorPrimary, textColorBrand } = useThemeColors();
	const { crowdFundingApi } = useApi();

	const { providerId, requestChangeParams } = props;

	/**
	 * 상점 정보
	 */
	const [storeInformation, setStoreInformation] = useState<AdminCrowdFundingProviderDetailDto>(null);

	/**
	 * 수정하기 여부
	 */
	const [isEditable, setIsEditable] = useState<boolean>(false);

	/**
	 * 대표 썸네일 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgUrls, setThumbnailImgUrls] = useState<string[]>([]);

	/**
	 * 대표 썸네일 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [thumbnailImgFiles, setThumbnailImgFiles] = useState<File[]>([]);

	/**
	 * 이메일 인증 번호, 이메일 인증 여부
	 */
	const [email, setEmail] = useState<string>();
	const [emailVerifyCode, setEmailVerifyCode] = useState<string>('');
	const [isEmailVerifyCheck, setIsEmailVerifyCheck] = useState<boolean>(false);

	// 승인/반려 여부
	const [isApplicationApprove, setIsApplicationApprove] = useState<boolean>(false);

	// 가입 승인 팝업
	const [isShowModalRegister, setIsShowModalRegister] = useState<boolean>(false);
	const [adminComment, setAdminComment] = useState<string>('');

	// 탈퇴 승인 팝업
	const [isShowModalWithdraw, setIsShowModalWithdraw] = useState<boolean>(false);

	// 브랜드 샵 정보
	/**
	 * 상세 이미지 URL
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgUrls, setDetailImgUrls] = useState<string[]>([]);

	/**
	 * 상세 이미지 파일
	 * 초기화는 무조건 빈 배열로 설정
	 */
	const [detailImgFiles, setDetailImgFiles] = useState<File[]>([]);

	// 설명
	const [description, setDescription] = useState<string>('');

	// 신청 및 처리 날짜
	const [registerDateTime, setRegisterDateTime] = useState<string>('');
	const [reactionDateTime, setReactionDateTime] = useState<string>('');

	// 브랜드 샵 신청 상태
	const [brandShopStatus, setBrandShopStatus] = useState<BrandShopStatusType>(BrandShopStatusType.WITHDRAW);

	/**
	 * 탈퇴 신청시 아이템 목록
	 */
	// 목록
	const [itemList, setItemList] = useState<CrowdFundingItemMetaCountTrain[]>([]);

	// 전체 갯수
	const [totalCount, setTotalCount] = useState<number>(0);
	// 한번에 불러올 목록
	const [limit, setLimit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);

	const [isShowItemDetailModal, setIsShowItemDetailModal] = useState<boolean>(false);
	const [selectItemId, setSelectItemId] = useState<string>('');


	// 은행 계좌 정보
	const [bankAccountNumber, setBankAccountNumber] = useState<string>('');

	// 은행 계좌 유형
	const [bankAccountType, setBankAccountType] = useState<string>('');

	// 은행 예금주 성
	const [bankAccountHolderSurName, setBankAccountHolderSurName] = useState<string>('');

	// 은행 예금주 이름
	const [bankAccountHolderGivenName, setBankAccountHolderGivenName] = useState<string>('');

	// 은행 이름
	const [bankAccountBankName, setBankAccountBankName] = useState<string>('');

	// 은행  코드
	const [bankAccountBankCode, setBankAccountBankCode] = useState<string>('');

	/**
	 * 로그인에서 상점 정보를 가져온 후 상점 정보 객체에 저장
	 */
	useEffect(() => {
		if (providerId) {
			getProvider();
			getBrandInformaiton();
			getBankAccount();
		}
	}, [providerId]);

	const getProvider = () => {
		crowdFundingApi
			.getProviderDetail(providerId)
			.then(responseGetProvider)
			.catch(() => {
				showToast(t('error.try_again'));
			});
	};

	const getBrandInformaiton = () => {
		crowdFundingApi
			.getBrandShopDetail(String(providerId))
			.then(response => {
				if (response.brandDetail) {
					setDescription(response.brandDetail.brandDescription);
					setBrandShopStatus(response.brandDetail.brandStatus);

					setRegisterDateTime(response.brandDetail.registerDateTime);
					setReactionDateTime(response.brandDetail.reactionDateTime);

					if (response.brandDetail.brandDetailImgUrl && response.brandDetail.brandDetailImgUrl.length > 0) {
						setDetailImgUrls([response.brandDetail.brandDetailImgUrl]);
					}
				}
			})
			.catch(error => {
				// showToast(t('error.try_again'));
			});
	};

	const getBankAccount = () => {
		crowdFundingApi.getProviderBankAccount(String(providerId)).then((response) => {
			if (response.bankAccount) {
				let bankAccount = response.bankAccount as ProviderBankAccountTrain
				setBankAccountType(bankAccount.bankAccountType);
				setBankAccountBankName(bankAccount.bankAccountBankName);
				setBankAccountBankCode(bankAccount.bankAccountBankCode);
				setBankAccountType(bankAccount.bankAccountType);
				setBankAccountHolderSurName(bankAccount.bankAccountHolderSurName);
				setBankAccountHolderGivenName(bankAccount.bankAccountHolderGivenName);
				setBankAccountNumber(bankAccount.bankAccountNumber);
			}
		}).catch(() => {

		});
	}

	const getWithdrawItemCountList = async (offset: number) => {
		crowdFundingApi
			.getCrowdFundingProviderItemMetaCount(String(providerId), {
				limit: limit,
				offset: offset * limit
			})
			.then(response => {
				if (response.itemMetaCountTrain) {
					setItemList(response.itemMetaCountTrain);
				}

				if (response.count) {
					setTotalCount(response.count);
				}
			})
			.catch(error => {});
	};

	/**
	 * 보여줄 목록 키 값 및 타입
	 */
	const visibleColumns: VisibleColumns<CrowdFundingItemMetaCountTrain>[] = [
		{
			row: 'itemId',
			title: 'id',
			type: TableRowType.TEXT
		},
		{
			row: 'participationCount',
			title: 'participationCount',
			type: TableRowType.TEXT
		},
		{
			row: 'obtainmentCount',
			title: 'obtainmentCount',
			type: TableRowType.TEXT
		},
		{
			row: 'applyCount',
			title: 'applyCount',
			type: TableRowType.TEXT
		},
		{
			row: 'usedCount',
			title: 'usedCount',
			type: TableRowType.TEXT
		}
	];

	/**
	 * 목록 클릭 이벤트
	 * @param rowData
	 */
	const handleRowClick = (rowData: any) => {
		setIsShowItemDetailModal(true);
		setSelectItemId(rowData.itemId);
	};

	const columns = useMemo(() => createDynamicColumns(itemList, t, visibleColumns, handleRowClick), [itemList, t]);

	const responseGetProvider = (response: ProviderDetailResponse) => {
		if (response.providerDetail) {
			setStoreInformation(response.providerDetail);
			setThumbnailImgUrls([response.providerDetail.thumbnailImgUrl]);

			if (response.providerDetail.providerStatus === ProviderStatusType.WITHDRAW_SUBMITTED) {
				getWithdrawItemCountList(0);
			}
		}
	};

	const showRegisterModal = (isApprove: boolean) => {
		setIsShowModalRegister(true);
		setIsApplicationApprove(isApprove);
	};

	const showWithdrawModal = (isApprove: boolean) => {
		setIsShowModalWithdraw(true);
		setIsApplicationApprove(isApprove);
	};

	const putProviderRegister = (isApprove: boolean) => {
		if (isApprove) {
			crowdFundingApi
				.putProviderRegisterApprove(providerId)
				.then(response => {
					setStoreInformation(prevState => {
						return {
							...prevState,
							providerStatus: ProviderStatusType.REGISTER_APPROVED
						};
					});
					requestChangeParams();
				})
				.catch(error => {
					showToast(t('error.try_again'));
				});
		} else {
			crowdFundingApi
				.putProviderRegisterRejected(providerId, adminComment)
				.then(() => {
					setStoreInformation(prevState => {
						return {
							...prevState,
							providerStatus: ProviderStatusType.REGISTER_REJECTED
						};
					});
					requestChangeParams();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		}
	};

	const putProviderWithdraw = (isApprove: boolean) => {
		if (isApprove) {
			crowdFundingApi
				.putProviderWithdrawApprove(providerId)
				.then(response => {
					setStoreInformation(prevState => {
						return {
							...prevState,
							providerStatus: ProviderStatusType.WITHDRAW_APPROVED
						};
					});
					setAdminComment('');
					requestChangeParams();
				})
				.catch(error => {
					showToast(t('error.try_again'));
				});
		} else {
			crowdFundingApi
				.putProviderWithdrawRejected(providerId, adminComment)
				.then(() => {
					setStoreInformation(prevState => {
						return {
							...prevState,
							providerStatus: ProviderStatusType.WITHDRAW_REJECTED
						};
					});
					requestChangeParams();
				})
				.catch(() => {
					showToast(t('error.try_again'));
				});
		}
	};

	const isShowApproveButton = () => {
		return (
			storeInformation?.providerStatus === ProviderStatusType.REGISTER_PENDING ||
			storeInformation?.providerStatus === ProviderStatusType.WITHDRAW_SUBMITTED
		);
	};

	const isProviderRegisterOrWithdraw = () => {
		return storeInformation?.providerStatus === ProviderStatusType.REGISTER_PENDING;
	};

	const isProviderWithdrawSubmitted = () => {
		return storeInformation?.providerStatus === ProviderStatusType.WITHDRAW_SUBMITTED;
	};

	if (!storeInformation) {
		return <Flex />;
	}

	return (
		<Flex direction="column" pt={{ sm: '75px', lg: '75px' }}>
			<Card px="0px">
				<Flex w="100%" direction={{ base: 'row', md: 'row' }} justifyContent={'center'} mb="36px">
					<Grid
						display={{ base: 'block', md: 'grid' }}
						pt={{ base: '0px', md: '0px', xl: '0px' }}
						pl={{ base: '0px', md: '0px', xl: '20px' }}
						pr={{ base: '0px', md: '0px', xl: '20px' }}
						gap={{ base: '20px', xl: '20px' }}
						width={{ base: '90%', xl: 'calc(100% - 40px)' }}
						maxWidth={'1280px'}
					>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }}>
							<GridItem colSpan={2}>
								<Text
									display="flex"
									ms="10px"
									fontSize="xl"
									color={textColorPrimary}
									fontWeight="bold"
									_hover={{ cursor: 'pointer' }}
								>
									{t('store.information')}
								</Text>
							</GridItem>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap={'20px'} justifyContent={'center'}>
							<DropImageBox
								imgUrls={thumbnailImgUrls}
								setImgUrls={setThumbnailImgUrls}
								imgFiles={thumbnailImgFiles}
								setImgFiles={setThumbnailImgFiles}
								maxFiles={1}
								title={t('store.thumbnail')}
								disabled={!isEditable}
							/>
							<Flex direction={'column'}>
								<InputField
									label={t('provider.status')}
									value={ProviderStatusTypeString(t, storeInformation?.providerStatus)}
									mt={'10px'}
									disabled={!isEditable}
								/>
								<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={'10px'}>
									{isShowApproveButton() ? (
										<ButtonField
											buttonName={t('provider.approved')}
											alignContent={'flex-end'}
											onClick={() => {
												if (isProviderRegisterOrWithdraw()) {
													showRegisterModal(true);
												} else {
													showWithdrawModal(true);
												}
											}}
										/>
									) : null}

									{isShowApproveButton() ? (
										<ButtonField
											buttonName={t('provider.rejected')}
											alignContent={'flex-end'}
											backgroundColor={'red'}
											onClick={() => {
												if (isProviderRegisterOrWithdraw()) {
													showRegisterModal(false);
												} else {
													showWithdrawModal(false);
												}
											}}
										/>
									) : null}

									{}
								</SimpleGrid>
							</Flex>
						</SimpleGrid>

						{isProviderWithdrawSubmitted() && itemList.length > 0 ? (
							<>
								<HSeparator />
								<Text
									display="flex"
									fontSize="md"
									color={textColorBrand}
									fontWeight="bold"
									_hover={{ cursor: 'pointer' }}
									mb={{ base: 10, md: 0 }}
								>
									{t('item.usage_information')}
								</Text>
								<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
									<SearchTable
										request={getWithdrawItemCountList}
										data={itemList}
										columns={columns}
										totalCount={totalCount}
									/>
								</SimpleGrid>
								<HSeparator mb={'10px'} />
							</>
						) : null}

						<>
							<Text
								display="flex"
								fontSize="md"
								color={textColorBrand}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
								mb={{ base: 10, md: 0 }}
							>
								{t('store.information')}
							</Text>
						</>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.business_name')}
								value={storeInformation?.name}
								mt={'10px'}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										name: e.target.value // name 속성을 새 값으로 업데이트
									}));
								}}
								disabled={!isEditable}
							/>
							<SelectField
								label={t('store.business_type')}
								disabled={true}
								value={storeInformation?.businessStructureType}
								options={Object.keys(BusinessStructureType)}
								onChange={e => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessStructureType: e.target.value as BusinessStructureType // name 속성을 새 값으로 업데이트
									}));
								}}
								displayOptionsString={BusinessTypeString}
							/>
							<InputField
								label={t('store.business_registration_number')}
								value={formatBusinessNumber(storeInformation?.businessRegistrationNo)}
								disabled={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessRegistrationNo: e.target.value // name 속성을 새 값으로 업데이트
									}));
								}}
								mt={'10px'}
							/>
						</SimpleGrid>
						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap={'20px'} justifyContent={'center'}>
							<InputField label={t('store.country')} value={storeInformation?.countryCode} disabled={!isEditable} />
							<InputField
								label={t('store.main_contact_number')}
								value={phoneFomatter(storeInformation?.businessPhoneNumber)}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessPhoneNumber: e.target.value.replaceAll('-', '')
									}));
								}}
								disabled={!isEditable}
							/>
							<InputField
								label={t('store.invoice_number')}
								value={formatInvoiceNumber(storeInformation?.invoiceNumber)}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										invoiceNumber: e.target.value.replaceAll('-', '')
									}));
								}}
								disabled={!isEditable}
							/>
						</SimpleGrid>

						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.address_postal_code')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessPostalCode: e.target.value.replaceAll('-', '')
									}));
								}}
								value={formatPostalCode(storeInformation?.businessPostalCode)}
								disabled={!isEditable}
								// onBlur={() => {
								// 	if (storeInformation?.countryCode === 'JP') {
								// 		fetchAddress(storeInformation?.businessPostalCode);
								// 	}
								// }}
							/>
							<InputField
								label={t('store.address_state')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessAddressCity: e.target.value
									}));
								}}
								value={storeInformation?.businessAddressCity}
								disabled={!isEditable}
							/>
							<InputField
								label={t('store.address_city')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessAddressState: e.target.value
									}));
								}}
								value={storeInformation?.businessAddressState}
								disabled={!isEditable}
							/>
							<InputField
								label={t('store.address_1')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessAddressStreetAddress1: e.target.value
									}));
								}}
								value={storeInformation?.businessAddressStreetAddress1}
								disabled={!isEditable}
							/>
							<InputField
								label={t('store.address_2')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										businessAddressStreetAddress2: e.target.value
									}));
								}}
								value={storeInformation?.businessAddressStreetAddress2}
								disabled={!isEditable}
							/>
						</SimpleGrid>

						<HSeparator />
						<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.registration_email')}
								value={email}
								disabled={!isEditable}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setEmail(e.target.value);
								}}
							/>

							{isEditable ? (
								<>
									<ButtonField
										pt={'22px'}
										buttonName={t('common.email_verify')}
										width={'100px'}
										onClick={() => {
											// postVerifyEmail();
										}}
									/>
								</>
							) : null}

							{isEmailVerifyCheck ? (
								<InputField
									label={t('store.email_auth_code')}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										setEmailVerifyCode(e.target.value);
									}}
									type={'number'}
									value={emailVerifyCode}
								/>
							) : null}

							{isEmailVerifyCheck ? (
								<>
									<ButtonField
										pt={'22px'}
										buttonName={t('common.ok')}
										width={'100px'}
										onClick={() => {
											// postVerifyEmailCheck();
										}}
									/>
								</>
							) : null}
						</SimpleGrid>

						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.business_contact_number')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										presidentPhoneNumber: e.target.value.replaceAll('-', '')
									}));
								}}
								value={phoneFomatter(storeInformation?.presidentPhoneNumber)}
								disabled={!isEditable}
							/>

							<InputField
								label={t('store.representative_last_name')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										presidentLastName: e.target.value
									}));
								}}
								value={storeInformation?.presidentLastName}
								disabled={!isEditable}
							/>
							<InputField
								label={t('store.representative_first_name')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										presidentFirstName: e.target.value
									}));
								}}
								value={storeInformation?.presidentFirstName}
								disabled={!isEditable}
							/>
						</SimpleGrid>

						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.cs_representative_name')}
								value={storeInformation?.managerName}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										managerName: e.target.value
									}));
								}}
								disabled={!isEditable}
							/>
							<InputField
								label={t('store.cs_representative_number')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setStoreInformation(prevState => ({
										...prevState, // 이전 상태를 복사
										managerPhoneNumber: e.target.value
									}));
								}}
								value={phoneFomatter(storeInformation?.managerPhoneNumber)}
								disabled={!isEditable}
							/>
						</SimpleGrid>

						<HSeparator />

						<SimpleGrid columns={{ base: 1, md: 1, xl: 3 }} gap={'20px'} justifyContent={'center'}>
							<InputField
								label={t('store.registration_date')}
								value={storeInformation?.registerApprovedDateTime}
								disabled={true}
							/>
							<InputField label={t('store.id')} value={storeInformation?.accountId} disabled={true} />
						</SimpleGrid>

						{brandShopStatus === BrandShopStatusType.APPROVED ? (
							<>
								<HSeparator />
								<Text
									display="flex"
									fontSize="md"
									color={textColorBrand}
									fontWeight="bold"
									_hover={{ cursor: 'pointer' }}
									mb={{ base: 10, md: 0 }}
								>
									{t('store.brand_information')}
								</Text>
								<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap={'20px'} justifyContent={'center'}>
									<InputField
										label={t('store.brand_register_date')}
										value={dateFormat(registerDateTime, DATA_FORMAT_FULL_DATE)}
										disabled={true}
									/>
									<InputField
										label={t('store.brand_process_date')}
										value={dateFormat(reactionDateTime, DATA_FORMAT_FULL_DATE)}
										disabled={true}
									/>
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
									<TextAreaField
										label={t('store.brand_shop_description') + ' *'}
										minH={'250px'}
										value={description}
										onChange={e => {
											setDescription(e.target.value);
										}}
										disabled={true}
										placeholder={t('store.detailed_description_guide')}
									/>
								</SimpleGrid>
								<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap={'20px'} justifyContent={'center'}>
									<Text
										display="flex"
										ms="10px"
										fontSize="sm"
										color={textColorPrimary}
										fontWeight="bold"
										_hover={{ cursor: 'pointer' }}
									>
										{t('store.brand_shop_detailed_image') + ' *'}
									</Text>
									<DropImageBox
										imgUrls={detailImgUrls}
										setImgUrls={setDetailImgUrls}
										imgFiles={detailImgFiles}
										disabled={true}
										title={t('store.brand_shop_detailed_image')}
										setImgFiles={setDetailImgFiles}
										maxFiles={1}
										width={'xs'}
									/>
								</SimpleGrid>
							</>
						) : null}

						<SimpleGrid columns={1} justifyContent="center">
							<HSeparator mb={'20px'}/>
							<Text
								display="flex"
								fontSize="md"
								color={textColorBrand}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}
								mb={{ base: 10, md: 0 }}
							>
								{t('store.bank_account_information')}
							</Text>
						</SimpleGrid>

						<SimpleGrid columns={{base: 1, xl: 3}}
												justifyContent="center" gap="20px">
							<InputField
								label={t('store.bank_name')}
								value={bankAccountBankName ? bankAccountBankName : '-'}
								disabled={true}
								onChange={e => {
									setBankAccountBankName(e.target.value);
								}}
							/>
							<InputField
								label={t('store.bank_code')}
								value={bankAccountBankCode ? bankAccountBankCode : '-'}
								disabled={true}
								onChange={e => {
									setBankAccountBankCode(e.target.value);
								}}
							/>
							<InputField
								label={t('store.bank_account_type')}
								value={bankAccountType ? bankAccountType : '-'}
								disabled={true}
								onChange={e => {
									setBankAccountType(e.target.value);
								}}
							/>

						</SimpleGrid>
						<SimpleGrid columns={{base: 1, xl: 3}} gap="20px"
												justifyContent="center">
							<InputField
								label={t('store.bank_account_number')}
								value={bankAccountNumber ? bankAccountNumber : '-'}
								disabled={true}
								onChange={e => {
									setBankAccountNumber(e.target.value);
								}}
							/>
							<InputField
								label={t('store.bank_holder_sur_name')}
								value={bankAccountHolderSurName ? bankAccountHolderSurName : '-'}
								disabled={true}
								onChange={e => {
									setBankAccountHolderSurName(e.target.value);
								}}
							/>
							<InputField
								label={t('store.bank_holder_given_name')}
								value={bankAccountHolderGivenName ? bankAccountHolderGivenName : '-'}
								disabled={true}
								onChange={e => {
									setBankAccountHolderGivenName(e.target.value);
								}}
							/>
						</SimpleGrid>
					</Grid>
				</Flex>
			</Card>

			<ModalBasic
				isOpen={isShowModalRegister && isApplicationApprove}
				size={'xl'}
				onClose={() => {
					setIsShowModalRegister(false);
				}}
				title={t('provider.store_approved', { name: storeInformation?.name })}
				desc={t('provider.store_approved_desc')}
				firstButton={{
					title: t('common.cancel'),
					onClick: () => {
						setIsShowModalRegister(false);
					}
				}}
				secondaryButton={{
					title: t('provider.approved'),
					onClick: () => {
						setIsShowModalRegister(false);
						putProviderRegister(isApplicationApprove);
					}
				}}
			/>

			<Modal
				isOpen={isShowModalRegister && !isApplicationApprove}
				onClose={() => {
					setIsShowModalRegister(false);
				}}
				size={'xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('provider.store_rejected', { name: storeInformation?.name })}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color={textColorPrimary} fontSize="md" fontWeight="500" ms={'10px'} mb={'10px'}>
							{t('provider.store_rejected_desc')}
						</Text>
						<TextAreaField
							label={t('provider.store_rejected_reason')}
							onChange={e => {
								setAdminComment(e.target.value);
							}}
							value={adminComment}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="brand"
							mr={3}
							onClick={() => {
								if (adminComment === null || adminComment.length < 10) {
									showToast(t('error.input_admin_comment'));
									return;
								}

								putProviderRegister(false);
								setIsShowModalRegister(false);
							}}
						>
							{t('provider.rejected')}
						</Button>
						<Button
							onClick={() => {
								setAdminComment('');
								setIsShowModalRegister(false);
							}}
						>
							{t('common.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<ModalBasic
				isOpen={isShowModalWithdraw}
				size={'xl'}
				onClose={() => {
					setIsShowModalWithdraw(false);
				}}
				title={t('provider.store_withdraw_approved', { name: storeInformation?.name })}
				desc={t('provider.store_withdraw_approved_desc')}
				firstButton={{
					title: t('common.cancel'),
					onClick: () => {
						setIsShowModalWithdraw(false);
					}
				}}
				secondaryButton={{
					title: t('provider.approved'),
					onClick: () => {
						setIsShowModalWithdraw(false);
						putProviderWithdraw(isApplicationApprove);
					}
				}}
			/>

			<Modal
				isOpen={isShowModalWithdraw && !isApplicationApprove}
				onClose={() => {
					setIsShowModalWithdraw(false);
				}}
				size={'xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('provider.store_withdraw_rejected', { name: storeInformation?.name })}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color={textColorPrimary} fontSize="md" fontWeight="500" ms={'10px'} mb={'10px'}>
							{t('provider.store_withdraw_rejected_desc')}
						</Text>
						<TextAreaField
							label={t('provider.store_withdraw_rejected_reason')}
							onChange={e => {
								setAdminComment(e.target.value);
							}}
							value={adminComment}
						/>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="brand"
							mr={3}
							onClick={() => {
								if (adminComment === null || adminComment.length < 10) {
									showToast(t('error.input_admin_comment'));
									return;
								}

								putProviderWithdraw(false);
								setIsShowModalWithdraw(false);
							}}
						>
							{t('provider.rejected')}
						</Button>
						<Button
							onClick={() => {
								setAdminComment('');
								setIsShowModalWithdraw(false);
							}}
						>
							{t('common.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				isOpen={isShowItemDetailModal}
				onClose={() => {
					setIsShowItemDetailModal(false);
				}}
				size={'full'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<ModalCloseButton />
						<CrowdFundingItemDetail itemId={selectItemId} requestChangeParams={() => {}} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
