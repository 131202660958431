import './assets/css/App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './locale/i18n';
import ErrorBoundary from './boundaries/errorBoundary';
import { getBrowserTokens, getLoginInfo, LoginProvider } from './providers/login-provider';
import { ApiProvider } from './providers/api-provider';
import Alerts from './views/common/404';
import { AuthProvider, getUserPermissions } from './providers/auth-provider';
import { SocketProvider } from './providers/socket-provider';
import { ThemeColorProvider } from './providers/theme-provider';
import { LoadingProvider } from './providers/loading-provider';

const { ToastContainer } = createStandaloneToast();

export default function Main() {
	const [currentTheme, setCurrentTheme] = useState(initialTheme);

	return (
		<ChakraProvider theme={currentTheme}>
			{/*/ 에러 처리 */}
			<ErrorBoundary>
				{/*/ 다국어 처리 */}
				<I18nextProvider i18n={i18n} defaultNS={'translation'}>
					{/*/ 인증 관련 */}
					<LoginProvider initialTokens={getBrowserTokens()} initialLoginInfo={getLoginInfo()}>
						<AuthProvider initialPermissions={getUserPermissions()}>
							<LoadingProvider>
								{/*/ API 관련 */}
								<ApiProvider>
									<ThemeColorProvider>
										<SocketProvider>
											<Routes>
												{/* 인증 관련 화면  */}
												<Route path="auth/*" element={<AuthLayout />} />
												{/* 어드민 관련 화면  */}
												<Route
													path="admin/*"
													element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />}
												/>
												{/* 로그인 화면  */}
												<Route path="/" element={<Navigate to={process.env.REACT_APP_DEFAULT_URI} replace />} />
												<Route path="/404" element={<Alerts />} />
												<Route path="*" element={<Navigate to="/404" replace />} />
											</Routes>
										</SocketProvider>
									</ThemeColorProvider>
								</ApiProvider>
							</LoadingProvider>
						</AuthProvider>
					</LoginProvider>
				</I18nextProvider>
				<ToastContainer />
			</ErrorBoundary>
		</ChakraProvider>
	);
}
