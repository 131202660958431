import React, { createContext, useContext, useMemo, useRef } from 'react';

import { Tokens, useLogin } from './login-provider';
import { CommonApi } from '../apis/commonAPI';
import { BaseApiOptions } from '../apis/baseAPI';
import { MemberAPI } from '../apis/memberAPI';
import { IdolAPI } from '../apis/idolAPI';
import { ContentsApi } from '../apis/contentsAPI';
import { ProjectApi } from '../apis/projectAPI';
import { MetaDataApi } from '../apis/metaDataAPI';
import { ReportApi } from '../apis/reportAPI';
import { NoticeApi } from '../apis/noticeAPI';
import { AccountApi } from "../apis/accountAPI";
import { ProductApi } from "../apis/productAPI";
import { CrowdFundingApi } from "../apis/crowdfundingAPI";

export type ApiContextValue = {
	commonApi: CommonApi;
	memberApi: MemberAPI;
	idolApi: IdolAPI;
	contentsApi: ContentsApi;
	projectApi: ProjectApi;
	metaDataApi: MetaDataApi;
	reportApi: ReportApi;
	noticeApi: NoticeApi;
	productApi: ProductApi;
	accountApi: AccountApi;
	crowdFundingApi: CrowdFundingApi;
};

// Api 클라이언트 인스턴스들을 캐시하여 리액트 컴포넌트 트리 전체에서 사용할 수 있게 해 줌.
export const ApiContext = createContext<ApiContextValue>(undefined!);

// Api 클라이언트 인스턴스들을 트리 전체에 공급해줌.
export const ApiProvider: React.FC<{
	children: any;
}> = ({ children }) => {
	const { tokens, setTokens } = useLogin();

	const refreshPromiseRef = useRef<Promise<Tokens> | undefined>();
	const options = useMemo<BaseApiOptions>(
		() => ({
			baseURL: process.env.REACT_APP_API_URL,
			tokens: tokens,
			getRefresh: () => refreshPromiseRef.current,
			setRefresh: promise => {
				refreshPromiseRef.current = promise;
				promise.then(tokens => tokens && setTokens(tokens)).finally(() => (refreshPromiseRef.current = undefined)); // 끝난 후 지워줘야 한다!
			}
		}),
		[tokens, setTokens]
	);

	/**
	 * 공통 API, 어드민 관리자 가입/로그인 관련
	 */
	const commonApi = useMemo(() => new CommonApi(options), [options]);

	/**
	 * 회원 관련
	 */
	const memberApi = useMemo(() => new MemberAPI(options), [options]);

	/**
	 * 아이돌 가입 관련
	 */
	const idolApi = useMemo(() => new IdolAPI(options), [options]);

	/**
	 * 콘텐츠 (라이브 스트리밍, VOD 등)
	 */
	const contentsApi = useMemo(() => new ContentsApi(options), [options]);

	/**
	 * 프로젝트 관련
	 */
	const projectApi = useMemo(() => new ProjectApi(options), [options]);

	const metaDataApi = useMemo(() => new MetaDataApi(options), [options]);

	/**
	 * 신고 관련
	 */
	const reportApi = useMemo(() => new ReportApi(options), [options]);

	/**
	 * 공지사항 관련
	 */
	const noticeApi = useMemo(() => new NoticeApi(options), [options]);

	/**
	 * 상품 관련
	 */
	const productApi = useMemo(() => new ProductApi(options), [options]);

	/**
	 * 관리자 관련
	 */
	const accountApi = useMemo(() => new AccountApi(options), [options]);

	/**
	 * 크라우드 펀팅
	 */
	const crowdFundingApi = useMemo(() => new CrowdFundingApi(options), [options]);

	return (
		<ApiContext.Provider
			value={{
				commonApi,
				memberApi,
				idolApi,
				contentsApi,
				projectApi,
				metaDataApi,
				reportApi,
				noticeApi,
				productApi,
				accountApi,
				crowdFundingApi
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};

// 리액트 컴포넌트 어디에서나 캐시된 api client를 사용할 수 있다. (인증 신경 안 써도 됨.)
export const useApi = () => {
	const context = useContext(ApiContext);

	if (context == null) {
		throw new Error("Cannot use 'useApi' outside of AppProvider.");
	}

	return context;
};
